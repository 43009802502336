import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    getBillingDetails,
    updateBillingDetails,
    getProratePrice,
    programSubscription,
    skipPayment,
} from "api/paymentAPI";
import { useLocation, useParams } from "react-router-dom";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import CONSTANTS from "common/constants";
import { getNavigationByHospitalId } from "api/usersApi";
import DynamicModal from "components/DynamicModal";
import { PaymentSummaryWrapper } from "./styled";
import { BreadCrumb } from "../../components/BreadCrumb";
import { parseJwt } from "../../common/utils";
import "styles/toggle-switch.scss";

export interface IBillingDetails {
    facilityName: string;
    billingAddress: string;
    city: string;
    state: string;
    countryName: string;
    zipCode: string;
    phoneNumber: string;
    emailAddress: string;
    billingContactName: string;
    updatedAt: any;
    updatedBy: any;
}

interface Props {
    history: any;
    isCorporation?: any;
    setActiveTab?: any;
    activeTab?: any;
    close?: any;
    setBillingComplete?: any;
    setPaymentComplete?: any;
    paymentComplete?: boolean;
}

export const PaymentSummaryPage = ({
    history,
    isCorporation,
    setActiveTab,
    activeTab,
    close,
    setBillingComplete,
    setPaymentComplete,
    paymentComplete,
}: Props) => {
    const dispatch = useDispatch();
    // PATH Params
    const params: any = useParams();
    const { programId, categoryId }: any = params;
    const location = useLocation();
    // STATE
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const initBillingDetails: IBillingDetails = {
        facilityName: "",
        billingAddress: "",
        city: "",
        state: "",
        countryName: "",
        zipCode: "",
        phoneNumber: "",
        emailAddress: "",
        billingContactName: "",
        updatedAt: "",
        updatedBy: "",
    };
    const [billingDetails, setBillingDetails] =
        useState<IBillingDetails>(initBillingDetails);
    const [savedBillingDetails, setSavedBillingDetails] =
        useState<IBillingDetails>(initBillingDetails);
    const [categoryName, setCategoryName] = useState<string>("");
    const formRef = useRef<HTMLFormElement>(null);
    const [offlinePayment, setOfflinePayment] = useState<boolean>(false);
    const [price, setPrice] = useState<number>();
    const [isContinuePaymentClicked, setIsContinuePaymentClicked] =
        useState<boolean>(false);
    const [isPayOfflineClicked, setIsPayOfflineClicked] =
        useState<boolean>(false);
    const [disabled, setDisabled] = useState<any>(false);
    const [facilityBillingDetails, setFacilityBillingDetails] =
        useState<boolean>(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const hospitalId: any = isCorporation
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
    const selectedCountryName = localStorage.getItem("selectedCountryName");
    const selectedCountryCode =
        isCorporation && selectedCountryName === CONSTANTS.DOMESTIC_COUNTRY_NAME
            ? "US"
            : localStorage.getItem("selectedHospitalCountryCode");

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        if (hospitalId) {
            isCorporation
                ? getBillingDetailsToDisplay()
                : getNavigationByHospitalId(hospitalId)
                      .then((navigationResponse: any) => {
                          const { entity, path } = navigationResponse.data.url;
                          if (
                              entity === CONSTANTS.HOSPITAL &&
                              path === CONSTANTS.THANKYOU_PAGE
                          ) {
                              dispatch(
                                  setPageLoadingStatus({
                                      isPageLoading: false,
                                  })
                              );
                              history.push("/successpayment");
                          } else if (
                              entity === CONSTANTS.HOSPITAL &&
                              path === CONSTANTS.PATHS.DASHBOARD
                          ) {
                              dispatch(
                                  setPageLoadingStatus({
                                      isPageLoading: false,
                                  })
                              );
                              history.push("/userDashboard");
                          } else if (
                              navigationResponse.data.isPaymentMade &&
                              navigationResponse.data.isProgramApproved
                          ) {
                              dispatch(
                                  setPageLoadingStatus({
                                      isPageLoading: false,
                                  })
                              );
                              history.push("/dashboard");
                          } else if (navigationResponse.data.isPaymentMade) {
                              dispatch(
                                  setPageLoadingStatus({
                                      isPageLoading: false,
                                  })
                              );
                              history.push(
                                  `/paymentStatus?referenceId=${navigationResponse.data?.info?.lastTransactionId}&lastSessionPayment=1`
                              );
                          } else {
                              const elementsArray = [];
                              elementsArray.push(
                                  {
                                      label: "Home",
                                      returnPath: {
                                          pathname:
                                              CONSTANTS.ROUTES.USER_DASHBOARD,
                                          state: {
                                              pageNum: location?.state?.pageNum,
                                              search: location?.state?.search,
                                          },
                                      },
                                  },
                                  {
                                      label: "Organization Details",
                                      returnPath: {
                                          pathname: `${CONSTANTS.ROUTES.HOSPITAL_REGISTRATION}/${hospitalId}`,
                                          state: {
                                              pageNum: location?.state?.pageNum,
                                              search: location?.state?.search,
                                          },
                                      },
                                  },
                                  {
                                      label: "Programs",
                                      returnPath: {
                                          pathname: CONSTANTS.ROUTES.PROGRAMS,
                                          state: {
                                              pageNum: location?.state?.pageNum,
                                              search: location?.state?.search,
                                          },
                                      },
                                  },
                                  {
                                      label: "Program Details",
                                      returnPath: {
                                          pathname: `${CONSTANTS.ROUTES.PROGRAM_REGISTRATION}/${programId}`,
                                          state: {
                                              pageNum: location?.state?.pageNum,
                                              search: location?.state?.search,
                                          },
                                      },
                                  },
                                  {
                                      label: "Agreement",
                                      returnPath: {
                                          pathname: `${CONSTANTS.ROUTES.PROGRAMS}/${programId}/category/${categoryId}/agreement`,
                                          state: {
                                              pageNum: location?.state?.pageNum,
                                              search: location?.state?.search,
                                          },
                                      },
                                  },
                                  {
                                      label: "Billing Details",
                                      returnPath: "",
                                  }
                              );
                              setBreadcrumbItems(elementsArray);
                              getBillingDetailsToDisplay();
                          }
                      })
                      .catch(() => {
                          dispatch(
                              setPageLoadingStatus({ isPageLoading: false })
                          );
                      });
        }
    }, [hospitalId]);

    const getBillingDetailsToDisplay = () => {
        Promise.all([
            getBillingDetails(hospitalId, categoryId),
            getProratePrice(programId, categoryId),
        ])
            .then((responses: any) => {
                const billingDetailsResponse = responses[0];
                const priceResponse = responses[1];
                if (billingDetailsResponse.success) {
                    if (billingDetailsResponse.data?.billingDetails) {
                        const billingDataResponse = {
                            ...billingDetailsResponse.data.billingDetails,
                            billingContactName: billingDetailsResponse.data
                                .billingDetails.billingContactName
                                ? billingDetailsResponse.data.billingDetails
                                      .billingContactName
                                : "",
                        };
                        setBillingDetails(billingDataResponse);
                        setSavedBillingDetails(billingDataResponse);
                    }
                }
                if (
                    priceResponse.success &&
                    priceResponse.statusCode == 200 &&
                    priceResponse.data &&
                    priceResponse.data.programCategoryPrice
                ) {
                    setCategoryName(
                        priceResponse.data.programCategoryPrice.categoryName
                    );
                    setPrice(priceResponse.data.programCategoryPrice.price);
                }
                dispatch(
                    setPageLoadingStatus({
                        isPageLoading: false,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    setPageLoadingStatus({
                        isPageLoading: false,
                    })
                );
            });
    };

    const handleChange = (event: any) => {
        setBillingDetails({
            ...billingDetails,
            [event.target.name]:
                event.target.type == "textarea" ||
                event.target.name == "facilityName" ||
                event.target.name == "city" ||
                event.target.name == "state"
                    ? event.target.value.replace(/[><]/g, "")
                    : event.target.value,
        });
    };

    const submitFacilityBillingDetails = async (event: any) => {
        delete billingDetails.updatedAt;
        delete billingDetails.updatedBy;
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        updateBillingDetails(hospitalId, categoryId, billingDetails, isEUP)
            .then((updateResponse: any) => {
                setIsContinuePaymentClicked(false);
                setSavedBillingDetails(billingDetails);
                if (!readOnly) {
                    setReadOnly(!readOnly);
                }
                const toast = {
                    message: `Billing details successfully saved.`,
                    code: "Success:",
                    type: "success",
                };
                dispatch(showToast(toast));
                close();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            })
            .catch((error: any) => {
                if (
                    !isCorporation &&
                    error.details &&
                    error.details[0]?.message ===
                        CONSTANTS.REGISTRATION_COMPLETED_BY_CORPORATION
                ) {
                    history.push("/successpayment");
                }
                setIsContinuePaymentClicked(false);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    const submitBillingInfo = async (event: any) => {
        event.preventDefault();
        if (isCorporation) {
            setDisabled(true);
            delete billingDetails.updatedAt;
            delete billingDetails.updatedBy;
            updateBillingDetails(hospitalId, categoryId, billingDetails)
                .then((response: any) => {
                    if (!readOnly) {
                        setReadOnly(!readOnly);
                    }
                    if (response.success) {
                        if (
                            selectedCountryCode !==
                            CONSTANTS.DOMESTIC_COUNTRY_CODE
                        ) {
                            jumpToNextTab();
                        } else {
                            setIsContinuePaymentClicked(false);
                            setSavedBillingDetails(billingDetails);
                            const toast = {
                                message: "Data successfully updated.",
                                code: "Success:",
                                type: "success",
                            };
                            dispatch(showToast(toast));
                            setDisabled(false);
                        }
                    }
                })
                .catch(() => {
                    setDisabled(false);
                });
        } else if (offlinePayment) {
            handleSkipPaymentSubmit(event);
            setOfflinePayment(false);
        } else if (facilityBillingDetails) {
            submitFacilityBillingDetails(event);
            setOfflinePayment(false);
        } else {
            setIsContinuePaymentClicked(true);
            setReadOnly(true);
            delete billingDetails.updatedAt;
            delete billingDetails.updatedBy;
            updateBillingDetails(hospitalId, categoryId, billingDetails)
                .then((updateResponse: any) => {
                    if (updateResponse.success) {
                        programSubscription(hospitalId, programId, categoryId)
                            .then((programSubscriptionResponse: any) => {
                                setIsContinuePaymentClicked(false);
                                window.location =
                                    programSubscriptionResponse.data.redirectUrl;
                            })
                            .catch(() => {
                                setIsContinuePaymentClicked(false);
                            });
                    }
                })
                .catch(() => {
                    setIsContinuePaymentClicked(false);
                });
        }
    };

    const handleSkipPaymentSubmit = async (event: any) => {
        setOfflinePayment(true);
        setIsPayOfflineClicked(true);
        delete billingDetails.updatedAt;
        delete billingDetails.updatedBy;
        try {
            const responses = await Promise.all([
                updateBillingDetails(
                    hospitalId,
                    categoryId,
                    billingDetails,
                    isEUP
                ),
                skipPayment(hospitalId, programId, categoryId, isEUP),
            ]);
            const programSubscriptionResult = await programSubscription(
                hospitalId,
                programId,
                categoryId,
                CONSTANTS.PAYMENT_OFFLINE,
                isEUP
            );
            if (
                responses[0].success &&
                responses[1].success &&
                programSubscriptionResult.success
            ) {
                if (isEUP) {
                    history.push("/successpayment");
                } else if (isCorporation) {
                    setPaymentComplete(true);
                }
            }
            setOfflinePayment(false);
            setIsPayOfflineClicked(false);
        } catch (error: any) {
            if (
                !isCorporation &&
                error.details &&
                error.details[0]?.message ===
                    CONSTANTS.REGISTRATION_COMPLETED_BY_CORPORATION
            ) {
                history.push("/successpayment");
            } else if (isCorporation) {
                setPaymentComplete(true);
            }
            setIsPayOfflineClicked(false);
        }
    };

    const jumpToPrevTab = () => {
        setActiveTab(activeTab - 1);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const jumpToNextTab = () => {
        setDisabled(false);
        setBillingComplete(true);
        setActiveTab(activeTab + 1);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const cancelEdit = () => {
        setBillingDetails(savedBillingDetails);
        setReadOnly(!readOnly);
    };

    const modifyEditMode = () => {
        if (!readOnly) {
            cancelEdit();
        } else {
            setBillingDetails({
                ...JSON.parse(JSON.stringify(savedBillingDetails)),
                ...billingDetails,
            });
            setReadOnly(!readOnly);
        }
    };

    return (
        <PaymentSummaryWrapper
            aria-labelledby="billing-details  tab Content"
            className="container"
        >
            {parseJwt(window.localStorage.getItem("userAccessToken"))
                .role_code == CONSTANTS.USER_ROLES.HOSPITAL_ADMIN ? (
                <BreadCrumb breadcrumbItems={breadcrumbItems} />
            ) : (
                " "
            )}
            <form ref={formRef} onSubmit={submitBillingInfo}>
                <div className="fd-title-border d-flex justify-content-between payment-title mb-lg-4 mb-3">
                    <h1 className="h3 font-normal" id="billing-details">
                        Billing Details
                    </h1>
                    <div className="edit-measures-link d-flex align-items-center">
                        <div className="edit-btn-toggle">
                            <input
                                type="checkbox"
                                id="editBillingDetails"
                                className="edit-btn-checkbox"
                                role="button"
                                aria-pressed={readOnly}
                                onClick={(event) => {
                                    event.preventDefault();
                                    modifyEditMode();
                                }}
                            />
                            {readOnly ? (
                                <label
                                    htmlFor="editBillingDetails"
                                    className="toggle-label"
                                >
                                    Edit
                                </label>
                            ) : (
                                <label
                                    htmlFor="editBillingDetails"
                                    className="toggle-label"
                                >
                                    Cancel Edit
                                </label>
                            )}
                            {readOnly ? (
                                <div
                                    aria-live="polite"
                                    className="d-block d-md-none sr-only"
                                >
                                    Edit billing details
                                </div>
                            ) : (
                                <div
                                    aria-live="polite"
                                    className="d-block d-md-none sr-only"
                                >
                                    Cancel editing billing details
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <div className="row">
                        <div className="pb-3 font-14 col-12 d-flex flex-row-reverse">
                            <div className="mand-field">
                                <sup>*</sup>
                                mandatory fields
                            </div>
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label
                                htmlFor="billingFacilityName"
                                className="label-form"
                            >
                                Organization Name
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                disabled={readOnly}
                                className="form-control"
                                name="facilityName"
                                id="billingFacilityName"
                                value={billingDetails.facilityName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label
                                htmlFor="billingContactName"
                                className="label-form"
                            >
                                Billing Contact Name
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                className="form-control"
                                name="billingContactName"
                                id="billingContactName"
                                pattern={
                                    CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_AND_SPACE_ONLY
                                }
                                value={billingDetails.billingContactName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label
                                htmlFor="billingAddress"
                                className="label-form"
                            >
                                Billing Address
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <textarea
                                rows={3}
                                disabled={readOnly}
                                className="form-control"
                                name="billingAddress"
                                id="billingAddress"
                                value={billingDetails.billingAddress}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label htmlFor="billingCity" className="label-form">
                                City
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                disabled={readOnly}
                                className="form-control"
                                name="city"
                                id="billingCity"
                                value={billingDetails.city}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label
                                htmlFor="billingState"
                                className="label-form"
                            >
                                State / province
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                disabled={readOnly}
                                value={billingDetails.state}
                                className="form-control"
                                name="state"
                                id="billingState"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label htmlFor="countryName" className="label-form">
                                Location
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                disabled={readOnly}
                                value={billingDetails.countryName}
                                className="form-control"
                                name="countryName"
                                id="countryName"
                                pattern={
                                    CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_AND_SPACE_ONLY
                                }
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label
                                htmlFor="billingZipCode"
                                className="label-form"
                            >
                                Zip Code / Postal Code
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                disabled={readOnly}
                                className="form-control"
                                name="zipCode"
                                id="billingZipCode"
                                minLength={2}
                                maxLength={9}
                                pattern="\d*"
                                onChange={handleChange}
                                value={billingDetails.zipCode}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label htmlFor="phoneNumber" className="label-form">
                                Phone Number
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                disabled={readOnly}
                                className="form-control"
                                name="phoneNumber"
                                id="phoneNumber"
                                minLength={10}
                                maxLength={20}
                                pattern="\+?\d*"
                                onChange={handleChange}
                                value={billingDetails.phoneNumber}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row required">
                        <div className="col-md-4 form-label">
                            <label
                                htmlFor="emailAddress"
                                className="label-form"
                            >
                                Email Address
                                <sup>*</sup>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="email"
                                disabled={readOnly}
                                className="form-control"
                                name="emailAddress"
                                id="emailAddress"
                                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                onChange={handleChange}
                                value={billingDetails.emailAddress}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="billing-btns row no-gutters d-block d-md-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column flex-md-row align-items-center">
                        <div className="d-md-inline-block billing-offline text-center">
                            {isCorporation ? (
                                <div className="d-flex flex-row flex-wrap justify-content-center">
                                    <button
                                        onClick={close}
                                        type="button"
                                        className="btn btn-round btn-secondary mr-0 mr-md-3 mb-4 mb-md-0"
                                        aria-describedby="admin-agreementdetails"
                                        disabled={disabled}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={jumpToPrevTab}
                                        type="button"
                                        className="btn btn-round btn-secondary mb-4 mb-md-0"
                                        disabled={disabled}
                                        aria-describedby="admin-programdetails"
                                    >
                                        Previous
                                    </button>
                                    {selectedCountryCode !==
                                        CONSTANTS.DOMESTIC_COUNTRY_CODE &&
                                        readOnly && (
                                            <button
                                                onClick={jumpToPrevTab}
                                                type="button"
                                                className="btn btn-round btn-primary text-center ml-0 ml-md-3 "
                                                disabled={!disabled}
                                                aria-describedby="admin-programdetails"
                                            >
                                                Next
                                            </button>
                                        )}
                                    {selectedCountryCode !==
                                        CONSTANTS.DOMESTIC_COUNTRY_CODE &&
                                        !readOnly && (
                                            <button
                                                type="submit"
                                                className={`btn btn-round btn-primary text-center ml-0 ml-md-3 ${
                                                    disabled
                                                        ? CONSTANTS.BUTTON_SPINNER
                                                        : ""
                                                }`}
                                                aria-describedby="admin-billingdetails"
                                                aria-label="Save Billing details"
                                                disabled={
                                                    isContinuePaymentClicked ||
                                                    isPayOfflineClicked ||
                                                    disabled
                                                }
                                            >
                                                Save
                                            </button>
                                        )}
                                    {selectedCountryCode ===
                                        CONSTANTS.DOMESTIC_COUNTRY_CODE && (
                                        <button
                                            type="submit"
                                            className={`btn btn-round btn-secondary text-center ml-0 ml-md-3 ${
                                                disabled
                                                    ? CONSTANTS.BUTTON_SPINNER
                                                    : ""
                                            }`}
                                            aria-describedby="admin-billingdetails"
                                            aria-label="Save Billing details"
                                            disabled={
                                                isContinuePaymentClicked ||
                                                isPayOfflineClicked ||
                                                disabled
                                            }
                                        >
                                            Save
                                        </button>
                                    )}
                                    {selectedCountryCode ===
                                        CONSTANTS.DOMESTIC_COUNTRY_CODE && (
                                        <button
                                            className={`btn btn-primary btn-round ml-md-4 billing-offline-btn ${
                                                isPayOfflineClicked
                                                    ? CONSTANTS.BUTTON_SPINNER
                                                    : ""
                                            }`}
                                            type="button"
                                            {...(formRef.current?.checkValidity()
                                                ? {
                                                      "data-toggle": "modal",
                                                      "data-target":
                                                          "#confirmationModal",
                                                  }
                                                : {
                                                      onClick: () => {
                                                          if (formRef.current)
                                                              formRef.current.requestSubmit();
                                                      },
                                                  })}
                                            disabled={
                                                isContinuePaymentClicked ||
                                                isPayOfflineClicked ||
                                                disabled
                                            }
                                        >
                                            Pay Offline
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <div className="d-flex flex-row flex-wrap justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-round billing-btn2 mb-4 mb-md-0"
                                        disabled={
                                            isContinuePaymentClicked ||
                                            isPayOfflineClicked
                                        }
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            history.push(
                                                `/programs/${programId}/category/${categoryId}/agreement`
                                            );
                                        }}
                                        aria-label="Cancel Payment"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className={`btn btn-secondary btn-round ml-0 ml-md-4 mb-4 mb-md-0 ${
                                            disabled
                                                ? CONSTANTS.BUTTON_SPINNER
                                                : ""
                                        }`}
                                        aria-describedby="admin-billingdetails"
                                        aria-label="Save Billing details"
                                        onClick={() =>
                                            setFacilityBillingDetails(true)
                                        }
                                        disabled={
                                            isContinuePaymentClicked ||
                                            isPayOfflineClicked
                                        }
                                    >
                                        Save
                                    </button>
                                    <button
                                        className={`btn btn-primary btn-round ml-md-4 billing-offline-btn ${
                                            isPayOfflineClicked
                                                ? CONSTANTS.BUTTON_SPINNER
                                                : ""
                                        }`}
                                        type="button"
                                        {...(formRef.current?.checkValidity()
                                            ? {
                                                  "data-toggle": "modal",
                                                  "data-target":
                                                      "#confirmationModal",
                                              }
                                            : {
                                                  onClick: () => {
                                                      if (formRef.current)
                                                          formRef.current.requestSubmit();
                                                  },
                                              })}
                                        disabled={
                                            isContinuePaymentClicked ||
                                            isPayOfflineClicked
                                        }
                                    >
                                        Pay Offline
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
            {selectedCountryCode === CONSTANTS.DOMESTIC_COUNTRY_CODE && (
                <DynamicModal
                    modalText={
                        <div>
                            You have chosen to <b>pay offline</b>. We will
                            connect you with payment details.
                        </div>
                    }
                    dismissText="Cancel"
                    actionText="Confirm"
                    actionFunction={handleSkipPaymentSubmit}
                />
            )}
        </PaymentSummaryWrapper>
    );
};

export default PaymentSummaryPage;
