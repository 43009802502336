import React, {
    forwardRef,
    useEffect,
    useState,
    useImperativeHandle,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import CONSTANTS from "common/constants";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch } from "react-redux";
import { BreadCrumb } from "components/BreadCrumb";
import ProgramsDetails from "pages/Admin/FacilityDetails/ProgramDetails";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getAssetDetails } from "api/certificateAPI";
import axios from "axios";
import { exportEMSDetails, showModal } from "common/utils";
import store from "app/store";
import MoreActions from "components/MoreActions/MoreActions";
import { approveHospital, deactivateHospital } from "api/adminDashboardAPI";
import HospitalDetails from "../../Admin/FacilityDetails/HospitalDetails";
import FacilityDetailsBanner from "../../Admin/HospitalBanner/FacilityDetailsBanner";
import { FacilityDetailsWrapper } from "../../Admin/FacilityDetails/styled";
import { getSubscriptionDetails } from "../../../api/adminDashboardAPI";
import AgreementDetails from "../../Admin/FacilityDetails/AgreementDetails";

interface Props {
    hospital: any;
    returnPath: any;
    programData: any;
    updateHospitalInfo?: any;
    certificateData?: any;
}
export const OrganizationDetails = forwardRef((props: Props, ref) => {
    const [hospital, setHospital] = useState<any>(props.hospital);
    const [programData, setProgramData] = useState<any>(props.programData);
    const [activeTab, setActiveTab] = useState<any>(1);
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [orderId, setOrderId] = useState<any>();
    const [agreementInfo, setAgreementInfo] = useState<any>({});
    const [activateFacilityActivateInd, setActivateFacilityActivateInd] =
        useState<boolean>(false);
    const [deactivateFacilityActivateInd, setDeactivateFacilityActivateInd] =
        useState<boolean>(false);
    const [isApprovalRestricted, setApprovalRestricted] =
        useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const params: any = useParams();
    useEffect(() => {
        setHospital(props.hospital);
        props.hospital && modifyLastEditedData(props.hospital);
        getSubscriptionDetails(
            params.hospitalId,
            params.programId,
            params.categoryId
        )
            .then((response: any) => {
                if (response?.success) {
                    setOrderId(response?.data?.orderId);
                }
            })
            .catch((error: any) => {
                dispatch(showToast(error));
            });
    }, [props.hospital]);
    useEffect(() => {
        const elementsArray = [];
        if (props.returnPath?.state?.adminOrganizationMangemnetTab) {
            elementsArray.push(
                {
                    label:
                        props.returnPath?.state
                            ?.adminOrganizationMangemnetTab == CONSTANTS.PENDING
                            ? "Pending"
                            : props.returnPath?.state
                                  ?.adminOrganizationMangemnetTab ==
                              CONSTANTS.REGISTRED
                            ? "Registered"
                            : props.returnPath?.state
                                  ?.adminOrganizationMangemnetTab ==
                              CONSTANTS.AWARDED
                            ? "Awarded"
                            : "Deactivated",
                    returnPath: props.returnPath,
                },
                {
                    label: location.state.facilityName,
                    returnPath: props.returnPath,
                }
            );
        }
        setBreadcrumbItems(elementsArray);
    }, [props.returnPath]);

    const handleHospitalDataChange = (modifiedData: any) => {
        setHospital(modifiedData);
        modifyLastEditedData(modifiedData);
    };
    useEffect(() => {
        setProgramData(props.programData);
        props.programData && modifyLastEditedData(props.programData);
    }, [props.programData]);
    useEffect(() => {
        props.updateHospitalInfo && props.updateHospitalInfo(hospital);
    }, [hospital]);
    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };
    const close = () => {
        history.push(props.returnPath || "/emsadmin/organization-management");
    };

    const handleProgramDataChange = (modifiedData: any) => {
        setProgramData(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleAgreementDataChange = (modifiedData: any) => {
        setAgreementInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const exportFacilityData = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getAssetDetails(CONSTANTS.EMS_EXPORT_FACILITY_TEMPLATE, true).then(
            async (presignedURL: any) => {
                if (
                    presignedURL.data?.assets?.[0].isDownloadable &&
                    presignedURL.data?.assets?.[0].presignedUrl
                ) {
                    const iframe: any = document.createElement("iframe");
                    iframe.setAttribute("height", "0");
                    iframe.setAttribute("width", "0");
                    iframe.className = "hiddenFrame";

                    try {
                        const axiosResponse = await axios({
                            method: "get",
                            url: presignedURL.data?.assets?.[0].presignedUrl,
                        });

                        if (axiosResponse.data) {
                            const templateHtml = axiosResponse.data;
                            /* add iframe to the main document for canvas creation and print */
                            document.body.appendChild(iframe);

                            iframe.onload = async () => {
                                await exportEMSDetails(
                                    hospital,
                                    programData,
                                    agreementInfo
                                );
                                const toast = {
                                    message:
                                        "Organization details successfully exported.",
                                    code: "Success:",
                                    type: "success",
                                };
                                dispatch(showToast(toast));

                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            };

                            iframe.contentWindow.document.open();
                            iframe.contentWindow.document.write(templateHtml);
                            iframe.contentWindow.document.close();
                        }
                    } catch {
                        const toast = {
                            message: "Export template doesn't exist.",
                        };

                        dispatch(showToast(toast));
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                }
            }
        );
    };

    const activateFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const activateResult = await deactivateHospital(
            hospital.facilityCode,
            true,
            hospital.hospitalId,
            null,
            parseInt(params.categoryId, 10)
        );
        if (activateResult.success) {
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
            const toast = {
                message: `${hospital.facilityName} successfully activated.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            close();
            activateFacilityConfirmationDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };

    const sortDropdown = [
        {
            label: CONSTANTS.EXPORT,
            value: CONSTANTS.EXPORT,
            className: "aha-icon-export menu-cst-icon",
        },
    ];

    if (orderId !== null) {
        sortDropdown.push({
            label:
                hospital.approvalStatus === CONSTANTS.PENDING
                    ? CONSTANTS.APPROVE
                    : CONSTANTS.ACTIVATE,
            value:
                hospital.approvalStatus === CONSTANTS.PENDING
                    ? CONSTANTS.APPROVE
                    : CONSTANTS.ACTIVATE,
            className: "aha-icon-tick-mark mr-2 font-red",
        });
    }

    const callParentOnSelect = (value: any) => {
        if (value === CONSTANTS.EXPORT) {
            return exportFacilityData();
        }
        if (value === CONSTANTS.APPROVE) {
            return approveFacility();
        }
        if (value === CONSTANTS.ACTIVATE) {
            return activateFacilityConfirmationPopupModal();
        }
    };

    const activateFacilityConfirmationPopupModal = () => {
        setActivateFacilityActivateInd(true);
        showModal();
    };
    const activateFacilityConfirmationDismissModal = () => {
        setActivateFacilityActivateInd(false);
        showModal();
    };

    const approveFacility = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        approveHospital(
            hospital.hospitalId,
            params.programId,
            params.categoryId,
            "APPROVED"
        )
            .then((response) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (response.success && response.statusCode === 200) {
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "Organization Approved:",
                            message: `Organization ${hospital.facilityName} successfully approved for the ${programData.programCategoryName} program.`,
                            type: "success",
                        })
                    );
                    close();
                }
            })
            .catch((error) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (
                    error.message ===
                    CONSTANTS.LOCKED_MEASURE_FOR_ORGANIZATION_APPROVALS
                ) {
                    setApprovalRestricted(true);
                    showModal();
                } else {
                    store.dispatch(showToast(error));
                }
            });
    };

    const dismissMeasureLockedWaringPopUp = () => {
        setApprovalRestricted(false);
        showModal();
    };

    useImperativeHandle(ref, () => ({
        exportFacilityData,
        close,
    }));

    return (
        <FacilityDetailsWrapper>
            {(hospital.approvalStatus === CONSTANTS.PENDING ||
                hospital.isActive == false) && (
                <BreadCrumb breadcrumbItems={breadcrumbItems} />
            )}
            {(hospital.approvalStatus === CONSTANTS.PENDING ||
                (!hospital.isActive &&
                    hospital.approvalStatus === CONSTANTS.REGISTRED) ||
                (!hospital.isActive &&
                    hospital.approvalStatus === CONSTANTS.AWARDED)) && (
                <div className="d-flex justify-content-between align-items-center mb-4 fac-heading">
                    <h1 className="h2 mb-4 font-400 fac-heading">
                        Organization Management
                    </h1>
                    <div className="d-flex">
                        <div
                            id="moreactions"
                            className="d-flex align-items-center more-actions"
                        >
                            <div
                                className="export-dropdown ml-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="More Actions"
                                data-container="#moreactions"
                            >
                                <MoreActions
                                    sortDropdown={sortDropdown}
                                    hospital={hospital}
                                    callParentOnSelect={callParentOnSelect}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {hospital.isActive === false && activateFacilityActivateInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal show fade aui-modal"
                        tabIndex={-1}
                        aria-labelledby="modalLabel"
                        aria-modal="true"
                        role="dialog"
                        style={{
                            display: activateFacilityActivateInd
                                ? "block"
                                : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <span className="sr-only">
                                            Confirm Activate Organization
                                        </span>
                                        <p className="pb-2">
                                            Are you sure you want to
                                            activate&nbsp;
                                            <b>{hospital.facilityName}</b>{" "}
                                            organization?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                aria-label="Cancel Activation and close the modal"
                                                onClick={
                                                    activateFacilityConfirmationDismissModal
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                onClick={activateFacility}
                                                aria-label="Confirm Activate"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
            <FacilityDetailsBanner
                hospital={hospital}
                programData={programData}
                lastEditedData={lastEditedData}
                exportFacilityDetailsData={exportFacilityData}
                certificateData={props.certificateData}
                close={close}
                orderId={orderId}
                isEmsAdmin
            />
            {isApprovalRestricted && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal fade show aui-modal approval-modal"
                        tabIndex={-1}
                        aria-labelledby="detailsConfirmApprovalModal"
                        aria-modal="true"
                        role="dialog"
                        style={{
                            display: isApprovalRestricted ? "block" : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <div className="modal-header">
                                        <h2
                                            className="h5 font-600"
                                            id="detailsConfirmApprovalModal"
                                        >
                                            Warning
                                        </h2>
                                    </div>
                                    <p className="pb-0">
                                        Approving organization is currently
                                        restricted as measure lock is active.
                                    </p>
                                    <div className="mt-4 d-flex justify-content-end">
                                        <button
                                            className="btn btn-primary btn-round btn-sm mx-2"
                                            aria-label="close the modal"
                                            onClick={
                                                dismissMeasureLockedWaringPopUp
                                            }
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
            <div
                className="d-flex flex-column flex-md-row flex-wrap aui-accordion-arrowtab"
                id="facilityDetails"
                role="tablist"
            >
                <button
                    onClick={() => setActiveTab(1)}
                    className="aui-acc-arrowtab-item aui-acc-arrtab-z1 aui-accordion-arrowtab1"
                    data-toggle="collapse"
                    data-target="#organizationDetails"
                    aria-expanded={activeTab == 1}
                    role="tab"
                    id="admin-facilitydetails"
                >
                    <span className="aui-acc-arrowtab-btn">
                        <span className="btn btn-text aui-acc-arrowtab-text">
                            Organization Details
                            <i className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none" />
                        </span>
                    </span>
                </button>
                <button
                    onClick={() => setActiveTab(2)}
                    className="aui-acc-arrowtab-item aui-acc-arrtab-z2 aui-accordion-arrowtab2"
                    data-toggle="collapse"
                    data-target="#programDetails"
                    aria-expanded={activeTab == 2}
                    role="tab"
                    id="admin-programdetails"
                >
                    <span className="aui-acc-arrowtab-btn">
                        <span className="btn btn-text aui-acc-arrowtab-text">
                            Program Details
                            <i className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none" />
                        </span>
                    </span>
                </button>
                <button
                    onClick={() => setActiveTab(3)}
                    className="aui-acc-arrowtab-item aui-acc-arrtab-z3 aui-accordion-arrowtab3"
                    data-toggle="collapse"
                    data-target="#agreementDetails"
                    aria-expanded={activeTab == 3}
                    role="tab"
                    id="admin-agreementdetails"
                    disabled={
                        !programData?.agreementReferenceId &&
                        programData?.programCategoryId !=
                            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                    }
                >
                    <span className="aui-acc-arrowtab-btn">
                        <span className="btn btn-text aui-acc-arrowtab-text">
                            Agreement Details
                            <i className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none" />
                        </span>
                    </span>
                </button>
                <div
                    data-testid="organizationDetails"
                    id="organizationDetails"
                    className={`collapse aui-accordion-arrowcontent aui-acc-arrc1 ${
                        activeTab == 1 ? "show" : "hide"
                    }`}
                    data-parent="#organizationDetails"
                    role="tabpanel"
                >
                    <HospitalDetails
                        hospital={hospital}
                        setHospital={handleHospitalDataChange}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        close={close}
                        orderId={orderId}
                    />
                </div>
                <div
                    id="programDetails"
                    className={`collapse aui-accordion-arrowcontent aui-acc-arrc2 ${
                        activeTab == 2 ? "show" : "hide"
                    }`}
                    data-parent="#organizationDetails"
                    role="tabpanel"
                >
                    <ProgramsDetails
                        hospital={hospital}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        close={close}
                        orderId={orderId}
                        programData={programData}
                        setRegistryInfo={undefined}
                        setRegistryQuestionData={undefined}
                        setProgramData={handleProgramDataChange}
                        isEmsAdmin
                    />
                </div>
                <div
                    id="agreementDetails"
                    className={`collapse aui-accordion-arrowcontent aui-acc-arrc3 ${
                        activeTab == 3 ? "show" : "hide"
                    }`}
                    data-parent="#facilityDetails"
                    role="tabpanel"
                >
                    <AgreementDetails
                        hospital={hospital}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        close={close}
                        setAgreementInfo={handleAgreementDataChange}
                        orderId={orderId}
                        emsAdmin
                    />
                </div>
            </div>
        </FacilityDetailsWrapper>
    );
});
export default OrganizationDetails;
