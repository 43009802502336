import styled from "styled-components";

export const FacilityDetailsWrapper = styled.section`
    width: 100%;
    .more-actions {
        position: relative;
        height: 40px;
        .tooltip {
            transform: none !important;
            top: -45px !important;
            left: auto !important;
            right: 0;
            &:before {
                left: auto !important;
                right: 8px;
            }
            &:after {
                left: auto !important;
                right: 8px;
            }
            .tooltip-inner {
                width: 105px;
            }
        }
        .menu-cst-select-fld {
            height: auto;
        }
        .menu-cst-select-dropdown {
            margin-top: -10px;
        }
        .menu-cst-selected-item {
            padding: 0;
        }
    }
    .aui-accordion-arrowcontent {
        margin-top: 20px;
        @media only screen and (min-width: 768px) {
            margin-top: 43px;
        }
    }
    .fd-title-border {
        border-bottom: 2px solid #c10e21;
        margin-bottom: 26px;
        .fd-edit-btn {
            color: #c10e21;
            .fd-edit {
                font-size: 24px;
            }
            .fd-canceledit {
                font-size: 16px;
            }
        }
    }
    .measure-help {
        color: #c10e21;
        font-size: 15px;
        background-color: transparent;
        border: none;
        margin: -2px 10px 0 10px;
    }
    .fd-table {
        tbody {
            tr {
                .aui-row-th {
                    vertical-align: top;
                }
                @media only screen and (min-width: 576px) {
                    .aui-row-th {
                        width: 50%;
                    }
                    .aui-row-td {
                        width: 50%;
                    }
                }
                @media only screen and (min-width: 768px) {
                    .aui-row-th {
                        width: 40%;
                    }
                    .aui-row-td {
                        width: 60%;
                    }
                }
                @media only screen and (min-width: 992px) {
                    .aui-row-th {
                        width: 45%;
                        padding-right: 75px;
                    }
                    .aui-row-td {
                        width: 55%;
                    }
                }
            }
        }
        .multi-td {
            .multi-td-label {
                flex: 0 0 100px;
                @media only screen and (min-width: 576px) {
                    flex: 0 0 90px;
                }
                @media only screen and (min-width: 768px) {
                    flex: 0 0 120px;
                }
                @media only screen and (min-width: 992px) {
                    flex: 0 0 150px;
                }
            }
        }
        .multi-td + .multi-td {
            border-top: 1px solid #e3e3e3;
        }
        .multi-td:first-child {
            margin-top: -16px;
        }
        .multi-td:last-child {
            margin-bottom: -16px;
        }
    }
    .st-field {
        width: 100px;
    }
    .ehr {
        border: 1px solid #e3e3e3;
        background-color: #f8f8f8;
        input {
            height: 34px;
        }
    }
    .reg-btn {
        border-top: 1px solid #e3e3e3;
        padding-top: 24px;
    }
    .aui-accordion-tab .aui-accordion-content {
        z-index: auto;
    }
    .fb-table-btn {
        &.fb-btn-edit {
            border-top: 1px solid #e3e3e3;
            margin-top: 36px;
            padding-top: 24px;
        }
        .fd-btn-1,
        .fd-btn-2 {
            width: 100%;
            margin-bottom: 24px;
            @media only screen and (min-width: 576px) {
                width: 49%;
                margin-bottom: 0;
            }
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
        .fd-btn-2 {
            @media only screen and (min-width: 576px) {
                margin-left: 2%;
            }
            @media only screen and (min-width: 768px) {
                margin-left: 24px;
            }
        }
        .fd-btn-3 {
            width: 100%;
            @media only screen and (min-width: 576px) {
                margin-top: 24px;
            }
            @media only screen and (min-width: 768px) {
                margin-left: 24px;
                width: auto;
                margin-top: 0;
            }
        }
    }
    .btn-agg-action {
        padding-left: 20px;
        padding-right: 20px;
        white-space: nowrap;
    }
    .last-tab-btns {
        .btn {
            width: 100%;
            margin: 0 0 24px;
            @media only screen and (min-width: 576px) {
                width: 48.5%;
                margin-bottom: 24px;
            }
            @media only screen and (min-width: 768px) {
                width: auto;
                margin-left: 24px;
                margin-bottom: 0;
            }
        }
        .btn:nth-child(1) + .btn:nth-child(2),
        .btn + .btn + .btn {
            @media only screen and (min-width: 576px) {
                margin-left: 3%;
            }
            @media only screen and (min-width: 1200px) {
                margin-left: 24px;
            }
        }
    }
    .dynamic-form {
        .form-group {
            label + div {
                align-items: flex-start !important;
            }
        }
        .form-radio {
            label {
                white-space: nowrap;
            }
        }
    }
    .upload-agr-icon {
        font-size: 20px;
    }
    .aui-row-td {
        font-size: 16px;
    }
    .reg-authorise {
        border: 1px solid #e3e3e3;
        padding: 20px 14px;
        background: #f8f8f8;
        margin-top: 20px;
    }
    .create-ems-id-modal {
        &.aui-modal {
            .modal-header {
                border-bottom: 2px solid #c10e21;
            }
        }
    }
    .title-dropdown {
        .cst-select-dropdown {
            width: 125px;
        }
    }
    .renew-document-btn {
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: auto;
            }
        }
        .tooltip {
            @media only screen and (min-width: 576px) {
                margin-left: -40px;
            }
            @media only screen and (min-width: 768px) {
                margin-left: 0;
            }
        }
    }
    .renew-tooltip {
        position: absolute;
        bottom: calc(100% + 18px);
        right: 0;
        display: none;
    }
    .renew-btn {
        position: relative;
        .renew-button {
            &:hover,
            &:focus {
                & + .renew-tooltip {
                    display: block;
                    opacity: 1;
                    & .tooltip-inner {
                        &:before,
                        &:after {
                            content: "";
                            position: absolute;
                            border-style: solid;
                            display: block;
                            width: 0;
                            z-index: 0;
                            left: 50%;
                            border-width: 0.5625rem 0.5625rem 0;
                        }
                        &:before {
                            border-color: #e3e3e3 transparent;
                            bottom: -9px;
                        }
                        &:after {
                            border-color: #fff transparent;
                            bottom: -8px;
                        }
                        @media only screen and (min-width: 992px) {
                            width: 190px;
                        }
                    }
                }
            }
        }
    }
    .aui-modal .modal-header {
        border-bottom: 2px solid rgb(193, 14, 33);
    }
`;
