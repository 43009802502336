import React, { Component, useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Pagination, { IPagination } from "components/Pagination";

import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import { getNavigationByHospitalId, getUserHospitals } from "api/usersApi";
import TableRowCount from "components/TableRowCount";
import {
    calculatNumOfDaysBetweenDates,
    expireDataRowStatus,
    getCurrentQuarter,
    hospitalApprovalStatus,
    localDateToYYYYMMDDFormatString,
    wordSplit,
} from "common/utils";
import CONSTANTS from "common/constants";
import ExpireIndicator from "components/ExpireIndicator";
import { UserDashboardWrapper } from "./styled";
import "styles/global.scss";
import SmartSearch from "../../components/SmartSearch";
import {
    setQuarter,
    setYear,
} from "../../components/MeasuresDateFilter/measuresDateFilter.slice";
import "styles/expire-tooltip.scss";
import { getActiveServiceYear } from "api/bulkRenewApi";
import { logger } from "utils/logger.utils";

const UserDashboardPage = () => {
    const history = useHistory();
    const location = useLocation();
    const [hospitals, setHospitals] = useState<any>([]);
    const dispatch = useDispatch();
    const [roleValue, setRoleValue] = useState<string>("none");

    /* Pagination - START */
    const [pageNumber, setPageNumber] = useState<number>(
        location?.state?.pageNum || 1
    );
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [facilitiesPresent, setFacilitiesPresent] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>(
        location?.state?.search || ""
    );
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [totalFacilityCount, setTotalFacilityCount] = useState<number>(0);
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        totalCount: 0,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    /* Pagination - END */
    const [activeServiceYear, setActiveServiceYear] = useState<any>();

    useEffect(() => {
        setIsTableLoading(true);
        !searchKey && dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getUserHospitals(pageNumber, pagination.pageSize, searchKey).then(
            (response: any) => {
                if (response.statusCode == 200 && response?.data?.facilities) {
                    response.data.facilities.length > 0 &&
                        setFacilitiesPresent(true);
                    setHospitals(response.data.facilities);
                    window.scrollTo(0, 0);
                    setTotalFacilityCount(response.data.totalFacilityCount);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    setPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setDataLoaded(true);
                }
                setIsTableLoading(false);
            }
        );
    }, [pageNumber]);

    useEffect(() => {
        getActiveServiceYear()
            .then((response: any) => {
                setActiveServiceYear(response.data.activeServiceYear.year);
            })
            .catch((error: any) => {
                logger("error: ", error);
            });
        window.onbeforeunload = function () {
            history.replace();
            return undefined;
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const getSearchedFacilities = (value: string) => {
        setIsTableLoading(true);
        setPageNumber(1);
        setPagination(defaultPagination);
        getUserHospitals(1, pagination.pageSize, value).then(
            (response: any) => {
                if (response.statusCode == 200 && response?.data?.facilities) {
                    setRoleValue("alert");
                    setHospitals(response.data.facilities);
                    setTotalFacilityCount(response.data.totalFacilityCount);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    setPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                }
                setIsTableLoading(false);
            }
        );
    };

    const handleUserNavigate = (
        hospitalId: string | number,
        countryCode: string
    ) => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getNavigationByHospitalId(hospitalId).then(
            (navigationResponse: any) => {
                if (navigationResponse.statusCode === 200) {
                    const { entity, path } = navigationResponse.data.url;
                    const { info } = navigationResponse.data;
                    if (entity === CONSTANTS.HOSPITAL) {
                        localStorage.setItem(
                            "selectedCategoryId",
                            info?.categoryId
                        );
                        switch (path) {
                            case CONSTANTS.PATHS.HOSPITAL_INACTIVE:
                                history.push("/organization/inactive", {
                                    pageNum: pageNumber,
                                    search: searchKey,
                                });
                                break;
                            case CONSTANTS.PATHS.PROGRAM_SELECTION:
                                history.push(`/programs`, {
                                    pageNum: pageNumber,
                                    search: searchKey,
                                });
                                break;
                            case CONSTANTS.PATHS.PROGRAM_INACTIVE:
                                history.push("/inactive");
                                break;
                            case CONSTANTS.PATHS.AGREEMENT:
                                history.push(
                                    `/programs/${info.programId}/category/${info.categoryId}/agreement`,
                                    { pageNum: pageNumber, search: searchKey }
                                );
                                break;
                            case CONSTANTS.PATHS.PAYMENTSUMMARY:
                                if (
                                    countryCode ===
                                    CONSTANTS.DOMESTIC_COUNTRY_CODE
                                ) {
                                    history.push(
                                        `/paymentSummary/program/${info.programId}/category/${info.categoryId}`,
                                        {
                                            pageNum: pageNumber,
                                            search: searchKey,
                                        }
                                    );
                                } else {
                                    history.push(
                                        `/programs/${info.programId}/category/${info.categoryId}/agreement`,
                                        {
                                            pageNum: pageNumber,
                                            search: searchKey,
                                        }
                                    );
                                }
                                break;
                            case CONSTANTS.THANKYOU_PAGE:
                                history.push("/successpayment", {
                                    pageNum: pageNumber,
                                    search: searchKey,
                                });
                                break;
                            case CONSTANTS.PATHS.PAYMENT_STATUS:
                                history.push(
                                    `/paymentStatus?referenceId=${info.lastTransactionId}&lastSessionPayment=1`
                                );
                                break;
                            case CONSTANTS.PATHS.DASHBOARD:
                                if (info.programId !== 22) {
                                    // Navigate to "/dashboard"
                                    history.push("/dashboard", {
                                        pageNum: pageNumber,
                                        search: searchKey,
                                    });
                                } else {
                                    // Navigate to a specific path based on other variables
                                    history.push(
                                        `/organizationDetails/${hospitalId}/programs/${info.programId}/categories/${info.categoryId}`,
                                        {
                                            pageNum: pageNumber,
                                            search: searchKey,
                                            activateMeasuresTab: true,
                                        }
                                    );
                                }

                                break;
                            default:
                                history.push(
                                    `/hospital/register/${hospitalId}`,
                                    { pageNum: pageNumber, search: searchKey }
                                );
                        }
                    } else {
                        history.push("/");
                    }
                }
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            }
        );
    };

    const setHospitalData = (hospital: any) => {
        localStorage.setItem("selectedHospitalId", hospital.hospitalId);
        localStorage.setItem("selectedProgramId", hospital.programId);
        localStorage.setItem("selectedHospitalCountryId", hospital.countryId);
        localStorage.setItem("selectedFacilityName", hospital?.facilityName);
        localStorage.setItem(
            "selectedHospitalExpiryDate",
            hospital?.programCertificateExpiryDate
        );
        localStorage.setItem(
            "selectedHospitalCountryCode",
            hospital.countryCode
        );
        if (hospital.programId == CONSTANTS.EMS_PROGRAM_ID) {
            dispatch(setYear(activeServiceYear));
        } else {
            dispatch(setYear(new Date().getFullYear()));
        }
        dispatch(setQuarter(getCurrentQuarter()));
        handleUserNavigate(hospital.hospitalId, hospital.countryCode);
    };

    const setHospitalDefaults = () => {
        history.push("/organization/register/new-organization");
        window.localStorage.setItem("isProgramApproved", "");
        window.localStorage.setItem("isHospitalDeActiveOrDeleted", "");
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const getHospitalStatus = (hospital: any) => {
        if (hospital?.programCertificateExpiryDate) {
            const days = calculatNumOfDaysBetweenDates(
                new Date(),
                hospital?.programCertificateExpiryDate
            );
            if (days >= 1 && days <= 50) {
                return "Expiring";
            }
            if (days === 0) {
                return "Expired";
            }
        }
        return `${hospital.approvalStatus
            .charAt(0)
            .toUpperCase()}${hospital.approvalStatus.slice(1).toLowerCase()}`;
    };
    const checkIsExpierSoon = (
        expiresOn: any,
        programId: any,
        hospitalId: any,
        isActive: any
    ) => {
        if (expiresOn && isActive) {
            return (
                <div>
                    <ExpireIndicator
                        expiresOn={expiresOn}
                        programId={programId}
                        hospitalId={hospitalId}
                    />
                </div>
            );
        }
        return "";
    };

    return (
        <UserDashboardWrapper className="container">
            <div className="user-titlebox d-md-flex justify-content-between align-items-center">
                <div>
                    <h1 className="h4 mb-0">User Dashboard</h1>
                </div>
                <div className="user-fac-reg pl-0 pl-md-4 pt-2 pt-md-0">
                    Total Organization Count: {totalFacilityCount}
                </div>
            </div>
            {dataLoaded && facilitiesPresent ? (
                <div>
                    <h2 className="h5 mb-3 mb-md-0">My Organizations</h2>
                    <div className="d-flex justify-content-end">
                        <SmartSearch
                            searchKey={searchKey}
                            setSearchKey={setSearchKey}
                            getSearchedItems={getSearchedFacilities}
                            arialabel=""
                            id="user-dashboard-search"
                        />
                    </div>
                    <div className="fac-search">
                        <TableRowCount
                            isFirst={pagination.isFirst}
                            pageNumber={pagination.pageNumber}
                            pageSize={pagination.pageSize}
                            isLast={pagination.isLast}
                            totalCount={pagination.totalCount}
                            roleValue={roleValue}
                        />
                    </div>
                    {isTableLoading ? (
                        <div
                            role="alert"
                            aria-label="my organizations table is loading"
                            className="sr-only"
                        />
                    ) : (
                        ""
                    )}
                    <div className="user-dashboard-table mt-2 w-100">
                        <table
                            className={`aui-responsive-status-table ${
                                isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                            }`}
                        >
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <div className="aui-th">
                                            Organization Details
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="aui-th">
                                            Location Details
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="aui-th">
                                            Program Type
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="aui-th">Status</div>
                                    </th>
                                </tr>
                            </thead>
                            {!isTableLoading ? (
                                <tbody>
                                    {hospitals.length > 0 &&
                                        hospitals.map((hospital: any) => {
                                            return (
                                                <>
                                                    <tr
                                                        key={
                                                            hospital.hospitalId
                                                        }
                                                        className={expireDataRowStatus(
                                                            hospital?.programCertificateExpiryDate,
                                                            hospital.isActive
                                                                ? hospital.approvalStatus
                                                                : CONSTANTS.DEACTIVATED
                                                        )}
                                                    >
                                                        <td data-title="Organization Details">
                                                            <div className="aui-td">
                                                                <div>
                                                                    <Link
                                                                        className="btn-text-link-uline text-left"
                                                                        onClick={() =>
                                                                            setHospitalData(
                                                                                hospital
                                                                            )
                                                                        }
                                                                        to={{}}
                                                                    >
                                                                        {
                                                                            hospital.facilityName
                                                                        }
                                                                    </Link>
                                                                    {hospital?.emsId && (
                                                                        <div className="mt-1">
                                                                            AHA
                                                                            EMS
                                                                            ID :{" "}
                                                                            <span>
                                                                                {
                                                                                    hospital?.emsId
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    <div className="mt-1">
                                                                        Organization
                                                                        Code:{" "}
                                                                        <span className="abbr-code">
                                                                            {wordSplit(
                                                                                hospital.facilityCode
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    {checkIsExpierSoon(
                                                                        hospital?.programCertificateExpiryDate,
                                                                        hospital?.programId,
                                                                        hospital?.hospitalId,
                                                                        hospital?.isActive
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td data-title="Location Details">
                                                            <div className="aui-td flex-column justify-content-center">
                                                                <div className="d-flex w-100">
                                                                    <div className="user-loc-title">
                                                                        Location:
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        {
                                                                            hospital.countryName
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100">
                                                                    <div className="user-loc-title">
                                                                        State:
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        {
                                                                            hospital.state
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td data-title="Program Type">
                                                            <div className="aui-td">
                                                                {hospital.programCategoryName ||
                                                                    "N/A"}
                                                            </div>
                                                        </td>
                                                        <td data-title="Status">
                                                            <div className="aui-td">
                                                                {hospital.isActive
                                                                    ? getHospitalStatus(
                                                                          hospital
                                                                      )
                                                                    : "Deactivated"}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                </tbody>
                            ) : null}
                        </table>
                        <div
                            role="alert"
                            aria-atomic="true"
                            className="table-emptystate-outer"
                        >
                            {hospitals.length < 1 && (
                                <div>No data to display</div>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        {pagination && Number(pagination.totalPages) > 0 ? (
                            <Pagination
                                pageNumber={pagination.pageNumber}
                                pageSize={pagination.pageSize}
                                totalCount={pagination.totalCount}
                                totalPages={pagination.totalPages}
                                isFirst={pagination.isFirst}
                                isLast={pagination.isLast}
                                setPageNumber={pagination.setPageNumber}
                                paginationSetLimit={paginationSetLimit}
                            />
                        ) : null}
                    </div>
                </div>
            ) : (
                dataLoaded && (
                    <div className="d-flex justify-content-center py-3 py-md-5 flex-column align-items-center user-emptystate">
                        <img
                            src="images/dashboard-image.png"
                            alt="No data available for Q C T or E M S"
                        />
                        <p className="mt-4 mb-5 user-emptystate-text">
                            Looks like you are a new user, Please{" "}
                            <button
                                className="btn-text-link-uline font-700"
                                data-toggle="modal"
                                data-target="#registerModal"
                            >
                                register
                            </button>{" "}
                            your organization
                        </p>
                        {/* Add New Facility Confirmation Modal */}
                        <div
                            className="facility-confirm-modal modal fade show aui-modal"
                            role="dialog"
                            id="registerModal"
                            tabIndex={-1}
                            aria-labelledby="registerModalLabel"
                            aria-modal="true"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body p-0">
                                        <h2
                                            className="h5 text-center font-bold register-header"
                                            id="registerModalLabel"
                                        >
                                            Registration Confirmation
                                        </h2>
                                        <p className="mb-4">
                                            Are you sure you want to{" "}
                                            <strong>
                                                Register a New Organization
                                            </strong>
                                            ? If you are part of an already{" "}
                                            <strong>
                                                Registered Organization
                                            </strong>
                                            , please connect with your
                                            organization&#39;s QCT leader before
                                            proceeding.
                                        </p>
                                        <div className="facility-confirm-btns d-flex justify-content-center flex-column flex-sm-row">
                                            <button
                                                className="btn btn-secondary btn-round"
                                                aria-label="Cancel Registering New organization"
                                                data-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round ml-sm-4 mt-4 mt-sm-0"
                                                data-dismiss="modal"
                                                onClick={setHospitalDefaults}
                                                aria-label="Proceed to Register a New organization"
                                            >
                                                Proceed
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </UserDashboardWrapper>
    );
};

export default UserDashboardPage;
