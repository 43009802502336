import CONSTANTS from "common/constants";
import Dropdown from "components/Dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSortOrganizations } from "components/SearchFilter/searchFilter.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { sleep } from "common/utils";
import { SortTableColumnDropdownWrapper } from "./styled";

interface Props {
    getHospitalsForAdminManage?: any;
    status?: any;
    pageNumber?: any;
    pageSize?: any;
    isActive?: any;
    categoryId?: any;
    searchKey?: any;
    setPendingHospitals?: any;
    setRenewingHospitals?: any;
    sortIndValue?: any;
    setCertifiedHospitals?: any;
    setRegisteredHospitals?: any;
    setDeactivatedHospitals?: any;
    getCorporationFacilities?: any;
    corporationId?: any;
    setFacilities?: any;
    id: string;
    stateCd?: any;
    country?: any;
    emsInd?: boolean;
    sortByLabel?: any;
    emsAward?: boolean;
    year?: any;
    award?: any;
    state?: any;
    search?: any;
    setCountDetail?: any;
    setTotalHospitals?: any;
    setAwardedHospitals?: any;
    setPagination?: any;
    setRoleValue?: any;
    setIsTableLoading?: any;
    setPageNumber?: any;
    selectedTab?: any;
    setOrderValue?: any;
    setOrderByValue?: any;
    emsLogs?: boolean;
    setLogsData?: any;
    measureCd?: any;
    awardCd?: any;
}

const SortTableColumnDropdown = (props: Props) => {
    const [message, setMessage] = useState("");
    const sortDropdown = [
        { label: "Default", value: CONSTANTS.DEFAULT },
        { label: "Name Ascending", value: CONSTANTS.ASCENDING },
        { label: "Name Descending", value: CONSTANTS.DESCENDING },
        { label: "Expiry Date Ascending", value: CONSTANTS.EXPIRY_ASCENDING },
        { label: "Expiry Date Descending", value: CONSTANTS.EXPIRY_DESCENDING },
        { label: "Descending", value: CONSTANTS.YEAR_DESCENDING },
        { label: "Ascending", value: CONSTANTS.YEAR_ASCENDING },
    ];
    const dispatch = useDispatch();
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const region = useSelector((state: any) => {
        return state.region;
    });

    const filteredSortDropdown = () => {
        if (props.emsInd) {
            return sortDropdown.filter(
                (option) =>
                    option.value === CONSTANTS.DEFAULT ||
                    option.value === CONSTANTS.ASCENDING ||
                    option.value === CONSTANTS.DESCENDING
            );
        }
        if (props.status !== CONSTANTS.CERTIFIED) {
            if (props.emsLogs) {
                return sortDropdown.filter(
                    (option) =>
                        option.value === CONSTANTS.YEAR_ASCENDING ||
                        option.value === CONSTANTS.YEAR_DESCENDING
                );
            }
            return sortDropdown.filter(
                (option) =>
                    option.value === CONSTANTS.DEFAULT ||
                    option.value === CONSTANTS.ASCENDING ||
                    option.value === CONSTANTS.DESCENDING
            );
        }
        if (props.status === CONSTANTS.CERTIFIED) {
            return sortDropdown.filter(
                (option) =>
                    option.value !== CONSTANTS.YEAR_ASCENDING &&
                    option.value !== CONSTANTS.YEAR_DESCENDING
            );
        }
        return sortDropdown;
    };

    const handleChangeSortOrganizations = async (value: any) => {
        if (searchFilter.sortOrganizations !== value) {
            if (
                props.status === CONSTANTS.CERTIFIED ||
                props.status === CONSTANTS.DEACTIVATED
            ) {
                dispatch(setSortOrganizations(CONSTANTS.ALL));
            }
            dispatch(setSortOrganizations(value));
            switch (value) {
                case CONSTANTS.ASCENDING:
                    setMessage(
                        `Table sorted in name ${CONSTANTS.ASCENDING} order.`
                    );
                    break;
                case CONSTANTS.DESCENDING:
                    setMessage(
                        `Table sorted in name ${CONSTANTS.DESCENDING} order.`
                    );
                    break;
                case CONSTANTS.DEFAULT:
                    setMessage(`Table sorted in ${CONSTANTS.DEFAULT} order.`);
                    break;
                case CONSTANTS.EXPIRY_ASCENDING:
                    setMessage(
                        `Table sorted in ${CONSTANTS.EXPIRY_DATE_ASCENDING} order.`
                    );
                    break;
                case CONSTANTS.EXPIRY_DESCENDING:
                    setMessage(
                        `Table sorted in ${CONSTANTS.EXPIRY_DATE_DESCENDING} order.`
                    );
                    break;
                case CONSTANTS.YEAR_ASCENDING:
                    setMessage(
                        `Table sorted in ${CONSTANTS.SERVICE_YEAR_ASCENDING} order.`
                    );
                    break;
                case CONSTANTS.YEAR_DESCENDING:
                    setMessage(
                        `Table sorted in ${CONSTANTS.SERVICE_YEAR_DESCENDING} order.`
                    );
                    break;
                default:
                    setMessage("");
                    break;
            }
            if (
                value === CONSTANTS.DESCENDING ||
                value === CONSTANTS.EXPIRY_DESCENDING ||
                value === CONSTANTS.YEAR_DESCENDING
            ) {
                props.sortIndValue(false);
            }
            if (
                value === CONSTANTS.ASCENDING ||
                value === CONSTANTS.EXPIRY_ASCENDING ||
                value === CONSTANTS.YEAR_ASCENDING
            ) {
                props.sortIndValue(true);
            }
            if (value === CONSTANTS.DEFAULT) {
                props.sortIndValue(undefined);
                dispatch(setSortOrganizations(CONSTANTS.ALL));
            }
            if (
                typeof props.getHospitalsForAdminManage === "function" &&
                !props.emsAward &&
                !props.emsLogs
            ) {
                props
                    .getHospitalsForAdminManage({
                        region:
                            props.categoryId ===
                            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                ? CONSTANTS.DOMESTIC
                                : region.region,
                        sortOrganizations:
                            value === CONSTANTS.EXPIRY_ASCENDING ||
                            value === CONSTANTS.EXPIRY_DESCENDING
                                ? value
                                : CONSTANTS.ALL,
                        status: props.status,
                        pageNumber: props.pageNumber,
                        isActive: props.isActive,
                        pageSize: props.pageSize,
                        categoryId: props.categoryId,
                        sortInd:
                            value === CONSTANTS.DEFAULT
                                ? undefined
                                : !!(
                                      value === CONSTANTS.ASCENDING ||
                                      value === CONSTANTS.EXPIRY_ASCENDING
                                  ),
                        searchKey: props.searchKey,
                        stateCd: props.stateCd,
                        country: props.country,
                        measureCd: props.measureCd,
                        awardCd: props.awardCd,
                    })
                    .then((res: any) => {
                        if (res.statusCode === 200) {
                            if (props.status === CONSTANTS.PENDING) {
                                props.setPendingHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                            if (props.status === CONSTANTS.CERTIFIED) {
                                props.setCertifiedHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                            if (props.status === CONSTANTS.RENEWING) {
                                props.setRenewingHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                            if (props.status === CONSTANTS.REGISTRED) {
                                props.setRegisteredHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                            if (props.status === CONSTANTS.DEACTIVATED) {
                                props.setDeactivatedHospitals(
                                    res.data.hospitalPrograms
                                );
                            }
                        }
                    });
            }
            if (
                typeof props.getHospitalsForAdminManage === "function" &&
                props.emsLogs
            ) {
                let orderSequence;

                if (value === CONSTANTS.YEAR_DESCENDING) {
                    orderSequence = CONSTANTS.DESC;
                } else {
                    orderSequence = CONSTANTS.ASC;
                }
                props.setIsTableLoading(true);
                const response: any = await props.getHospitalsForAdminManage(
                    props.pageNumber,
                    props.pageSize,
                    orderSequence
                );
                props.setLogsData(response?.data?.logResult?.rows);
                props.setPagination({
                    ...response.data._pagination,
                    pageNumber: props.pageNumber,
                });
                props.setIsTableLoading(false);
            }

            if (
                typeof props.getHospitalsForAdminManage === "function" &&
                props.emsAward
            ) {
                let orderValue;
                let orderByValue;

                switch (value) {
                    case CONSTANTS.ASCENDING:
                        orderValue = CONSTANTS.ASC;
                        orderByValue = CONSTANTS.ORDER_BY_NAME;
                        break;
                    case CONSTANTS.DESCENDING:
                        orderValue = CONSTANTS.DESC;
                        orderByValue = CONSTANTS.ORDER_BY_NAME;
                        break;
                    case CONSTANTS.DEFAULT:
                        orderValue = CONSTANTS.DESC;
                        orderByValue = CONSTANTS.DEFAULT_ORDER_BY; // Default sort
                        break;
                    default:
                        break;
                }
                props.setOrderByValue(orderByValue);
                props.setOrderValue(orderValue);
                props
                    .getHospitalsForAdminManage(
                        props.year,
                        CONSTANTS.ACTIVE_TAB_MAP[props.selectedTab],
                        1,
                        props.pageSize,
                        props.award,
                        props.state,
                        props.search,
                        orderValue,
                        orderByValue
                    )
                    .then((response: any) => {
                        if (response.success) {
                            if (
                                CONSTANTS.ACTIVE_TAB_MAP[props.selectedTab] ===
                                CONSTANTS.ACTIVE_TAB_MAP[1]
                            ) {
                                props.setTotalHospitals(
                                    response.data.logsData.rows.map(
                                        (hospital: any) => ({
                                            ...hospital,
                                            awardedBy:
                                                hospital.awardedBy.split(
                                                    " "
                                                )[0] !== "null" &&
                                                hospital.awardedBy.split(
                                                    " "
                                                )[1] !== "null"
                                                    ? hospital.awardedBy
                                                    : "N/A",
                                            award:
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.PENDING &&
                                                    hospital.award == "N/A") ||
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.DEACTIVATED &&
                                                    (hospital.award == "" ||
                                                        hospital.award ==
                                                            "null"))
                                                    ? "None"
                                                    : hospital.award,
                                        })
                                    )
                                );
                            }
                            if (
                                CONSTANTS.ACTIVE_TAB_MAP[props.selectedTab] ===
                                CONSTANTS.ACTIVE_TAB_MAP[2]
                            ) {
                                props.setPendingHospitals(
                                    response.data.logsData.rows.map(
                                        (hospital: any) => ({
                                            ...hospital,
                                            awardedBy:
                                                hospital.awardedBy.split(
                                                    " "
                                                )[0] !== "null" &&
                                                hospital.awardedBy.split(
                                                    " "
                                                )[1] !== "null"
                                                    ? hospital.awardedBy
                                                    : "N/A",
                                            award:
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.PENDING &&
                                                    hospital.award == "N/A") ||
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.DEACTIVATED &&
                                                    (hospital.award == "" ||
                                                        hospital.award ==
                                                            "null"))
                                                    ? "None"
                                                    : hospital.award,
                                        })
                                    )
                                );
                            }
                            if (
                                CONSTANTS.ACTIVE_TAB_MAP[props.selectedTab] ===
                                CONSTANTS.ACTIVE_TAB_MAP[3]
                            ) {
                                props.setRegisteredHospitals(
                                    response.data.logsData.rows.map(
                                        (hospital: any) => ({
                                            ...hospital,
                                            awardedBy:
                                                hospital.awardedBy.split(
                                                    " "
                                                )[0] !== "null" &&
                                                hospital.awardedBy.split(
                                                    " "
                                                )[1] !== "null"
                                                    ? hospital.awardedBy
                                                    : "N/A",
                                            award:
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.PENDING &&
                                                    hospital.award == "N/A") ||
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.DEACTIVATED &&
                                                    (hospital.award == "" ||
                                                        hospital.award ==
                                                            "null"))
                                                    ? "None"
                                                    : hospital.award,
                                        })
                                    )
                                );
                            }
                            if (
                                CONSTANTS.ACTIVE_TAB_MAP[props.selectedTab] ===
                                CONSTANTS.ACTIVE_TAB_MAP[4]
                            ) {
                                props.setAwardedHospitals(
                                    response.data.logsData.rows.map(
                                        (hospital: any) => ({
                                            ...hospital,
                                            awardedBy:
                                                hospital.awardedBy.split(
                                                    " "
                                                )[0] !== "null" &&
                                                hospital.awardedBy.split(
                                                    " "
                                                )[1] !== "null"
                                                    ? hospital.awardedBy
                                                    : "N/A",
                                            award:
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.PENDING &&
                                                    hospital.award == "N/A") ||
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.DEACTIVATED &&
                                                    (hospital.award == "" ||
                                                        hospital.award ==
                                                            "null"))
                                                    ? "None"
                                                    : hospital.award,
                                        })
                                    )
                                );
                            }
                            if (
                                CONSTANTS.ACTIVE_TAB_MAP[props.selectedTab] ===
                                CONSTANTS.ACTIVE_TAB_MAP[5]
                            ) {
                                props.setDeactivatedHospitals(
                                    response.data.logsData.rows.map(
                                        (hospital: any) => ({
                                            ...hospital,
                                            awardedBy:
                                                hospital.awardedBy.split(
                                                    " "
                                                )[0] !== "null" &&
                                                hospital.awardedBy.split(
                                                    " "
                                                )[1] !== "null"
                                                    ? hospital.awardedBy
                                                    : "N/A",
                                            award:
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.PENDING &&
                                                    hospital.award == "N/A") ||
                                                (hospital.organizationStatus ==
                                                    CONSTANTS.DEACTIVATED &&
                                                    (hospital.award == "" ||
                                                        hospital.award ==
                                                            "null"))
                                                    ? "None"
                                                    : hospital.award,
                                        })
                                    )
                                );
                            }
                            props.setCountDetail(
                                response.data.countDataResult[0]
                            );
                            props.setPagination({
                                ...response.data._pagination,
                                setPageNumber: props.setPageNumber,
                            });
                            props.setRoleValue("alert");
                            props.setIsTableLoading(false);
                        }
                    });
            }
            if (typeof props.getCorporationFacilities === "function") {
                props
                    .getCorporationFacilities({
                        region: region.region,
                        corporationId: props.corporationId,
                        pageNumber: props.pageNumber,
                        pageSize: props.pageSize,
                        status: props.status,
                        sortOrganizations:
                            value === CONSTANTS.EXPIRY_ASCENDING ||
                            value === CONSTANTS.EXPIRY_DESCENDING
                                ? value
                                : CONSTANTS.ALL,
                        categoryId: props.categoryId,
                        sortInd:
                            value === CONSTANTS.DEFAULT
                                ? undefined
                                : !!(
                                      value === CONSTANTS.ASCENDING ||
                                      value === CONSTANTS.EXPIRY_ASCENDING
                                  ),
                        searchKey: props.searchKey,
                        stateCd: props.stateCd,
                        country: props.country,
                    })
                    .then((res: any) => {
                        if (res.statusCode === 200) {
                            if (props.status === CONSTANTS.CERTIFIED) {
                                props.setFacilities(
                                    res.data.certifiedFacilityDetails
                                );
                            } else if (
                                props.status === CONSTANTS.DEACTIVATED ||
                                props.status === CONSTANTS.REGISTRED ||
                                props.status === CONSTANTS.PENDING ||
                                props.status === CONSTANTS.RENEWING
                            ) {
                                props.setFacilities(res.data.facilityDetails);
                            }
                        }
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                        localStorage.setItem("resetFilter", "false");
                    });
                localStorage.setItem("resetFilter", "true");
            }
        }
    };

    useEffect(() => {
        if (message !== "") {
            const timer: any = sleep(6000).then(() => {
                setMessage("");
            });
            return () => clearTimeout(timer);
        }
    }, [message]);
    return (
        <SortTableColumnDropdownWrapper>
            <label htmlFor={props.id} id={`${props.id}-label`}>
                {`Sort by ${props?.sortByLabel || ""}`} :
            </label>
            <Dropdown
                id={props.id}
                items={filteredSortDropdown()}
                selectedValue={searchFilter.sortOrganizations}
                callParentOnSelect={(value: any) =>
                    handleChangeSortOrganizations(value)
                }
            />
            <div className="sr-only" role="alert" aria-atomic="true">
                {message}
            </div>
        </SortTableColumnDropdownWrapper>
    );
};
export default SortTableColumnDropdown;
