import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ILoader {
    isPageLoading: boolean;
    message?: string;
    isloadingWithMessage?: boolean;
}

export const initialState: ILoader = {
    isPageLoading: false,
    message: "",
    isloadingWithMessage: false,
};

const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setPageLoadingStatus: (state, action: PayloadAction<ILoader>) => {
            state.isPageLoading = action.payload.isPageLoading;
            state.isloadingWithMessage =
                action.payload.isloadingWithMessage ?? false;
            state.message = action.payload.message;
        },
    },
});
const { actions, reducer } = loaderSlice;

export const { setPageLoadingStatus } = actions;
export default reducer;
