import React from "react";
import { Switch } from "react-router-dom";
import Home from "pages/Home/HomePage";
import Programs from "pages/Programs/ProgramsPage";
import Dashboard from "pages/Dashboard/DashboardPage";
import HospitalInfo from "pages/HospitalInfo/HospitalInfoPage";
import HospitalProgram from "pages/HospitalProgram/HospitalProgramPage";
import HospitalProgramAgreement from "pages/HospitalProgramAgreement/HospitalProgramAgreementPage";
import LocationSelection from "pages/Verify/UserVerificationPage";
import SuccessPayment from "pages/SuccessPayment/SuccessPaymentPage";
import Measures from "pages/Measures/MeasuresPage";
import Progress from "pages/Progress/ProgressPage";
import AdminDashboard from "pages/Admin/AdminDashboard/AdminDashboardPage";
import OrganizationManagement from "pages/EMSAdmin/OrganizationManagement/OrganizationManagement";
import HospitalManagementPage from "pages/Admin/HospitalManagement/HospitalManagementPage";
import ApproveHospital from "pages/Admin/ApproveHospital/ApproveHospital";
import RegisteredHospitalDetails from "pages/Admin/RegisteredHospitalDetails/RegisteredHospitalDetails";
import RegisteredEMSHospitalDetails from "pages/EMSAdmin/RegistredEMSHospitalDetails/RegistredEMSHospitalDetails";
import DocumentSubmissionPage from "pages/DocumentSubmission/DocumentSumbissionPage";
import CertificationPage from "pages/Certification/CertificationPage";
import PaymentSummaryPage from "pages/Payment/PaymentSummaryPage";
import PaymentStatusPage from "pages/Payment/PaymentStatusPage";
import HospitalProfile from "pages/Settings/HospitalPrograms/HospitalProgramsPage";
import RegistryManagement from "pages/Registry/RegistryManagement/RegistryManagementPage";
import CorporationManagement from "pages/Corporation/CorporationManagement/CorporationManagementPage";
import RegistryDetails from "pages/Registry/RegistryDetails/RegistryDetailsPage";
import InactiveUser from "components/InactiveUser/InactiveUserPage";
import FacilitiesRegistered from "pages/Registry/FacilitiesRegistered/FacilitiesRegisteredPage";
import RegistryProfilePage from "pages/Registry/RegistrySettings/RegistryProfilePage";
import RegistryDashboardPage from "pages/Registry/RegistryDashboard/RegistryDashboardPage";
import RegistryMeasures from "pages/Registry/RegistryMeasures/RegistryMeasuresPage";
import RegistryHome from "pages/Registry/RegistryHome/RegistryHomePage";
import CorporationHome from "pages/Corporation/CorporationHome/CorporationHomePage";
import UserManagementPage from "pages/UserManagement/UserManagementPage";
import CorporationDetails from "pages/Corporation/CorporationDetails/CorporationDetailsPage";
import CorporationDashboard from "pages/Corporation/CorporationDashboard/CorporationDashboardPage";
import CorporationFacilitiesRegistered from "pages/Corporation/FacilitiesRegistered/FacilitiesRegisteredPage";
import CorporationFacilitiesCertified from "pages/Corporation/FacilitiesCertified/FacilitiesCertifiedPage";
import FacilitiesDetails from "pages/Corporation/FacilitiesDetails/FacilitiesDetails";
import FacilitiesMeasures from "pages/Corporation/FacilitiesDetails/FacilitiesMeasures";
import FacilitiesDocuments from "pages/Corporation/FacilitiesDetails/FacilitiesDocuments";
import FacilitiesCertificate from "pages/Corporation/FacilitiesDetails/FacilitiesCertificate";
import UserDashboardPage from "pages/UserDashboard/UserDashboardPage";
import CorporationPendingFacilities from "pages/Corporation/PendingFacilities/PendingFacilitiesPage";
import CorporationDeactivatedFacilities from "pages/Corporation/DeactivatedFacilities/DeactivatedFacilitiesPage";
import CorporationProfile from "pages/Corporation/CorporationSettings/CorporationProfilePage";
import FacilityDetailsSettings from "pages/Settings/FacilityDetails/FacilityDetailsPage";
import AgreementDetailsSettings from "pages/Settings/AgreementDetails/AgreementDetailsPage";
import CorporationBulkDocumentSubmission from "pages/Corporation/CorporationBulkDocuments/CorporationBulkDocumentSubmissionPage";
import SettingsBillingDetails from "pages/Settings/BillingDetails/BillingDetailsPage";
import ApproveEmsProgramHospital from "pages/EMSAdmin/ApproveEmsProgramHospital/ApproveEmsprogramHospital";
import { ArchivedDocuments } from "pages/Admin/ArchivedDocuments/ArchivedDocuments";
import { CorporationRenewingFacilities } from "pages/Corporation/RenewingFacilities/RenewingFacilitiesPage";
import { ArchivedCertificate } from "pages/Admin/ArchivedCertificate/ArchivedCertificate";
import ArchivedView from "pages/ArchivedView";
import OrganizationLogs from "pages/EMSAdmin/OrganizationLogs/OraganizationLogs";
import OrganizationLogsList from "pages/EMSAdmin/OrganizationLogsList/OrgaganizationLogsList";
import RegisteredEMSEUPDetails from "pages/RegistredEMSEUPDetails/RegistredEMSEUPdetails";
import Route from "./Route";

export default function Routes() {
    return (
        <Switch>
            <Route
                path="/location"
                isPrivate
                exact
                hospitalRoute
                component={LocationSelection as any}
                title="Welcome to Quality Certification Tool | American Heart Association"
            />
            <Route
                path="/organization/inactive"
                exact
                isPrivate
                hospitalRoute
                component={InactiveUser as any}
                title="Inactive | American Heart Association"
            />
            <Route
                path="/progress"
                isPrivate
                exact
                hospitalRoute
                component={Progress as any}
                title="Welcome to Quality Certification Tool | American Heart Association"
            />
            <Route
                path="/userDashboard"
                exact
                isPrivate
                hospitalRoute
                component={UserDashboardPage as any}
                title="User Dashboard | American Heart Association"
            />
            <Route
                path="/organization/register/:hospitalId"
                exact
                isPrivate
                hospitalRoute
                component={HospitalInfo as any}
                title="Registration | American Heart Association"
            />
            <Route
                path="/programs/:countryCode"
                isPrivate
                hospitalRoute
                exact
                component={Programs as any}
                title="Programs | American Heart Association"
            />
            <Route
                path="/programs"
                isPrivate
                hospitalRoute
                exact
                component={Programs as any}
                title="Programs | American Heart Association"
            />
            <Route
                path="/program/register/:programId"
                exact
                isPrivate
                hospitalRoute
                component={HospitalProgram as any}
                title="Program Details | American Heart Association"
            />
            <Route
                path="/programs/:programId/category/:categoryId/agreement"
                exact
                isPrivate
                hospitalRoute
                component={HospitalProgramAgreement as any}
                title="Agreement | American Heart Association"
            />
            <Route
                path="/paymentSummary/program/:programId/category/:categoryId"
                exact
                isPrivate
                hospitalRoute
                component={PaymentSummaryPage as any}
                title="Payment Summary | American Heart Association"
            />
            <Route
                path="/paymentStatus"
                exact
                isPrivate
                hospitalRoute
                component={PaymentStatusPage as any}
                title="Payment Status | American Heart Association"
            />
            <Route
                path="/successpayment"
                exact
                isPrivate
                hospitalRoute
                component={SuccessPayment as any}
                title="Thank you | American Heart Association"
            />
            <Route
                path="/dashboard"
                exact
                isPrivate
                hospitalRoute
                protectedRoute
                component={Dashboard as any}
                title="Dashboard | American Heart Association"
            />
            <Route
                path="/documents/organization/:hospitalId/programs/:programId/categories/:categoryId"
                exact
                isPrivate
                hospitalRoute
                component={DocumentSubmissionPage as any}
                title="Document Submission | American Heart Association"
            />
            <Route
                path="/organizationDetails/:hospitalId/programs/:programId/categories/:categoryId"
                protectedRoute
                exact
                isPrivate
                hospitalRoute
                component={RegisteredEMSEUPDetails as any}
                title="Organization Details | American Heart Association"
            />
            <Route
                path="/measures/organization/:hospitalId/programs/:programId/categories/:categoryId"
                protectedRoute
                exact
                isPrivate
                hospitalRoute
                component={Measures as any}
                title="Measures | American Heart Association"
            />
            <Route
                path="/certificate/organization/:hospitalId/programs/:programId/categories/:categoryId"
                exact
                isPrivate
                hospitalRoute
                protectedRoute
                component={CertificationPage as any}
                title="Certificate | American Heart Association"
            />
            <Route
                path="/settings/organizationdetails"
                exact
                isPrivate
                hospitalRoute
                protectedRoute
                component={FacilityDetailsSettings as any}
                title="Organization Details | American Heart Association"
            />
            <Route
                path="/archived-view/hospital/:hospitalId/programs/:programId/categories/:categoryId"
                exact
                isPrivate
                hospitalRoute
                protectedRoute
                component={ArchivedView as any}
                title="Archived Details | American Heart Association"
            />
            <Route
                path="/settings/program"
                exact
                isPrivate
                hospitalRoute
                protectedRoute
                component={HospitalProfile as any}
                title="Programs | American Heart Association"
            />
            <Route
                path="/settings/agreementdetails/organization/:hospitalId/programs/:programId"
                exact
                isPrivate
                hospitalRoute
                protectedRoute
                component={AgreementDetailsSettings as any}
                title="Agreement Details | American Heart Association"
            />
            <Route
                path="/settings/billingdetails/organization/:hospitalId/categories/:categoryId"
                exact
                isPrivate
                hospitalRoute
                protectedRoute
                component={SettingsBillingDetails as any}
                title="Billing Details | American Heart Association"
            />
            <Route
                path="/user-management"
                exact
                isPrivate
                hospitalRoute
                component={UserManagementPage as any}
                title="User Management | American Heart Association"
            />
            {/* Admin Routes - START */}
            <Route
                path="/admin/dashboard"
                exact
                isPrivate
                adminRoute
                component={AdminDashboard as any}
                title="Dashboard | American Heart Association"
                checkMaintenance
            />
            <Route
                path="/admin/organization-management"
                exact
                isPrivate
                adminRoute
                component={HospitalManagementPage as any}
                title="Organization Management | American Heart Association"
            />
            <Route
                path="/admin/organization-management/approve-organization/:hospitalId/program/:programId/category/:categoryId"
                exact
                isPrivate
                adminRoute
                component={ApproveHospital as any}
                title="Registration Details | American Heart Association"
            />
            <Route
                path="/admin/organization-management/organization-details/:hospitalId/program/:programId/category/:categoryId"
                exact
                isPrivate
                adminRoute
                component={RegisteredHospitalDetails as any}
                title="Organization Management | American Heart Association"
            />
            <Route
                path="/registry/registry-management"
                exact
                isPrivate
                adminRoute
                component={RegistryManagement as any}
                title="Registry Management | American Heart Association"
            />
            <Route
                path="/corporation/organization-details/archived-view/:hospitalId/program/:programId/category/:categoryId"
                exact
                isPrivate
                corporationRoute
                component={ArchivedView as any}
                title="Archived Details | American Heart Association"
            />
            <Route
                path="/corporation/corporation-management"
                exact
                isPrivate
                adminRoute
                component={CorporationManagement as any}
                title="Corporation Management | American Heart Association"
            />
            <Route
                path="/corporation/corporation-management/corporation-details/add-corporation/:corporationId"
                exact
                isPrivate
                adminRoute
                component={CorporationDetails as any}
                title="Add New Corporation | American Heart Association"
            />
            <Route
                path="/corporation/corporation-management/corporation-details/:corporationId"
                exact
                isPrivate
                adminRoute
                component={CorporationDetails as any}
                title="Corporation Details | American Heart Association"
            />
            <Route
                path="/registry/registry-management/registry-details/add-registry/:registryId"
                exact
                isPrivate
                adminRoute
                component={RegistryDetails as any}
                title="Add New Registry | American Heart Association"
            />
            <Route
                path="/registry/registry-management/registry-details/:registryId"
                exact
                isPrivate
                adminRoute
                component={RegistryDetails as any}
                title="Registry Details | American Heart Association"
            />
            <Route
                path="/admin/organization-management/archived-view/:hospitalId/program/:programId/category/:categoryId"
                exact
                isPrivate
                adminRoute
                component={ArchivedView as any}
                title="Archived Details | American Heart Association"
            />
            <Route
                path="/admin/organization-management/archived-certificate/:hospitalId/program/:programId/category/:categoryId/date/:archivedDate"
                exact
                isPrivate
                adminRoute
                component={ArchivedCertificate as any}
                title="Archived Certificate Details | American Heart Association"
            />
            {/* Admin Routes - END */}

            {/* EMS Admin Routes - Start */}
            <Route
                path="/emsadmin/organization-management"
                exact
                isPrivate
                emsAdminRoute
                component={OrganizationManagement as any}
                title="Organization Management | American Heart Association"
            />
            <Route
                path="/emsadmin/organization-management/organization-details/:hospitalId/program/:programId/category/:categoryId"
                exact
                isPrivate
                emsAdminRoute
                component={RegisteredEMSHospitalDetails as any}
                title="Organization Management | American Heart Association"
            />
            <Route
                path="/emsadmin/organization-management/approve-organization/:hospitalId/program/:programId/category/:categoryId"
                exact
                isPrivate
                emsAdminRoute
                component={ApproveEmsProgramHospital as any}
                title="Organization Management | American Heart Association"
            />
            <Route
                path="/emsadmin/organization-logs"
                exact
                isPrivate
                emsAdminRoute
                component={OrganizationLogs as any}
                title="Organization Logs | American Heart Association"
            />
            <Route
                path="/emsadmin/organization-logs/logs-list/:year"
                exact
                isPrivate
                emsAdminRoute
                component={OrganizationLogsList as any}
                title="Organization Logs list | American Heart Association"
            />
            {/* EMS Admin Routes - END */}

            {/* Registry Routes - STARTS */}
            <Route
                path="/registry"
                exact
                registryRoute
                component={RegistryHome as any}
                hideHeader
                title="Registry Home | American Heart Association"
                checkMaintenance
            />

            <Route
                path="/registry/inactive"
                exact
                isPrivate
                registryRoute
                component={InactiveUser as any}
                title="Inactive | American Heart Association"
            />
            <Route
                path="/registry/organization-management"
                exact
                isPrivate
                registryRoute
                component={FacilitiesRegistered as any}
                title="Registry Organizations Management | American Heart Association"
            />
            <Route
                path="/registry/measures/organization/:hospitalId"
                exact
                isPrivate
                registryRoute
                component={RegistryMeasures as any}
                title="Registry Measures | American Heart Association"
            />
            <Route
                path="/registry/settings/profile"
                exact
                isPrivate
                registryRoute
                component={RegistryProfilePage as any}
                title="Registry Profile | American Heart Association"
            />
            <Route
                path="/registry/dashboard"
                exact
                isPrivate
                registryRoute
                component={RegistryDashboardPage as any}
                title="Registry Dashboard | American Heart Association"
            />
            <Route
                path="/registry/user-management"
                exact
                isPrivate
                registryRoute
                component={UserManagementPage as any}
                title="Registry User Management | American Heart Association"
            />
            {/* Registry Routes - ENDS */}

            {/* Corporation Routes - STARTS */}
            <Route
                path="/corporation"
                exact
                corporationRoute
                component={CorporationHome as any}
                hideHeader
                title="Corporation Home | American Heart Association"
                checkMaintenance
            />

            <Route
                path="/corporation/inactive"
                exact
                isPrivate
                corporationRoute
                component={InactiveUser as any}
                title="Inactive | American Heart Association"
            />
            <Route
                path="/corporation/dashboard"
                exact
                isPrivate
                corporationRoute
                component={CorporationDashboard as any}
                title="Corporation Dashboard | American Heart Association"
            />
            <Route
                path="/corporation/pending-approval"
                exact
                isPrivate
                corporationRoute
                component={CorporationPendingFacilities as any}
                title="Corporation Pending Organizations | American Heart Association"
            />
            <Route
                path="/corporation/registered-organization"
                exact
                isPrivate
                corporationRoute
                component={CorporationFacilitiesRegistered as any}
                title="Corporation Registered Organizations | American Heart Association"
            />
            <Route
                path="/corporation/renewed-organizations"
                exact
                isPrivate
                corporationRoute
                component={CorporationRenewingFacilities as any}
                title="Corporation Renewed Organizations | American Heart Association"
            />
            <Route
                path="/corporation/certified-organization"
                exact
                isPrivate
                corporationRoute
                component={CorporationFacilitiesCertified as any}
                title="Corporation Organizations Certified | American Heart Association"
            />
            <Route
                path="/corporation/deactivated-organization"
                exact
                isPrivate
                corporationRoute
                component={CorporationDeactivatedFacilities as any}
                title="Corporation Organizations Certified | American Heart Association"
            />
            <Route
                path="/corporation/organization-details/:hospitalId/program/:programId/category/:categoryId"
                exact
                isPrivate
                corporationRoute
                component={FacilitiesDetails as any}
                title="Corporation Organization Details | American Heart Association"
            />
            <Route
                path="/corporation/measures/hospital/:hospitalId/programs/:programId/categories/:categoryId"
                exact
                isPrivate
                corporationRoute
                component={FacilitiesMeasures as any}
                title="Corporation Organization Measures | American Heart Association"
            />
            <Route
                path="/corporation/documents/hospital/:hospitalId/programs/:programId/categories/:categoryId"
                exact
                isPrivate
                corporationRoute
                component={FacilitiesDocuments as any}
                title="Corporation Organization Documents | American Heart Association"
            />
            <Route
                path="/corporation/certificate/hospital/:hospitalId/programs/:programId/categories/:categoryId"
                exact
                isPrivate
                corporationRoute
                component={FacilitiesCertificate as any}
                title="Corporation Organization Cerification | American Heart Association"
            />
            <Route
                path="/corporation/settings/profile"
                exact
                isPrivate
                corporationRoute
                component={CorporationProfile as any}
                title="Corporation Profile | American Heart Association"
            />
            <Route
                path="/corporation/user-management"
                exact
                isPrivate
                corporationRoute
                component={UserManagementPage as any}
                title="Corporation User Management | American Heart Association"
            />
            <Route
                path="/corporation/bulk-document-submission"
                exact
                isPrivate
                corporationRoute
                component={CorporationBulkDocumentSubmission as any}
                title="Corporation Bulk Document Submission | American Heart Association"
            />
            {/* Corporation Routes - ENDS */}

            {/* default route */}
            <Route
                path="/*"
                exact
                component={Home as any}
                hideHeader
                checkMaintenance
            />
        </Switch>
    );
}
