import React, { useEffect, useRef, useState } from "react";
import {
    getAwardsAndBrandingMaterials,
    getAssetDetails,
    saveCertificate,
    getHospitalProgramCertificate,
} from "api/certificateAPI";
import { useLocation, useParams } from "react-router-dom";
import store from "app/store";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import html2canvas from "html2canvas";
import HospitalBanner from "pages/Admin/HospitalBanner/HospitalBanner";
import CONSTANTS from "common/constants";
import Dropdown from "components/Dropdown";
import { calculatNumOfDaysBetweenDates, sleep } from "common/utils";
import { CertificationWrapper } from "./styled";
import { logger } from "../../../utils/logger.utils";
import "styles/model-red.scss";

interface Props {
    hospital: any;
    programName: any;
}

export const HospitalCertificationTab = (props: Props) => {
    const [awards, setAwards] = useState<any>([]);
    const [awardsDropdown, setAwardsDropdown] = useState<any>([]);
    const [certificateYear, setCertificateYear] = useState<any>([
        { label: new Date().getFullYear(), value: new Date().getFullYear() },
        {
            label: new Date().getFullYear() - 1,
            value: new Date().getFullYear() - 1,
        },
    ]);
    const [selectedYear, setSelectedYear] = useState<any>(
        new Date().getFullYear()
    );
    const [isCertificateYearExists, setisCertificateYearExists] =
        useState<boolean>(false);
    const [certificateInfo, setCertificateInfo] = useState<any>({});
    const [certificates, setCertificates] = useState<any>({});
    const [brandingMaterials, setBrandingMaterials] = useState<any>([]);
    const [awardValidity, setAwardValidity] = useState<any>(0);
    const [generateCertificateButtonLabel, setGenerateCertificateButtonLabel] =
        useState<any>("Generate Certificate");
    const [showBrandingMaterials, setShowBrandingMaterials] =
        useState<boolean>(true);
    const [selectedAward, setSelectedAward] = useState<any>({});
    const { programId, categoryId }: any = useParams();
    const previewCertificateRef = useRef<HTMLButtonElement>(null);
    const expiryDateRef = useRef<HTMLInputElement>(null);
    const grantedDateRef = useRef<any>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const [expierStatus, setExpierStatus] = useState<boolean>(false);
    const [expireMsgToolTip, setExpireMsgToolTip] = useState<any>("");
    const location = useLocation();

    useEffect(() => {
        const expireOn: any = localStorage.getItem("certificateExpireOn");
        if (
            expireOn &&
            location?.state?.adminfacilityMangemnetTab === CONSTANTS.CERTIFIED
        ) {
            const days = calculatNumOfDaysBetweenDates(new Date(), expireOn);
            if (days >= 1 && days <= 50) {
                setExpierStatus(true);
                setExpireMsgToolTip(
                    "This organization's certificate is about to expire. Please renew it to Update Certificate."
                );
            } else if (days === 0) {
                setExpierStatus(true);
                setExpireMsgToolTip(
                    "This organization's certificate expired. Please renew it to Update Certificate."
                );
            } else {
                setExpierStatus(false);
            }
        }
    }, [certificateInfo.expiryOn]);

    useEffect(() => {
        if (!certificateInfo.grantedOn) return;
        let calculatedExpiryOn: any = null;
        let formatedExpiry: any = null;
        const grantedOnDate: any = certificateInfo.grantedOn
            ? new Date(
                  `${certificateInfo.grantedOn.replaceAll("-", "/")} 00:00`
              ) // to create date object in local time
            : "";
        if (grantedOnDate && awardValidity) {
            calculatedExpiryOn = calculateExpiry(grantedOnDate, awardValidity);
            calculatedExpiryOn.setDate(calculatedExpiryOn.getDate() - 1);
            formatedExpiry = calculatedExpiryOn
                ? `${calculatedExpiryOn.getFullYear()}-${`0${
                      calculatedExpiryOn.getMonth() + 1
                  }`.slice(-2)}-${`0${calculatedExpiryOn.getDate()}`.slice(-2)}`
                : "";
        } else {
            formatedExpiry = certificateInfo.expiryOn;
        }
        setCertificateInfo({
            ...certificateInfo,
            expiryOn: formatedExpiry,
        });
        if (expiryDateRef.current) {
            expiryDateRef.current.value = formatedExpiry;
        }
    }, [certificateInfo.grantedOn]);

    useEffect(() => {
        if (props.hospital && Object.keys(props.hospital).length > 0) {
            store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
            getHospitalProgramCertificate(
                props.hospital.hospitalId,
                programId,
                categoryId
            )
                .then((certificateObj: any) => {
                    if (
                        certificateObj.data.programCertficate &&
                        certificateObj.data.programCertficate
                            .programCertificateId
                    ) {
                        setGenerateCertificateButtonLabel("Update Certificate");
                        getAwardsAndBrandingMaterials(
                            programId,
                            categoryId,
                            props.hospital.countryId
                        ).then((result) => {
                            if (
                                certificateObj.data.programCertficate
                                    .certificateYear
                            ) {
                                getCertificateYearDropdown(
                                    certificateObj.data.programCertficate
                                        .certificateYear
                                );
                                setSelectedYear(
                                    certificateObj.data.programCertficate
                                        .certificateYear
                                );
                            } else {
                                getCertificateYearDropdown(
                                    parseInt(
                                        certificateObj.data.programCertficate.programCertificateGrantedOn.slice(
                                            0,
                                            4
                                        )
                                    )
                                );
                                setSelectedYear(
                                    parseInt(
                                        certificateObj.data.programCertficate.programCertificateGrantedOn.slice(
                                            0,
                                            4
                                        )
                                    )
                                );
                            }

                            setCertificates(result.data.certificates);
                            if (result.data.isCertificateYearExists) {
                                setisCertificateYearExists(
                                    result.data.isCertificateYearExists
                                );
                            }
                            const awardsArray = new Array(0);
                            result.data.certificates.map((award: any) => {
                                awardsArray.push({
                                    awardId: award.certificateAwardId,
                                    awardName: award.certificateCategoryName,
                                    validity: award.certificateExpiry,
                                });
                            });
                            setAwards(awardsArray);
                            setCertificateInfo({
                                facilityName:
                                    certificateObj.data.programCertficate
                                        .programCertificateFacilityName ||
                                    props.hospital.facilityName,
                                award: certificateObj.data.programCertficate
                                    .programCertificateName,
                                city: certificateObj.data.programCertficate
                                    .programCertificateCity,
                                state: certificateObj.data.programCertficate
                                    .programCertificateState,
                                countryName:
                                    certificateObj.data.programCertficate
                                        .programCertificateCountry,
                                grantedOn:
                                    certificateObj.data.programCertficate
                                        .programCertificateGrantedOn,
                                expiryOn:
                                    certificateObj.data.programCertficate
                                        .programCertificateExpiryDate,
                                programCertificateId:
                                    certificateObj.data.programCertficate
                                        .programCertificateId,
                            });
                            loadBrandingMaterials(
                                result.data.certificates,
                                false,
                                certificateObj.data.programCertficate
                            );
                        });
                        store.dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    } else {
                        getAwardsAndBrandingMaterials(
                            programId,
                            categoryId,
                            props.hospital.countryId
                        )
                            .then((result) => {
                                if (result.data.isCertificateYearExists) {
                                    setisCertificateYearExists(
                                        result.data.isCertificateYearExists
                                    );
                                }
                                setCertificates(result.data.certificates);
                                const awardsData = new Array(0);
                                result.data.certificates.map((award: any) => {
                                    awardsData.push({
                                        awardId: award.certificateAwardId,
                                        awardName:
                                            award.certificateCategoryName,
                                        validity: award.certificateExpiry,
                                    });
                                });
                                setAwards(awardsData);
                                setCertificateInfo({
                                    award:
                                        awardsData && awardsData.length === 1
                                            ? awardsData[0].awardName
                                            : awardsData &&
                                              awardsData.length > 1
                                            ? "Select"
                                            : "",
                                    city: props.hospital.city,
                                    state: props.hospital.state,
                                    countryName: props.hospital.countryName,
                                    facilityName: props.hospital.facilityName,
                                    grantedOn: "",
                                    expiryOn: "",
                                });
                                loadBrandingMaterials(
                                    result.data.certificates,
                                    false
                                );
                                store.dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            })
                            .catch((error) => {
                                logger(error);
                                store.dispatch(showToast(error));
                                store.dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            });
                    }
                })
                .catch((error) => {
                    logger(error);
                });
        }
    }, [props.hospital]);

    const getCertificateYearDropdown = (yearValue: any) => {
        const years = [];
        const yearsArray: any = [];
        const currentYear = new Date().getFullYear();
        if (yearValue > currentYear) {
            for (let i = yearValue; i >= currentYear - 1; i--) {
                years.push(i);
            }
        } else {
            if (yearValue <= 2019) {
                yearValue = 2020;
            }
            for (let i = currentYear; i >= yearValue - 1; i--) {
                years.push(i);
            }
        }
        if (years && years.length > 0) {
            years.forEach((year: any) => {
                yearsArray.push({
                    label: year,
                    value: year,
                });
            });
            setCertificateYear(yearsArray);
        }
    };

    const modifyCertificateTemplate = (template: any) => {
        let formattedExpDate = "";

        if (!certificateInfo.award.includes("Performance")) {
            if (template.getElementsByClassName("cer-title")[0]) {
                if (template.getElementsByClassName("cer-title")[0].innerHTML) {
                    template.getElementsByClassName("cer-title")[0].innerHTML =
                        certificateInfo.award;
                } else {
                    template.getElementsByClassName("cer-title")[0].innerHTML =
                        null;
                }
            }

            template.getElementById(
                "facilityname"
            ).innerHTML = `${certificateInfo.facilityName
                ?.slice(0, CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT)
                ?.split(" ")
                .join(" ")}`;
            if (props.hospital.countryCode == CONSTANTS.DOMESTIC_COUNTRY_CODE) {
                template.getElementById(
                    "citystate"
                ).innerHTML = `${certificateInfo.city
                    ?.split(" ")
                    .join("&nbsp;")}, ${certificateInfo.state
                    ?.split(" ")
                    .join("&nbsp;")}`;
            } else {
                template.getElementById(
                    "citystate"
                ).innerHTML = `${certificateInfo.city
                    ?.split(" ")
                    .join("&nbsp;")}, ${certificateInfo.countryName
                    ?.split(" ")
                    .join("&nbsp;")}`;
            }

            const grantedDate = certificateInfo.grantedOn
                ? new Date(
                      `${certificateInfo.grantedOn.replaceAll("-", "/")} 00:00`
                  )
                : null;
            const formattedGrantedDate = grantedDate
                ? `${grantedDate.toLocaleString("default", {
                      month: "long",
                  })}&nbsp;${grantedDate.getDate()},&nbsp;${grantedDate.getFullYear()}`
                : "";
            template.getElementById("granteddate").innerHTML =
                formattedGrantedDate;

            const expirydDate = certificateInfo.expiryOn
                ? new Date(
                      `${certificateInfo.expiryOn.replaceAll("-", "/")} 00:00`
                  )
                : null;

            if (expirydDate) {
                formattedExpDate = `${expirydDate.toLocaleString("default", {
                    month: "long",
                })}&nbsp;${expirydDate.getDate()},&nbsp;${expirydDate.getFullYear()}`;
            }

            if (
                template.getElementById("expireddate") &&
                template.getElementById("expireddate").innerHTML
            ) {
                template.getElementById("expireddate").innerHTML =
                    formattedExpDate;
            }

            if (template.getElementById("hfAwardedYear")) {
                if (isCertificateYearExists) {
                    template.getElementById("hfAwardedYear").innerHTML =
                        selectedYear;
                } else {
                    template.getElementById("hfAwardedYear").innerHTML =
                        new Date().getFullYear();
                }
            }
        } else {
            // this is for international heart failure
            template.getElementsByClassName(
                "hf-hospital"
            )[0].childNodes[1].innerHTML = props.hospital.facilityName
                ?.split(" ")
                .join("&nbsp;");
            template.getElementsByClassName(
                "hf-hospital"
            )[0].childNodes[3].innerHTML = `${certificateInfo.city
                ?.split(" ")
                .join("&nbsp;")},&nbsp;${certificateInfo.state
                ?.split(" ")
                .join("&nbsp;")}`;
        }
    };

    const setPreviewModal = async () => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        previewCertificateRef &&
            previewCertificateRef.current &&
            previewCertificateRef.current.click();
        await createCanvas();
        store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
    };

    const createCanvas = async () => {
        return new Promise((resolve, reject) => {
            const iframe: any = document.createElement("iframe");

            iframe.setAttribute("height", "0");
            iframe.setAttribute("width", "0");
            iframe.className = "hiddenFrame";
            const imagePreviewContainer: any = document.getElementById(
                "imagePreviewContainer"
            );
            imagePreviewContainer.innerHTML = "";

            getAssetDetails(
                selectedAward.certificateTemplateReference,
                true
            ).then((result) => {
                let templateHtml: any;
                fetch(`${result.data.assets[0].presignedUrl}`)
                    .then((response) => {
                        return response.text();
                    })
                    .then((template: any) => {
                        templateHtml = template;
                        document.body.appendChild(iframe);

                        iframe.onload = () => {
                            const hiddenFrame: any =
                                document.getElementsByClassName(
                                    "hiddenFrame"
                                )[0];
                            const hiddenFrameDoc =
                                hiddenFrame.contentWindow.document;

                            modifyCertificateTemplate(hiddenFrameDoc);

                            html2canvas(hiddenFrameDoc.body, {
                                scale: 2,
                                useCORS: true,
                                allowTaint:
                                    true /* logging: true, foreignObjectRendering: true */,
                            }).then((canvas) => {
                                if (hiddenFrame && hiddenFrame.parentNode) {
                                    hiddenFrame.parentNode.removeChild(
                                        hiddenFrame
                                    );
                                }

                                const certificateDataUrl = canvas.toDataURL(
                                    "image/png",
                                    1.0
                                ); // toDataURL('image/png', 1.0)

                                imagePreviewContainer.appendChild(canvas);
                                resolve(certificateDataUrl);
                            });
                        };

                        iframe.contentWindow.document.open();
                        iframe.contentWindow.document.write(templateHtml);
                        iframe.contentWindow.document.close();
                    });
            });
        });
    };

    const calculateExpiry = (date: any, months: any) => {
        const convertedDate = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() != convertedDate) {
            date.setDate(0);
        }
        return date;
    };

    const handleMaterialChange = (event: any) => {
        const materials = brandingMaterials;
        materials.map((certificateObj: any) => {
            if (certificateObj.assetName == event.target.name) {
                certificateObj.isAllowed = event.target.checked;
            }
        });
        setBrandingMaterials(materials);
    };

    const handleChange = (event: any) => {
        setCertificateInfo({
            ...certificateInfo,
            [event.target.name]:
                event.target.type == "checkbox"
                    ? event.target.checked
                    : event.target.name === "facilityName" ||
                      event.target.name === "city" ||
                      event.target.name === "state"
                    ? event.target.value.replace(/[><]/g, "")
                    : event.target.value.trim(),
        });
    };

    const onChangeAward = (value: any) => {
        logger("Award selected Value:", value);
        setCertificateInfo({ ...certificateInfo, award: value });
        loadBrandingMaterials(certificates, value);
        if (awards?.length > 1) {
            if (value != "Select") setShowBrandingMaterials(true);
            if (value == "Select") {
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setShowBrandingMaterials(false);
            }
        }
    };

    const loadBrandingMaterials = (
        certificatesData: any,
        value: any,
        userData: any = null
    ) => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const referenceIdArray = new Array(0);
        let selectedCertificate;
        if (value || userData?.programCertificateName) {
            const selectedValue =
                value || (userData ? userData.programCertificateName : null);
            selectedCertificate = certificatesData.filter(
                (certificateObj: any) => {
                    return (
                        certificateObj.certificateCategoryName == selectedValue
                    );
                }
            );
        } else {
            selectedCertificate = certificatesData;
        }

        if (selectedCertificate.length > 0) {
            setSelectedAward(selectedCertificate[0]);
            setAwardValidity(selectedCertificate[0].certificateExpiry);
            selectedCertificate[0].brandingMaterials.map((material: any) => {
                referenceIdArray.push(material.referenceId);
            });
            const referenceIdString = referenceIdArray.join(",");
            getAssetDetails(referenceIdString)
                .then((result) => {
                    const assetArray = new Array(0);
                    let isAllowed: any;
                    result.data.assets.map((asset: any) => {
                        if (userData?.brandingMaterials) {
                            const userMaterial =
                                userData.brandingMaterials.filter(
                                    (data: any) => {
                                        return (
                                            data.referenceId ==
                                            asset.referenceId
                                        );
                                    }
                                );
                            isAllowed = userMaterial.length > 0;
                        }

                        assetArray.push({
                            assetRefId: asset.referenceId,
                            assetName: asset.assetName,
                            isAllowed: !userData ? true : isAllowed,
                        });
                    });
                    setBrandingMaterials(assetArray);
                    store.dispatch(
                        setPageLoadingStatus({ isPageLoading: false })
                    );
                })
                .catch((error) => {
                    logger(error);
                    store.dispatch(showToast(error));
                });
        }
    };

    // on Change Awards
    useEffect(() => {
        if (awards?.length === 1) {
            const awardsDropdownMap = awards.map((award: any) => {
                return { label: award.awardName, value: award.awardName };
            });
            setAwardsDropdown(awardsDropdownMap);
            logger("awardsDropdownMap:", awardsDropdownMap);
        }
        if (awards?.length > 1) {
            setShowBrandingMaterials(false);
            const awardsDropdownMap: any = [
                { label: "Select", value: "Select" },
            ];
            awards.map((award: any) => {
                awardsDropdownMap.push({
                    label: award.awardName,
                    value: award.awardName,
                });
            });
            setAwardsDropdown(awardsDropdownMap);
            logger("awardsDropdownMap:", awardsDropdownMap);
        }
    }, [awards]);

    // On Change certificateInfo
    useEffect(() => {
        validateCertificateForm();
        if (certificateInfo.award && certificateInfo.award != "Select") {
            setShowBrandingMaterials(true);
        }
    }, [certificateInfo]);

    const validateCertificateForm = () => {
        if (!certificateInfo.award) {
            return false;
        }
        if (!certificateInfo.city) {
            return false;
        }
        if (!certificateInfo.state) {
            return false;
        }
        if (!certificateInfo.state) {
            return false;
        }
        if (!certificateInfo.countryName) {
            return false;
        }
        if (!certificateInfo.grantedOn) {
            if (grantedDateRef && grantedDateRef.current) {
                grantedDateRef.current.valueAsDate = null;
            }
            return false;
        }
        if (!certificateInfo.expiryOn) {
            return false;
        }
        if (certificateInfo.expiryOn <= certificateInfo.grantedOn) {
            expiryDateRef &&
                expiryDateRef.current &&
                expiryDateRef.current.setCustomValidity(
                    "Expires On date should be Greater than the Granted Date"
                );
            return false;
        }
        expiryDateRef &&
            expiryDateRef.current &&
            expiryDateRef.current.setCustomValidity("");
        return true;
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (certificateInfo.award == "Select") {
            const toast = {
                message: "Please select the Award.",
                code: "Error:",
            };
            store.dispatch(showToast(toast));
            return false;
        }
        if (validateCertificateForm()) {
            if (event?.nativeEvent?.submitter?.name === "preview") {
                setPreviewModal();
            } else {
                store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
                const generatedImage = await createCanvas();
                sleep(1000).then(() => {
                    const brandingMaterialsSelectedInd = brandingMaterials.some(
                        (item: any) => {
                            return item.isAllowed === true;
                        }
                    );
                    const materials: any = JSON.parse(
                        JSON.stringify(brandingMaterials)
                    );
                    saveCertificate(
                        certificateInfo,
                        materials,
                        props.hospital.hospitalId,
                        programId,
                        categoryId,
                        generatedImage,
                        props.hospital.countryId,
                        isCertificateYearExists,
                        selectedYear,
                        localStorage.getItem("selectedHospitalCountryCode")
                    )
                        .then((response) => {
                            getCertificateYearDropdown(selectedYear);
                            store.dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                            const success = {
                                message: certificateInfo.programCertificateId
                                    ? `Certificate ${
                                          brandingMaterialsSelectedInd
                                              ? "and Branding materials"
                                              : ""
                                      } successfully updated for ${
                                          props.hospital.facilityName
                                      }.`
                                    : `Certificate ${
                                          brandingMaterialsSelectedInd
                                              ? "and Branding materials"
                                              : ""
                                      } successfully generated for ${
                                          props.hospital.facilityName
                                      }.`,
                                code: "Success:",
                                type: "success",
                            };
                            setGenerateCertificateButtonLabel(
                                "Update Certificate"
                            );
                            store.dispatch(showToast(success));
                            setCertificateInfo({
                                ...certificateInfo,
                                programCertificateId: true,
                            });
                        })
                        .catch((error) => {
                            logger("error: ", error);
                            store.dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                        });
                });
            }
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            formRef.current?.requestSubmit();
        }
    };
    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });
    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };
    const eventHandler = (e: any) => {
        e.preventDefault();
    };

    return (
        <CertificationWrapper>
            <h3 className="h3 mb-4 d-lg-none">Generate Certificate</h3>
            <HospitalBanner
                hospitalName={props.hospital.facilityName}
                programName={props.programName}
                countryName={props.hospital.countryName}
            />
            <div className="row">
                <div className="col-lg-10">
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="d-flex flex-row-reverse">
                            <div className="pb-3 mand-field">
                                <sup>*</sup>
                                mandatory fields
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="award"
                                    id="award-label"
                                    className="label-form"
                                >
                                    Select Award
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                {awardsDropdown && (
                                    <Dropdown
                                        disabled={awardsDropdown.length == 0}
                                        id="award"
                                        items={awardsDropdown}
                                        selectedValue={certificateInfo.award}
                                        callParentOnSelect={onChangeAward}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="facilityName"
                                    className="label-form"
                                >
                                    Organization Name
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    title={CONSTANTS.ENTER_ALPHANUMERIC_TEXT}
                                    name="facilityName"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    value={certificateInfo.facilityName?.slice(
                                        0,
                                        CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
                                    )}
                                    type="text"
                                    className="form-control"
                                    id="facilityName"
                                    required
                                    maxLength={
                                        CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
                                    }
                                    aria-describedby="corpFacName"
                                />
                                <div className="form-help" id="corpFacName">
                                    (Enter 3 to{" "}
                                    {CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT}{" "}
                                    characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label htmlFor="city" className="label-form">
                                    City<sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    name="city"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    value={certificateInfo.city}
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    required
                                    maxLength={30}
                                    aria-describedby="corpFacCity"
                                />
                                <div className="form-help" id="corpFacCity">
                                    (Enter 2 to 30 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label htmlFor="state" className="label-form">
                                    State / Province<sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    name="state"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    value={certificateInfo.state}
                                    type="text"
                                    className="form-control"
                                    id="state"
                                    required
                                    maxLength={30}
                                    aria-describedby="corpFacState"
                                />
                                <div className="form-help" id="corpFacState">
                                    (Enter 2 to 30 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label htmlFor="country" className="label-form">
                                    Location<sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    title={CONSTANTS.ENTER_ALPHABETIC_TEXT}
                                    pattern={
                                        CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                    }
                                    name="countryName"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    defaultValue={certificateInfo.countryName}
                                    type="text"
                                    className="form-control"
                                    id="country"
                                    required
                                    maxLength={30}
                                    aria-describedby="corpFacCountry"
                                />
                                <div className="form-help" id="corpFacCountry">
                                    (Enter 2 to 30 characters)
                                </div>
                            </div>
                        </div>
                        {isCertificateYearExists && (
                            <div className="form-group row required">
                                <div className="col-md-4 form-label">
                                    <label
                                        htmlFor="certificateYear"
                                        id="certificateYear-label"
                                        className="label-form"
                                    >
                                        Certificate Year
                                    </label>
                                </div>
                                <div className="col-md-8">
                                    <Dropdown
                                        id="certificateYear"
                                        items={certificateYear}
                                        selectedValue={selectedYear}
                                        callParentOnSelect={(value: any) => {
                                            setSelectedYear(value);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="grantedon"
                                    className="label-form"
                                >
                                    <span className="d-block mb-n2">
                                        Granted On
                                        <sup>*</sup>
                                    </span>
                                    <i className="label-helptext">
                                        (DD-MM-YYYY)
                                    </i>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    name="grantedOn"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    defaultValue={certificateInfo.grantedOn}
                                    type="date"
                                    className="form-control"
                                    id="grantedon"
                                    required
                                    ref={grantedDateRef}
                                />
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-md-4 form-label">
                                <label
                                    htmlFor="expireson"
                                    className="label-form"
                                >
                                    <span className="d-block mb-n2">
                                        Expires On
                                        <sup>*</sup>
                                    </span>
                                    <i className="label-helptext">
                                        (DD-MM-YYYY)
                                    </i>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    name="expiryOn"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    defaultValue={certificateInfo.expiryOn}
                                    type="date"
                                    className="form-control"
                                    id="expireson"
                                    ref={expiryDateRef}
                                    required
                                />
                            </div>
                        </div>
                        {showBrandingMaterials &&
                            brandingMaterials?.length > 0 && (
                                <div
                                    className="form-group row"
                                    aria-labelledby="brandings"
                                    role="group"
                                >
                                    <div className="col-md-4 form-label">
                                        <label id="brandings">
                                            Branding Materials
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <ul className="d-md-flex flex-wrap cert-checkbox row mt-3 mt-md-0">
                                            {brandingMaterials.map(
                                                (material: any, index: any) => {
                                                    return (
                                                        <li
                                                            key={`brandingMaterial-${index}`}
                                                            className="col-sm-6"
                                                        >
                                                            <div
                                                                key={`check${index}`}
                                                                className="form-group form-check-bordered mb-4"
                                                            >
                                                                <input
                                                                    key={Math.random()}
                                                                    name={
                                                                        material.assetName
                                                                    }
                                                                    onChange={
                                                                        handleMaterialChange
                                                                    }
                                                                    onKeyDown={
                                                                        handleKeyDown
                                                                    }
                                                                    type="checkbox"
                                                                    id={`check${index}`}
                                                                    defaultChecked={
                                                                        material.isAllowed ==
                                                                        true
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor={`check${index}`}
                                                                >
                                                                    {
                                                                        material.assetName
                                                                    }
                                                                </label>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        {brandingMaterials?.length > 0 && (
                            <div className="row my-5">
                                <div className="col-12">
                                    <div className="cert-btns py-4">
                                        <div className="col-xl-8 offset-xl-4 d-flex justify-content-xl-start justify-content-center flex-column flex-sm-row">
                                            <button
                                                ref={previewCertificateRef}
                                                className="invisible invisible-btn"
                                                data-toggle="modal"
                                                data-target="#previewCertificate"
                                                aria-hidden="true"
                                                tabIndex={-1}
                                            >
                                                invisible
                                            </button>
                                            <button
                                                name="preview"
                                                className="btn btn-secondary btn-round"
                                            >
                                                Preview
                                            </button>
                                            {expierStatus && (
                                                <div
                                                    id="export-measure-btn"
                                                    className="export-export-btn ml-3"
                                                >
                                                    <button
                                                        className="btn btn-primary btn-round mt-3 mt-sm-0 "
                                                        data-toggle="popover"
                                                        {...(!isSafariBrowser()
                                                            ? {
                                                                  "data-trigger":
                                                                      "focus",
                                                              }
                                                            : {})}
                                                        data-placement="top"
                                                        aria-label="Export Facilities"
                                                        data-content={
                                                            expireMsgToolTip
                                                        }
                                                        data-container="#export-measure-btn"
                                                        onClick={eventHandler}
                                                    >
                                                        {
                                                            generateCertificateButtonLabel
                                                        }
                                                    </button>
                                                </div>
                                            )}
                                            {!expierStatus && (
                                                <button
                                                    name="submit"
                                                    className="btn btn-primary btn-round ml-sm-4 mt-4 mt-sm-0"
                                                >
                                                    {
                                                        generateCertificateButtonLabel
                                                    }
                                                </button>
                                            )}

                                            <div
                                                className="modal fade show aui-modal certificate-modal"
                                                id="previewCertificate"
                                                tabIndex={-1}
                                                aria-labelledby="previewLabel"
                                                aria-modal="true"
                                                role="dialog"
                                            >
                                                <div className="modal-dialog modal-dialog-centered modal-lg aha-card-modal">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h2
                                                                className="h3 mb-0"
                                                                id="previewLabel"
                                                            >
                                                                Preview
                                                            </h2>
                                                            <button
                                                                type="button"
                                                                className="btn btn-text"
                                                                data-dismiss="modal"
                                                                aria-label="Close Preview Certificate Modal"
                                                            >
                                                                <span
                                                                    aria-hidden="true"
                                                                    className="aha-icon-cross"
                                                                />
                                                            </button>
                                                        </div>
                                                        <div
                                                            id="imagePreviewContainer"
                                                            className="modal-body"
                                                        >
                                                            <span className="hiddenFontElementLubdubLight" />
                                                            <span className="hiddenFontElementLubdubMedium" />
                                                            <span className="hiddenFontElementLubdubBold" />
                                                        </div>
                                                        <div id="hiddendiv" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
                <div id="certificateCanvasParent">
                    <span className="hiddenFontElementLubdubLight" />
                    <span className="hiddenFontElementLubdubMedium" />
                    <span className="hiddenFontElementLubdubBold" />
                </div>
            </div>
        </CertificationWrapper>
    );
};

export default HospitalCertificationTab;
