import DropdownMenu from "components/DropdownMenu";
import React from "react";
import { MoreActionsWrapper } from "./styled";

interface Props {
    sortDropdown?: any;
    hospital?: any;
    callParentOnSelect?: any;
    vIcon?: any;
}

const MoreActions = (props: Props) => {
    const callActions = (value: any) => {
        props.callParentOnSelect(value);
    };
    const dropDownItem = (
        <>
            <i
                className={`font-red ${
                    props?.vIcon ? props?.vIcon : `aha-icon-meat-balls`
                }`}
                aria-hidden="true"
            />
            <span className="sr-only font-red export-text">More Actions</span>
        </>
    );
    return (
        <MoreActionsWrapper>
            <DropdownMenu
                items={props.sortDropdown}
                selectedValue=""
                callParentOnSelect={(value: any) => callActions(value)}
                dropDownItem={dropDownItem}
                id=""
                hospital={props.hospital}
            />
        </MoreActionsWrapper>
    );
};

export default MoreActions;
