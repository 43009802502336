import styled from "styled-components";

export const MeasuresWrapper = styled.div`
    margin-bottom: 249px;
    @media only screen and (min-width: 576px) {
        margin-bottom: 298px;
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: 50px;
    }

    .measure-filters {
        color: #000;
        .measure-filter-item {
            flex-grow: 1;
            > div {
                width: 165px;
            }
            @media only screen and (min-width: 768px) {
                flex-grow: unset;
            }
        }
        .measure-filter-item + .measure-filter-item {
            margin-left: 16px;
        }
        .cst-select {
            color: #000;
            flex-grow: 1;
            @media only screen and (min-width: 768px) {
                width: 100%;
            }
        }
    }
    .hidden {
        display: none !important;
    }
    .aui-accordion-content1 {
        padding-top: 60px;
        @media only screen and (min-width: 768px) {
            padding-top: 0;
        }
    }
    .edit-measures-link {
        color: #c10e21;
        position: absolute;
        right: 0;
        top: -28px;
        .btn:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        i:hover {
            cursor: pointer;
        }
        @media only screen and (min-width: 768px) {
            top: -34px;
        }
    }
    .measure-banner {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .warning-message {
        border: 1px solid #8e6d00;
        background-color: #fef0d0;
        color: #815e0b;
        padding: 0.75rem 1.25rem;
        border-radius: 0.25rem;
        position: relative;
    }
`;
