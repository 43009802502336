import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import DocumentServiceAPI from "api/documentsAPI";
import {
    localDateToYYYYMMDDFormatString,
    dateToLocaleTimeString,
    showModal,
    calculatNumOfDaysBetweenDates,
    localDateTimeToHHMMSSString,
} from "common/utils";
import Pagination, { IPagination } from "components/Pagination";
import store from "app/store";
import { useLocation, useParams } from "react-router-dom";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import Dropdown from "components/Dropdown";
import CONSTANTS from "common/constants";
import TableRowCount from "components/TableRowCount";
import SearchDropdown from "components/SearchDropdown";
import FocusTrap from "focus-trap-react";
import { getArchivalDocumentAPI } from "api/archivalDocumentIntegrationAPI";
import { getHospitalProgramCertificate } from "api/certificateAPI";
import { logger } from "../../utils/logger.utils";
import UploadDocument from "./UploadDocument";
import { IUploadDocForm } from "./UploadDocumentModalContent";
import { BulkUploadWrapper } from "./Styled";
import { DocumentResultTableWrapper } from "./DocumentResultTableWrapper";
import "styles/empty-state.scss";
import { useDispatch } from "react-redux";

const documentServiceAPI = new DocumentServiceAPI();

interface Props {
    isArchivedDocuments?: boolean;
    isEUP: boolean;
    setUploadProgram?: any;
    setUploadStandards?: any;
    viewDocument?: any;
    refreshTableDateNow: number;
    setRefreshTableDateNow: any;
    documentsInd?: boolean;
    ref?: any;
}

const DocumentResultTable = (props: Props) => {
    const params: any = useParams();
    // STATE Variables META DATA
    const hospitalId: any = params.hospitalId
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");
    const [programs, setPrograms] = useState<any>([]);
    const [standards, setStandards] = useState<any>([]);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [isExpire, setIsExpire] = useState<any>(false);

    // FILTER VALUES
    const [selectedProgram, setSelectedProgram] = useState<any>({
        programId: undefined,
        programCategoryId: undefined,
        programCategoryName: "",
        subscriptionStartDate: "",
        subscriptionEndDate: "",
    });
    const location = useLocation();
    const [selectedStandard, setSelectedStandard] = useState<any>(-1);
    const [status, setStatus] = useState<any>(undefined);
    const [sortByDate, setSortByDate] = useState<string>("DESC");
    const [documentResultList, setDocumentResultList] = useState<any>([]);
    const [mount, setMount] = useState<boolean>(false);
    const [isDocumentsTableLoading, setIsDocumentsTableLoading] =
        useState<boolean>(false);
    const [uploadProgram, setUploadProgram] = useState<any>({
        programId: undefined,
        programCategoryId: undefined,
        programCategoryName: "",
        subscriptionStartDate: "",
        subscriptionEndDate: "",
    });
    const dispatch = useDispatch();
    const [uploadStandards, setUploadStandards] = useState<any>([]);
    const [archivedDate, setArchivedDate] = useState<any>(null);
    const initUploadDocForm: IUploadDocForm = {
        title: "",
        programId: "",
        programName: "",
        uploadStandard: "###", // ### is sperating the standardId & SandardName
        uploadDescription: "",
        file: null,
        facilityMessage: "",
        fileUploadError: false,
        setFileUploadSuccess: false,
    };
    const [uploadDocForm, setUploadDocForm] =
        useState<IUploadDocForm>(initUploadDocForm);

    // Dropdown Values
    const [programsDropdown, setProgramsDropdown] = useState<any>([]);
    const [standardsDropdown, setStandardsDropdown] = useState<any>([]);
    const statusDropdown: Array<{ label: string; value: string }> = [
        { label: "All", value: "All" },
    ];
    if (!location?.state?.isArchived) {
        statusDropdown.push({ label: "Pending", value: "PENDING" });
    }
    statusDropdown.push(
        { label: "Approved", value: "APPROVED" },
        { label: "Rejected", value: "REJECTED" }
    );
    const sortByDateDropdown: Array<{ label: string; value: string }> = [
        { label: "Ascending", value: "ASC" },
        { label: "Descending", value: "DESC" },
    ];
    const [message, setMessage] = useState("");

    /* Pagination - START */
    const [pageNumber, setPageNumber] = useState<number>(1);
    const paginationSetLimit = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        totalCount: 0,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    /* Pagination - END */
    const [uploadDocumentInd, setuploadDocumentInd] = useState<boolean>(false);

    const getStatusText = (statusText: string, reviewComment: string) => {
        if (statusText === "PENDING") {
            return "Pending";
        }
        if (statusText === "APPROVED") {
            return "Approved";
        }
        if (statusText === "REJECTED") {
            return "Rejected";
        }
    };
    const updateSortCount = (value: any) => {
        switch (value) {
            case CONSTANTS.ASC:
                setMessage(`Table sorted in name ascending order.`);
                break;
            case CONSTANTS.DESC:
                setMessage(`Table sorted in name descending order.`);
                break;
            default:
                setMessage("");
                break;
        }
    };

    const applyFilter = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const archiveDate = localStorage.getItem("selectedArchivedDate");
        setIsDocumentsTableLoading(true);
        if (
            archiveDate &&
            archiveDate !== "undefined" &&
            location?.state?.isArchived
        ) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            await getArchivalDocumentAPI(
                hospitalId,
                localStorage.getItem("selectedArchivedDate"),
                sortByDate,
                selectedStandard,
                status,
                pageNumber
            )
                .then((res: any) => {
                    if (res.statusCode == 200) {
                        setDocumentResultList(res?.data?.documentData?.rows);
                        updateSortCount(sortByDate);
                        setPagination({
                            ...res.data._pagination,
                            setPageNumber,
                        });
                        setIsDocumentsTableLoading(false);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                })
                .catch((err: any) => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        } else if (!props.isArchivedDocuments) {
            documentServiceAPI
                .get(
                    hospitalId,
                    params.programId,
                    params.categoryId,
                    selectedStandard,
                    status,
                    sortByDate,
                    pageNumber
                )
                .then((documents: any) => {
                    if (
                        documents.success === true &&
                        documents.statusCode === 200
                    ) {
                        setDocumentResultList(documents.data.hospitalUploads);
                        setPagination({
                            ...documents.data._pagination,
                            setPageNumber,
                        });
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    setIsDocumentsTableLoading(false);
                    if (CONSTANTS.WINDOW.AUI.Tooltip) {
                        CONSTANTS.WINDOW.AUI.Tooltip.init();
                    }
                    if (mount) {
                        setRoleValue("alert");
                    }
                    setMount(true);
                })
                .catch((error: any) => {
                    logger("documentServiceAPI GET documents Error:", error);
                    setIsDocumentsTableLoading(false);
                });
        }
    };
    useEffect(() => {
        if (location?.state?.isArchived) {
            let selectedArchivedDate = localStorage.getItem(
                "selectedArchivedDate"
            );
            selectedArchivedDate =
                selectedArchivedDate === "undefined"
                    ? null
                    : selectedArchivedDate;
            setArchivedDate(
                selectedArchivedDate !== undefined ? selectedArchivedDate : null
            );
            applyFilter();
        }
    }, [localStorage.getItem("selectedArchivedDate")]);

    const downloadFile = (downloadId: number) => {
        documentServiceAPI.download(downloadId, hospitalId);
    };

    // On change of Page Number & Sort By Date Applying Filter method
    useEffect(() => {
        if (selectedProgram.programId) {
            applyFilter();
        }
    }, [
        sortByDate,
        pageNumber,
        selectedProgram,
        selectedStandard,
        status,
        props.refreshTableDateNow,
    ]);

    const checkIsCertificateExpire = (expiryOn: any) => {
        if (expiryOn) {
            const days = calculatNumOfDaysBetweenDates(new Date(), expiryOn);
            if (days >= 1 && days <= 50) {
                setIsExpire(true);
            } else if (days === 0) {
                setIsExpire(true);
            } else {
                setIsExpire(false);
            }
        } else {
            setIsExpire(false);
        }
    };

    const getExpireyDate = () => {
        getHospitalProgramCertificate(
            hospitalId,
            params.programId,
            params.categoryId
        ).then((certResponse: any) => {
            if (certResponse.success) {
                checkIsCertificateExpire(
                    certResponse.data.programCertficate
                        .programCertificateExpiryDate
                );
            }
        });
    };
    useEffect(() => {
        if (props.isEUP && !props.isArchivedDocuments) {
            getExpireyDate();
            store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        }
        getProgramsAndStandards();
    }, []);

    const getProgramsAndStandards = () => {
        Promise.all([
            getHospitalProgramDetails(params.programId, hospitalId),
            documentServiceAPI.getStandardsByProgramId(params.categoryId),
        ])
            .then((responses: any) => {
                if (
                    responses[0].statusCode === 200 &&
                    responses[0].success === true
                ) {
                    const defaultProgram = {
                        programId: params.programId,
                        programCategoryId: responses[0].data.programCategoryId,
                        programCategoryName:
                            responses[0].data.programCategoryName,
                    };
                    setPrograms([defaultProgram]);
                    setSelectedProgram(defaultProgram); // Default selection
                    setUploadProgram(defaultProgram);
                    if (props.setUploadProgram) {
                        props.setUploadProgram(defaultProgram);
                    }
                }
                if (
                    responses[1].statusCode === 200 &&
                    responses[1].success === true
                ) {
                    setStandards(responses[1].data.standards);
                    const uploadStandardsArray: any = [];
                    responses[1].data.standards.map((standard: any) => {
                        if (
                            (!props.isEUP && standard.isAdmin) ||
                            (props.isEUP && !standard.isAdmin)
                        ) {
                            uploadStandardsArray.push(standard);
                        }
                    });
                    setUploadStandards(uploadStandardsArray);
                    if (props.setUploadStandards) {
                        props.setUploadStandards(uploadStandardsArray);
                    }
                }
                if (props.isEUP || props.isArchivedDocuments) {
                    store.dispatch(
                        setPageLoadingStatus({ isPageLoading: false })
                    );
                }
            })
            .catch((err) => logger(err));
    };

    useEffect(() => {
        if (props.documentsInd) {
            if (selectedProgram.programId) {
                applyFilter();
            }
            getProgramsAndStandards();
        }
    }, [props.documentsInd]);

    const onChangeProgram = (value: any) => {
        let selectedProgramValue = {};
        programs.map((program: any) => {
            if (value === program.programId) {
                selectedProgramValue = program;
            }
        });
        setSelectedProgram(selectedProgramValue);
        setUploadProgram(selectedProgramValue);
        if (props.setUploadProgram) {
            props.setUploadProgram(selectedProgramValue);
        }
        setPageNumber(1);
    };

    useEffect(() => {
        // Populate Standars Dropdown
        const standardsDropdownMap: any = [
            {
                label: "All",
                value: -1,
            },
        ];

        standards.map((standard: any) => {
            if (
                !props.isEUP ||
                (props.isEUP &&
                    (standard.isAdmin === false ||
                        standard.standardName === CONSTANTS.SITE_SUMMARY))
            ) {
                standardsDropdownMap.push({
                    label: standard.standardName,
                    value: standard.standardId,
                });
            }
        });
        setStandardsDropdown(standardsDropdownMap);
    }, [standards]);

    useEffect(() => {
        // Populate Programs Dropdown
        const programsDropdownMap: any = [];

        programs.map((standard: any) => {
            programsDropdownMap.push({
                label: standard.programCategoryName,
                value: standard.programId,
            });
        });
        setProgramsDropdown(programsDropdownMap);
    }, [programs]);

    const setDocumentStatus = (approvalStatus: any) => {
        switch (approvalStatus) {
            case CONSTANTS.REGISTRED:
                return "aui-table-status-success";
            case CONSTANTS.PENDING:
                return "aui-table-status-pending";
            case CONSTANTS.REJECTED:
                return "aui-table-status-danger";
            default:
                return "aui-table-status-pending";
        }
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });
    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };
    const eventHandler = (e: any) => {
        e.preventDefault();
    };
    const tableFilterData = () => {
        return (
            <>
                <div aria-labelledby="docsfilerby" role="group">
                    <h2
                        className="h6 font-600 d-none d-md-block"
                        id="docsfilerby"
                    >
                        Filter By
                    </h2>
                    <div className="d-md-flex justify-content-between align-items-center mb-3 mb-md-5 doc-filter-main">
                        <div className="doc-filters m-0 d-flex flex-md-row flex-column flex-grow-1 navbar-expand-lg">
                            <div className="d-flex d-md-none justify-content-between align-items-center doc-filter-header">
                                <h2 className="h8 font-600 mb-0">Filter By</h2>
                                <button
                                    type="button"
                                    className="navbar-toggler doc-filter-more"
                                    data-toggle="collapse"
                                    data-target="#navbarFilters"
                                    aria-controls="navbarFilters"
                                    aria-expanded="false"
                                    aria-label="filter by"
                                >
                                    <i
                                        className="aha-icon-hamburger-round"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div
                                className="d-md-flex collapse navbar-collapse doc-filter-container"
                                id="navbarFilters"
                            >
                                <div className="flex-grow-1 row no-gutters m-0">
                                    <div className="d-xl-flex align-items-center doc-filter-field doc-filter-1">
                                        <label
                                            htmlFor="programSelected"
                                            id="programSelected-label"
                                            className="mr-3 mb-0"
                                        >
                                            Program
                                        </label>
                                        <div className="flex-grow-1">
                                            {programsDropdown && (
                                                <Dropdown
                                                    id="programSelected"
                                                    items={programsDropdown}
                                                    selectedValue={
                                                        selectedProgram.programId
                                                    }
                                                    callParentOnSelect={(
                                                        value: any
                                                    ) => {
                                                        onChangeProgram(value);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-xl-flex align-items-center doc-filter-field doc-filter-2">
                                        <label
                                            htmlFor="programStandars"
                                            id="programStandars-label"
                                            className="mr-3 mb-0"
                                        >
                                            Standards
                                        </label>
                                        <div className="flex-grow-1">
                                            {standardsDropdown && (
                                                <SearchDropdown
                                                    id="programStandars"
                                                    items={standardsDropdown}
                                                    selectedValue={
                                                        selectedStandard
                                                    }
                                                    callParentOnSelect={(
                                                        value: any
                                                    ) => {
                                                        setSelectedStandard(
                                                            value
                                                        );
                                                        setPageNumber(1);
                                                    }}
                                                    label="Select Standard"
                                                    placeholder="Select"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-xl-flex align-items-center doc-filter-field doc-filter-3">
                                        <label
                                            htmlFor="programStatus"
                                            id="programStatus-label"
                                            className="mr-3 mb-0"
                                        >
                                            Status
                                        </label>
                                        <div className="flex-grow-1">
                                            {statusDropdown && (
                                                <Dropdown
                                                    id="programStatus"
                                                    items={statusDropdown}
                                                    selectedValue={status}
                                                    callParentOnSelect={(
                                                        value: any
                                                    ) => {
                                                        setStatus(value);
                                                        setPageNumber(1);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isExpire &&
                            props.isEUP &&
                            !props.isArchivedDocuments && (
                                <div
                                    id="export-measure-btn"
                                    className="export-export-btn"
                                >
                                    <button
                                        className="btn btn-primary btn-round ml-md-3 ml-lg-4 upload-btn upload-docbtn mt-3 mt-md-0"
                                        data-toggle="popover"
                                        {...(!isSafariBrowser()
                                            ? {
                                                  "data-trigger": "focus",
                                              }
                                            : {})}
                                        data-placement="top"
                                        data-content="Document uploads will be enabled after admin renews this organization"
                                        data-container="#export-measure-btn"
                                    >
                                        Upload Documents
                                        <i
                                            className="aha-icon-upload ml-2 d-xl-inline-block d-md-none d-xl-inline-block"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            )}
                        {!isExpire &&
                            props.isEUP &&
                            !props.isArchivedDocuments && (
                                <button
                                    className="btn btn-primary btn-round ml-md-3 ml-lg-4 upload-btn upload-docbtn"
                                    onClick={() => {
                                        setuploadDocumentInd(true);
                                        showModal();
                                    }}
                                >
                                    Upload Documents
                                    <i
                                        className="aha-icon-upload ml-2 d-xl-inline-block d-md-none d-xl-inline-block"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}
                        {uploadDocumentInd ? (
                            <FocusTrap
                                focusTrapOptions={{
                                    escapeDeactivates: false,
                                    clickOutsideDeactivates: false,
                                }}
                            >
                                <UploadDocument
                                    uploadProgram={uploadProgram}
                                    standards={uploadStandards}
                                    setRefreshTableDateNow={
                                        props.setRefreshTableDateNow
                                    }
                                    initUploadDocForm={initUploadDocForm}
                                    uploadDocForm={uploadDocForm}
                                    setUploadDocForm={setUploadDocForm}
                                    setuploadDocumentInd={setuploadDocumentInd}
                                    uploadDocumentInd={uploadDocumentInd}
                                    isEUP={props.isEUP}
                                />
                            </FocusTrap>
                        ) : null}
                    </div>
                </div>
                <div className="d-md-flex justify-content-between mb-4">
                    <h2 className="h4 mb-0">
                        {location?.state?.isArchived
                            ? "Documents"
                            : "Supporting Documents"}
                    </h2>
                    <div className="form-group d-flex mt-4 mt-md-0 ml-md-4 mb-0 align-items-center">
                        <label
                            htmlFor="sortByDate"
                            id="sortByDate-label"
                            className="mr-4 mb-0 text-nowrap"
                        >
                            Sort by Date
                        </label>
                        {sortByDateDropdown && (
                            <div className="doc-sort">
                                <Dropdown
                                    id="sortByDate"
                                    items={sortByDateDropdown}
                                    selectedValue={sortByDate}
                                    callParentOnSelect={(value: any) => {
                                        setSortByDate(value);
                                    }}
                                />
                                <div
                                    className="sr-only"
                                    role="alert"
                                    aria-atomic="true"
                                >
                                    {message}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <TableRowCount
                    isFirst={pagination.isFirst}
                    pageNumber={pagination.pageNumber}
                    pageSize={pagination.pageSize}
                    isLast={pagination.isLast}
                    totalCount={pagination.totalCount}
                    roleValue={roleValue}
                    documentInd
                />
                <BulkUploadWrapper className="doc-table mt-3">
                    <table
                        className={`aui-responsive-status-table ${
                            isDocumentsTableLoading
                                ? CONSTANTS.TABLE_SPINNER
                                : ""
                        }`}
                    >
                        <thead>
                            <tr>
                                <th scope="col">
                                    <div className="aui-th">Date</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Document</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Type</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Standard</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Status</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentResultList?.length > 0 &&
                                documentResultList.map((document: any) => (
                                    <tr
                                        key={document.hospitalProgramUploadsId}
                                        className={setDocumentStatus(
                                            document.approvalStatus
                                        )}
                                    >
                                        <td
                                            data-title="Date"
                                            className="doc-table-date"
                                        >
                                            <div className="aui-td">
                                                <div className="w-100">
                                                    <div className="font-600 mb-1">
                                                        {document.documentCreatedAt
                                                            ? localDateToYYYYMMDDFormatString(
                                                                  document.documentCreatedAt
                                                              )
                                                            : localDateToYYYYMMDDFormatString(
                                                                  document.createdAt
                                                              )}
                                                    </div>
                                                    <div className="text-uppercase">
                                                        {document.documentCreatedAt
                                                            ? dateToLocaleTimeString(
                                                                  document.documentCreatedAt
                                                              )
                                                            : dateToLocaleTimeString(
                                                                  document.createdAt
                                                              )}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            data-title="Document"
                                            className="doc-table-title"
                                        >
                                            <div className="aui-td">
                                                <div className="w-100">
                                                    <div
                                                        className="h8 mb-1 font-600"
                                                        id={`doc-${document.documentId}`}
                                                    >
                                                        {document.documentTitle}
                                                    </div>
                                                    <p className="mb-0">
                                                        {
                                                            document.documentDescription
                                                        }
                                                    </p>
                                                    {document.bulkUploadId ? (
                                                        <div
                                                            className="bulk-uploadedby"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title={
                                                                document.corporationName
                                                            }
                                                            aria-label={`Document uploaded by: ${document.corporationName}`}
                                                            role="note"
                                                        >
                                                            Document uploaded
                                                            by:{" "}
                                                            {
                                                                document.corporationName
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            data-title="Type"
                                            className="doc-table-type"
                                        >
                                            <div className="aui-td">
                                                {`${document.fileType}`.toUpperCase()}
                                            </div>
                                        </td>
                                        <td
                                            data-title="Standard"
                                            className="doc-table-std"
                                        >
                                            <div className="aui-td">
                                                {document.standardName}
                                            </div>
                                        </td>
                                        <td
                                            data-title="Status"
                                            className="doc-table-status"
                                        >
                                            <div className="aui-td">
                                                {getStatusText(
                                                    document.approvalStatus,
                                                    document.reviewComment
                                                )}
                                            </div>
                                        </td>
                                        <td
                                            data-title="Action"
                                            className="doc-table-action"
                                        >
                                            <div
                                                className="aui-td"
                                                id={`action-${document.documentId}`}
                                            >
                                                <div className="d-flex justify-content-lg-center mx-lg-2 doc-action-btns">
                                                    {props.isEUP &&
                                                    document.adminUpload ? (
                                                        <button
                                                            className="btn-text-link-uline"
                                                            onClick={() =>
                                                                downloadFile(
                                                                    document.documentId
                                                                )
                                                            }
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            data-container={`#action-${document.documentId}`}
                                                            title="Download"
                                                            aria-label={`download document ${document.documentTitle}`}
                                                        >
                                                            <i className="aha-icon-download" />
                                                        </button>
                                                    ) : (
                                                        <div
                                                            data-toggle="modal"
                                                            data-target="#viewUploadedData"
                                                        >
                                                            <button
                                                                className="btn-text-link-uline"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="View"
                                                                data-container={`#action-${document.documentId}`}
                                                                onClick={() => {
                                                                    props.viewDocument(
                                                                        document.referenceId,
                                                                        document.programId
                                                                            ? document.programId
                                                                            : Number(
                                                                                  params?.programId
                                                                              )
                                                                    );
                                                                }}
                                                                aria-label={`view document ${document.documentTitle} `}
                                                            >
                                                                <i
                                                                    className="aha-icon-view-details"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div
                        role="alert"
                        aria-atomic="true"
                        className="table-emptystate-outer"
                    >
                        {documentResultList?.length < 1 && (
                            <div>No data to display</div>
                        )}
                    </div>
                </BulkUploadWrapper>
            </>
        );
    };
    return (
        <DocumentResultTableWrapper>
            <div className="container docs-container">
                {!props.isEUP && !props.isArchivedDocuments && (
                    <div className="d-flex justify-content-end mb-4 mb-sm-0">
                        <button
                            className="btn btn-primary btn-round ml-md-3 ml-lg-4 upload-btn upload-docbtn"
                            onClick={() => {
                                setuploadDocumentInd(true);
                                showModal();
                            }}
                        >
                            Upload Documents
                            <i className="aha-icon-upload ml-2" />
                        </button>
                    </div>
                )}
                {props.isArchivedDocuments &&
                (localStorage.getItem("selectedArchivedDate") === "undefined" ||
                    localStorage.getItem("selectedArchivedDate") === null) &&
                !documentResultList.length ? (
                    <div className="d-flex justify-content-center archived-main py-3 py-md-5 flex-column align-items-center user-emptystate">
                        <img
                            src="/images/dashboard-image.png"
                            alt="No data available"
                        />
                        <p className="mt-4 mb-5 user-emptystate-text">
                            No archived data available
                        </p>
                    </div>
                ) : (
                    tableFilterData()
                )}
                {pagination && Number(pagination.totalPages) > 0 ? (
                    <Pagination
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        totalCount={pagination.totalCount}
                        totalPages={pagination.totalPages}
                        isFirst={pagination.isFirst}
                        isLast={pagination.isLast}
                        setPageNumber={pagination.setPageNumber}
                        paginationSetLimit={5}
                    />
                ) : null}
            </div>
        </DocumentResultTableWrapper>
    );
};

export default DocumentResultTable;
