import styled from "styled-components";

export const HospitalManagementDetailsWrapper = styled.div`
    .hospital-mgnt-header {
        .navbar-toggler i {
            cursor: pointer;
            color: #c10e21;
            font-size: 20px;
            display: block;
        }
        .aui-navbar-toggler {
            margin-top: 20px;
            @media only screen and (min-width: 576px) {
                float: none;
                margin-top: 0;
            }
        }
        .tab {
            width: 100%;

            .tab-bordered {
                width: 100%;

                .nav.nav-tabs {
                    display: flex;
                    background-color: #fafafa;
                    li {
                        flex: 1 1 0;
                        @media only screen and (max-width: 574.99px) {
                            flex: 0;
                        }

                        button {
                            width: 100%;
                            padding-left: 10px;
                            padding-right: 10px;
                            font-size: 16px;
                            font-weight: 400;
                            .countnumber {
                                font-size: 26px;
                                font-weight: 600;
                                line-height: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    .more-actions {
        position: relative;
        height: 40px;
        .tooltip {
            transform: none !important;
            top: -45px !important;
            left: auto !important;
            right: 0;
            &:before {
                left: auto !important;
                right: 8px;
            }
            &:after {
                left: auto !important;
                right: 8px;
            }
            .tooltip-inner {
                width: 105px;
            }
        }
        .menu-cst-select-fld {
            height: auto;
        }
        .menu-cst-select-dropdown {
            margin-top: -10px;
        }
        .menu-cst-selected-item {
            padding: 0;
        }
    }
    .hospital-mgnt-table {
        tbody {
            tr {
                @media only screen and (min-width: 576px) {
                    .aui-row-th {
                        width: 50%;
                    }
                    .aui-row-td {
                        width: 50%;
                    }
                }
                @media only screen and (min-width: 768px) {
                    .aui-row-th {
                        width: 40%;
                    }
                    .aui-row-td {
                        width: 60%;
                    }
                }
                @media only screen and (min-width: 992px) {
                    .aui-row-th {
                        width: 45%;
                    }
                    .aui-row-td {
                        width: 55%;
                    }
                }
            }
        }
        .table-sub-row {
            width: 100%;
            tr {
                th {
                    padding-top: 4px;
                    font-weight: normal;
                    width: 15% !important;
                    @media only screen and (min-width: 576px) {
                        padding-top: 16px;
                    }
                }
                td {
                    border: 0 !important;
                    padding-top: 4px;
                    padding-bottom: 8px;
                    width: 50% !important;
                    @media only screen and (min-width: 576px) {
                        padding-top: 16px;
                    }
                }
            }
        }
    }
    .hospital-title-border {
        border-bottom: 3px solid #c10e21;
    }
    .hospital-detail-btn {
        padding-left: 50%;
    }
    .fac-detail-tab {
        .fac-heading {
            display: none;
        }
    }
    .admin-doc-tab {
        .doc-filter-1 {
            @media only screen and (min-width: 1200px) {
                width: 340px;
            }
            @media only screen and (min-width: 1400px) {
                width: 490px;
            }
            @media only screen and (min-width: 1500px) {
                width: 535px;
            }
        }
        .doc-table {
            thead {
                @media only screen and (min-width: 992px) {
                    th:nth-child(1) {
                        width: 17%;
                    }
                    th:nth-child(2) {
                        width: 20%;
                    }
                    th:nth-child(3) {
                        width: 10%;
                    }
                    th:nth-child(4) {
                        width: 23%;
                    }
                    th:nth-child(5) {
                        width: 15%;
                    }
                    th:nth-child(6) {
                        width: 15%;
                    }
                    th:nth-child(3),
                    th:nth-child(4),
                    th:nth-child(5),
                    th:nth-child(6) {
                        white-space: nowrap;
                    }
                    th:nth-child(1),
                    th:nth-child(2) {
                        .aui-th {
                            justify-content: flex-start;
                        }
                    }
                    th:nth-child(3),
                    th:nth-child(4),
                    th:nth-child(5),
                    th:nth-child(6) {
                        .aui-th {
                            justify-content: center;
                        }
                    }
                }
                @media only screen and (min-width: 1200px) {
                    th:nth-child(1) {
                        width: 13%;
                    }
                    th:nth-child(2) {
                        width: 31%;
                    }
                    th:nth-child(3) {
                        width: 8%;
                    }
                    th:nth-child(4) {
                        width: 24%;
                    }
                    th:nth-child(5) {
                        width: 12%;
                    }
                    th:nth-child(6) {
                        width: 12%;
                    }
                }
            }
        }
    }
    .deactivate-modal {
        .modal-header {
            border-bottom: 2px solid #c10e21;
            padding-bottom: 8px;
            margin-bottom: 10px;
        }
    }
    .logs-filter {
        .search-filter {
            width: 100%;
        }
        state-filter,
        .award-filter {
            @media only screen and (min-width: 576px) {
                width: 49%;
            }
        }
        .search-filter,
        state-filter,
        .award-filter {
            @media only screen and (min-width: 1200px) {
                width: 49%;
            }
        }
        .state-filter + .award-filter {
            @media only screen and (min-width: 576px) {
                margin-left: 2%;
            }
        }
        .search-filter + state-filter,
        .state-filter + .award-filter {
            @media only screen and (min-width: 1200px) {
                margin-left: 12px;
            }
        }
    }
`;
