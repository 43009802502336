import APIUtils from "../utils/API.utils";
import store from "../app/store";
import { showToast } from "../components/Toast/toast.slice";
import config from "../config";

const API = new APIUtils();

export const getActiveServiceYear = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const ApiRes = await API.GET(
                `${config[config.env].apiEndpoints.catalogs}/bulkRenew`
            );
            if (ApiRes.success == true) {
                resolve(ApiRes);
            } else {
                throw ApiRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
