import React, { useState } from "react";
import Dropdown from "components/Dropdown";
import {
    getHospitalProgramDetails,
    deleteOrGetProgramRegistry,
    getProgramResgistries,
    updateHospitalProgramDetails,
    saveProgramRegistry,
    getProgramCategoriesByCountryId,
} from "api/hospitalProgramsAPI";
import {
    getAgreementDetails,
    getAgreementDownloadUrl,
} from "api/programAgreementAPI";
import { callProgramCoprporaions } from "api/corporationAPI";
import { logger } from "utils/logger.utils";
import CONSTANTS from "common/constants";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { showModal, wordChange } from "common/utils";
import FocusTrap from "focus-trap-react";
import SearchDropdown from "components/SearchDropdown";

interface Props {
    programs: any;
    viewDetails: boolean;
}

const ProgramsCard = ({ programs, viewDetails }: Props) => {
    const dispatch = useDispatch();
    const [programInfo, setProgramInfo] = useState<any>("");
    const [finalAgreementFileName, setFinalAgreementFileName] =
        useState<any>("");
    const [programDetails, setProgramDetails] = useState<any>();
    const [registryQuestion, setRegistryQuestion] = useState<any>();
    const [corporationQuestion, setCorporationQuestion] = useState<any>();
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const [isCorpAuthorized, setIsCorpAuthorized] = useState<boolean>(false);
    const [registryId, setRegistryId] = useState<any>("");
    const [registryData, setRegistryData] = useState<any>({
        isRegistryUsing: false,
        registryName: "",
        otherRegistryName: "",
    });
    const [corporationData, setCorporationData] = useState<any>({
        corporationName: "",
    });
    const [isCorporationUsing, setIsCorporationUsing] = useState<any>("");
    const [saveDisableInd, setSaveDisableInd] = useState<boolean>(true);
    const [loadingInd, setLoadingInd] = useState<boolean>(false);
    let closeDetailsModalElement: any = null;
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const countryId: any = localStorage.getItem("selectedHospitalCountryId");
    const countryCode: any = localStorage.getItem(
        "selectedHospitalCountryCode"
    );
    const [programDetailsActivateInd, setProgramDetailsActivateInd] =
        useState<boolean>(false);
    const [corpQuestionInd, setCorpQuestionInd] = useState<boolean>(false);
    const [hfcRegistryInd, setHfcRegistryInd] = useState<boolean>(false);

    const handleRegistryChange = (event: any) => {
        if (event.target.name === "customRegistryName") {
            event.target.value = event.target.value.replace(/[><]/g, "");
        }
        if (
            event.target.name == "isRegistryUsing" &&
            event.target.value.trim() == "YES"
        ) {
            if (registryData.registries[0]?.value) {
                setSaveDisableInd(false);
            } else {
                setSaveDisableInd(true);
            }
            setIsAuthorized(false);
            setRegistryData({
                ...registryData,
                [event.target.name]: event.target.value.trim(),
                registryName: "",
            });
        } else {
            setSaveDisableInd(false);
            setRegistryData({
                ...registryData,
                [event.target.name]: event.target.value.trim(),
            });
        }
    };

    const handleCorporationChange = (event: any) => {
        if (
            event.target.name == "isCorporationUsing" &&
            event.target.value.trim() == "YES"
        ) {
            if (
                corporationData.corporations &&
                corporationData.corporations[0]?.value
            ) {
                setSaveDisableInd(false);
            } else {
                setSaveDisableInd(true);
            }
            setIsCorpAuthorized(false);
            setIsCorporationUsing(event.target.value.trim());
            setCorporationData({
                ...corporationData,
                corporationName: "",
            });
        } else {
            setSaveDisableInd(false);
            setIsCorporationUsing(event.target.value.trim());
            setCorporationData({
                ...corporationData,
                corporationName: "",
            });
        }
    };

    const getFinalUploadedAgreementInfo = async (uploadId: any) => {
        const uploadedAgreementInfo: any = await getAgreementDownloadUrl(
            uploadId,
            hospitalId
        );
        setFinalAgreementFileName(uploadedAgreementInfo.data.uploads.fileName);
    };

    const getProgramDetails = (program: any) => {
        setProgramInfo(program);
        Promise.all([
            getHospitalProgramDetails(program.programId, hospitalId),
            deleteOrGetProgramRegistry(
                hospitalId,
                program.programId,
                program.categoryId,
                "GET"
            ),
            getProgramResgistries(program.categoryId),
            getAgreementDetails(program.programId, hospitalId),
            getProgramCategoriesByCountryId(program.programId, countryId),
        ])
            .then((response: any) => {
                if (
                    response[0].success == true &&
                    response[4].success == true
                ) {
                    if (response[3].data && response[3].data.finalAgreementId) {
                        getFinalUploadedAgreementInfo(
                            response[3].data.finalAgreementId
                        );
                    }

                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    setLoadingInd(true);
                    const programJsonForm = JSON.parse(
                        response[0].data.programDetails
                    ).jsonForm;
                    programJsonForm.forEach((item: any) => {
                        if (item.key === "hf-registry") {
                            setHfcRegistryInd(true);
                        }
                    });
                    setProgramDetails(programJsonForm);
                    const registryArr: any = [];
                    const corporationArr: any = [];
                    let registryInfo: any;
                    const corporationInfo: any = {
                        corporationName: "",
                        corporationId: null,
                    };
                    setIsCorporationUsing(
                        response[0].data?.corporationId ? "YES" : "NO"
                    );
                    if (response[0].data?.corporationId) {
                        setIsCorpAuthorized(true);
                    }
                    callProgramCoprporaions(
                        program.programId,
                        setCorpQuestionInd
                    )
                        .then((corpResponse: any) => {
                            if (corpResponse?.data) {
                                for (
                                    let i = 0;
                                    i < corpResponse.data.length;
                                    i++
                                ) {
                                    if (
                                        corpResponse.data[i].corporationId ==
                                        response[0].data.corporationId
                                    ) {
                                        corporationInfo.corporationName =
                                            corpResponse.data[
                                                i
                                            ].corporationName.trim();
                                        corporationInfo.corporationId =
                                            corpResponse.data[i].corporationId;
                                    }
                                    corporationArr.push({
                                        label: corpResponse.data[
                                            i
                                        ].corporationName.trim(),
                                        value: corpResponse.data[
                                            i
                                        ].corporationName.trim(),
                                        id: corpResponse.data[i].corporationId,
                                    });
                                }
                                setCorporationData({
                                    corporations: corporationArr,
                                    corporationName:
                                        corporationInfo &&
                                        corporationInfo.corporationName
                                            ? corporationInfo.corporationName.trim()
                                            : "",
                                });
                                setLoadingInd(false);
                            }
                        })
                        .catch(() => {
                            setLoadingInd(false);
                        });
                    let prevSelectedRegistryName: any = "";
                    if (
                        response[2] &&
                        response[2].data &&
                        response[2].data.registries
                    ) {
                        response[2].data.registries.forEach((item: any) => {
                            if (
                                item.registryId == response[1].data.registryId
                            ) {
                                prevSelectedRegistryName = item.registryName;
                            }
                            registryArr.push({
                                label: item.registryName,
                                value: item.registryName,
                                id: item.registryId,
                            });
                        });
                    }

                    if (response[1].success == true) {
                        setRegistryId(response[1].data.registryId);
                        registryInfo = {
                            isRegistryUsing: "YES",
                            registryName: prevSelectedRegistryName,
                            customRegistryName: "",
                        };
                    } else if (
                        response[0].data &&
                        response[0].data.programDetails
                    ) {
                        const pgmDetailsData = JSON.parse(
                            response[0].data.programDetails
                        ).jsonForm;
                        for (let i = 0; i < pgmDetailsData.length; i++) {
                            if (
                                pgmDetailsData[i].hasOwnProperty(
                                    "isRegistryUsing"
                                )
                            ) {
                                registryInfo = pgmDetailsData[i];
                            }
                        }
                    }

                    registryArr.push({
                        label: CONSTANTS.REGISTRY_OTHER,
                        value: CONSTANTS.REGISTRY_OTHER,
                        id: CONSTANTS.REGISTRY_OTHER,
                    });
                    setRegistryData({
                        isRegistryUsing:
                            registryInfo && registryInfo.isRegistryUsing
                                ? registryInfo.isRegistryUsing
                                : "",
                        registries: registryArr,
                        registryName:
                            registryInfo && registryInfo.registryName
                                ? registryInfo.registryName
                                : "",
                        customRegistryName:
                            registryInfo && registryInfo.customRegistryName
                                ? registryInfo.customRegistryName
                                : "",
                    });
                    if (
                        registryInfo &&
                        registryInfo.isRegistryUsing == "YES" &&
                        registryInfo.registryName !== CONSTANTS.REGISTRY_OTHER
                    ) {
                        setIsAuthorized(true);
                    }

                    const selectedCategory = response[4].data.filter(
                        (category: any) => {
                            return category.categoryId == program.categoryId;
                        }
                    );
                    selectedCategory[0] &&
                        selectedCategory[0].programDetails &&
                        selectedCategory[0].programDetails.registry &&
                        setRegistryQuestion(
                            selectedCategory[0].programDetails.registry
                                .registryQuestion
                        );
                    selectedCategory[0] &&
                        selectedCategory[0].programDetails &&
                        selectedCategory[0].programDetails.corporation &&
                        setCorporationQuestion(
                            selectedCategory[0].programDetails.corporation
                                .corporationQuestion
                        );
                } else {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    logger(program.error);
                }
            })
            .catch((error) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                logger(error);
            });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
        if (isCorporationUsing === "YES" && !corporationData.corporationName) {
            const toast = {
                message: "Please select the Corporation.",
                code: "Error:",
            };
            store.dispatch(showToast(toast));
            return false;
        }
        if (
            registryData.isRegistryUsing === "YES" &&
            (!registryData.registryName ||
                (registryData.registryName === CONSTANTS.REGISTRY_OTHER &&
                    !registryData.customRegistryName))
        ) {
            const toast = {
                message: "Please select the Registry.",
                code: "Error:",
            };
            store.dispatch(showToast(toast));
            return false;
        }
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const registryInfo: any = {
            customData: true,
            isRegistryUsing: registryData.isRegistryUsing,
            registryName: registryData.registryName,
            customRegistryName: registryData.customRegistryName,
        };

        let isExistFlag: boolean = false;
        for (let i = 0; i < programDetails.length; i++) {
            if (programDetails[i].hasOwnProperty("isRegistryUsing")) {
                programDetails[i] = registryInfo;
                isExistFlag = true;
            }
        }
        if (!isExistFlag) {
            programDetails.push(registryInfo);
        }

        let selectedCorporationId: any = null;
        if (isCorporationUsing == "YES") {
            const selectedCorporationObj = corporationData.corporations.filter(
                (corporation: any) => {
                    return corporation.value == corporationData.corporationName;
                }
            );
            selectedCorporationId =
                selectedCorporationObj.length > 0 &&
                selectedCorporationObj[0].id;
        }

        const innerData = { jsonForm: programDetails };
        const updatedFormData = {
            programDetails: JSON.stringify(innerData),
            corporationId: selectedCorporationId,
            programCategoryId: programInfo.categoryId,
        };

        updateHospitalProgramDetails(
            updatedFormData,
            programInfo.programId,
            hospitalId
        )
            .then((updateProgramResponse: any) => {
                if (updateProgramResponse.success == true) {
                    if (
                        registryData.isRegistryUsing == "YES" &&
                        registryData.registryName != CONSTANTS.REGISTRY_OTHER
                    ) {
                        const selectedRegistryObj =
                            registryData.registries.filter((registry: any) => {
                                return (
                                    registry.value == registryData.registryName
                                );
                            });

                        const registryObj = {
                            programName: programInfo.programName,
                            categoryName: programInfo.categoryName,
                            registryId: selectedRegistryObj[0].id
                                ? selectedRegistryObj[0].id
                                : registryId,
                            programId: programInfo.programId,
                            categoryId: programInfo.categoryId,
                            hospitalId,
                        };

                        saveProgramRegistry(registryObj)
                            .then((response: any) => {
                                displayToastMessage(false);
                            })
                            .catch((error: any) => {
                                logger("error is :: ", error);
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            });
                    } else {
                        deleteOrGetProgramRegistry(
                            hospitalId,
                            Number(programInfo.programId),
                            Number(programInfo.categoryId),
                            "DELETE"
                        )
                            .then((deleteResponse: any) => {
                                displayToastMessage(false);
                            })
                            .catch((error) => {
                                logger(
                                    "error in deleteOrGetProgramRegistry :: ",
                                    error
                                );
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            });
                    }
                } else {
                    logger("error is :: ", updateProgramResponse.error);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch((error: any) => {
                logger("error is :: ", error);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };
    const displayToastMessage = (isPageLoading: boolean) => {
        dispatch(
            setPageLoadingStatus({
                isPageLoading,
            })
        );
        const toast = {
            message: "Program settings updated successfully.",
            type: "success",
            code: "Success:",
        };
        store.dispatch(showToast(toast));
        closeDetailsModalElement.click();
    };

    const programDetailsPopupModal = () => {
        setProgramDetailsActivateInd(true);
        showModal();
    };
    const programDetailsDismissModal = () => {
        setProgramDetailsActivateInd(false);
        showModal();
    };

    return (
        <div>
            {programs &&
                programs.map((program: any) => {
                    return (
                        <div
                            key={`program-${program.programId}`}
                            className="col-12 mb-3 prg-item"
                        >
                            <div className="card aha-type-card">
                                <div className="d-flex align-items-start justify-content-between typecard-header">
                                    <h3
                                        className="h5 flex-grow-1 m-0"
                                        id={`program-${program.programId}`}
                                    >
                                        {program.programName ||
                                            (program &&
                                                program.programInfo &&
                                                program.programInfo[0]
                                                    .programName)}
                                    </h3>
                                    <i
                                        className="aha-icon-acls mt-1"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="typecard-body">
                                    <p>
                                        {program?.programDescription ||
                                            (program &&
                                                program.programInfo &&
                                                program.programInfo[0]
                                                    ?.programDescription)}
                                    </p>
                                </div>
                                {viewDetails && (
                                    <div className="d-flex align-items-center justify-content-end typecard-footer">
                                        <button
                                            type="button"
                                            className="btn btn-round btn-sm btn-primary"
                                            onClick={() => {
                                                setRegistryQuestion("");
                                                setCorporationQuestion("");
                                                getProgramDetails(program);
                                                setSaveDisableInd(true);
                                                setProgramDetailsActivateInd(
                                                    true
                                                );
                                                programDetailsPopupModal();
                                            }}
                                            aria-label={`View Details about ${
                                                program.programName ||
                                                (program &&
                                                    program.programInfo &&
                                                    program.programInfo[0]
                                                        .programName)
                                            }`}
                                        >
                                            View Details
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}

            {viewDetails && programDetailsActivateInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal fade show aui-modal program-detail-modal"
                        tabIndex={-1}
                        aria-labelledby="modalTitle"
                        aria-modal="true"
                        role="dialog"
                        id="programdetails"
                        style={{
                            display: programDetailsActivateInd
                                ? "block"
                                : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <form onSubmit={onSubmit}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2 className="h4" id="modalTitle">
                                            Program Details
                                        </h2>
                                        <button
                                            type="button"
                                            className="close"
                                            aria-label="Close Program details modal"
                                            ref={(input) => {
                                                closeDetailsModalElement =
                                                    input;
                                            }}
                                            onClick={programDetailsDismissModal}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className="aha-icon-cross"
                                            />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="programdetail-table">
                                            <table className="aui-responsive-table aui-table-cols">
                                                <tbody>
                                                    {!corporationQuestion ||
                                                    !programDetails ||
                                                    !finalAgreementFileName ||
                                                    (countryCode !=
                                                        CONSTANTS.DOMESTIC_COUNTRY_CODE &&
                                                        !registryQuestion &&
                                                        !hfcRegistryInd) ? (
                                                        <div
                                                            className="aui-block-loader"
                                                            role="alert"
                                                            aria-live="assertive"
                                                            aria-label="Program details are loading"
                                                            tabIndex={0}
                                                        />
                                                    ) : (
                                                        <>
                                                            {programDetails?.map(
                                                                (
                                                                    programData: any
                                                                ) => {
                                                                    if (
                                                                        programData.hideElement &&
                                                                        programData.value ===
                                                                            ""
                                                                    ) {
                                                                        return null;
                                                                    }
                                                                    if (
                                                                        programData.type ==
                                                                        "parent"
                                                                    ) {
                                                                        return (
                                                                            <tr>
                                                                                <th
                                                                                    scope="row"
                                                                                    className="aui-row-th"
                                                                                >
                                                                                    {
                                                                                        programData.label
                                                                                    }

                                                                                    :
                                                                                </th>
                                                                                <td className="aui-row-td">
                                                                                    <div className="prm-subtype-list">
                                                                                        {programData.options.map(
                                                                                            (
                                                                                                option: any,
                                                                                                index: number
                                                                                            ) => {
                                                                                                return (
                                                                                                    <span
                                                                                                        key={`programData-${index}`}
                                                                                                        className="prm-subtype"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        {
                                                                                                            option.label
                                                                                                        }

                                                                                                        :{" "}
                                                                                                        {
                                                                                                            option.value
                                                                                                        }
                                                                                                    </span>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                    if (
                                                                        programData.type ==
                                                                        "multiSelect"
                                                                    ) {
                                                                        const multiSelectData: any =
                                                                            [];
                                                                        programData.value.forEach(
                                                                            (
                                                                                pgm: any
                                                                            ) => {
                                                                                multiSelectData.push(
                                                                                    pgm.label
                                                                                );
                                                                            }
                                                                        );
                                                                        return (
                                                                            <tr>
                                                                                <th
                                                                                    scope="row"
                                                                                    className="aui-row-th"
                                                                                >
                                                                                    {wordChange(
                                                                                        programData.label
                                                                                    )}

                                                                                    :
                                                                                </th>
                                                                                <td className="aui-row-td">
                                                                                    {multiSelectData.join(
                                                                                        " | "
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                    return (
                                                                        programData.label && (
                                                                            <tr>
                                                                                <th
                                                                                    scope="row"
                                                                                    className="aui-row-th"
                                                                                >
                                                                                    {wordChange(
                                                                                        programData.label
                                                                                    )}

                                                                                    :
                                                                                </th>
                                                                                <td className="aui-row-td">
                                                                                    {programData.type ==
                                                                                    "checkbox"
                                                                                        ? programData.value.join(
                                                                                              ", "
                                                                                          )
                                                                                        : programData.value ||
                                                                                          "N/A"}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    );
                                                                }
                                                            )}
                                                            {registryQuestion && (
                                                                <tr
                                                                    role="radiogroup"
                                                                    aria-labelledby="reg-question"
                                                                >
                                                                    <th
                                                                        scope="row"
                                                                        className="aui-row-th"
                                                                        id="reg-question"
                                                                    >
                                                                        {wordChange(
                                                                            registryQuestion
                                                                        )}
                                                                    </th>
                                                                    <td className="aui-row-td">
                                                                        <div className="d-flex">
                                                                            <div className="form-radio mr-5">
                                                                                <input
                                                                                    name="isRegistryUsing"
                                                                                    onChange={
                                                                                        handleRegistryChange
                                                                                    }
                                                                                    checked={
                                                                                        registryData &&
                                                                                        registryData.isRegistryUsing ==
                                                                                            "YES"
                                                                                    }
                                                                                    type="radio"
                                                                                    className="form-control"
                                                                                    id="prmYes"
                                                                                    defaultValue="YES"
                                                                                />
                                                                                <label
                                                                                    htmlFor="prmYes"
                                                                                    id="yes-record"
                                                                                >
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-radio">
                                                                                <input
                                                                                    name="isRegistryUsing"
                                                                                    onChange={
                                                                                        handleRegistryChange
                                                                                    }
                                                                                    checked={
                                                                                        registryData &&
                                                                                        registryData.isRegistryUsing ==
                                                                                            "NO"
                                                                                    }
                                                                                    defaultValue="NO"
                                                                                    type="radio"
                                                                                    className="form-control"
                                                                                    id="prmNo"
                                                                                />
                                                                                <label
                                                                                    htmlFor="prmNo"
                                                                                    id="no-record"
                                                                                >
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {registryQuestion &&
                                                                registryData &&
                                                                registryData.isRegistryUsing ==
                                                                    "YES" && (
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            className="aui-row-th"
                                                                        >
                                                                            <label
                                                                                htmlFor="registryName"
                                                                                id="registryName-label"
                                                                            >
                                                                                Please
                                                                                select
                                                                                the
                                                                                Registry
                                                                                name
                                                                            </label>
                                                                        </th>
                                                                        <td className="aui-row-td">
                                                                            <SearchDropdown
                                                                                id="registryName"
                                                                                items={
                                                                                    registryData
                                                                                        ? registryData.registries
                                                                                        : []
                                                                                }
                                                                                selectedValue={
                                                                                    registryData &&
                                                                                    registryData.registryName
                                                                                }
                                                                                callParentOnSelect={(
                                                                                    value: any
                                                                                ) => {
                                                                                    setSaveDisableInd(
                                                                                        false
                                                                                    );
                                                                                    setRegistryData(
                                                                                        {
                                                                                            ...registryData,
                                                                                            registryName:
                                                                                                value.trim(),
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                label="Select Registry"
                                                                                placeholder="Select"
                                                                                mandatoryInd
                                                                            />
                                                                            {registryData.registryName !==
                                                                                CONSTANTS.REGISTRY_OTHER &&
                                                                                registryData.registryName && (
                                                                                    <div className="w-100 reg-authorise">
                                                                                        <div className="form-group form-check m-0">
                                                                                            <input
                                                                                                required
                                                                                                type="checkbox"
                                                                                                value=""
                                                                                                id="reg-authorise"
                                                                                                defaultChecked={
                                                                                                    isAuthorized
                                                                                                }
                                                                                            />
                                                                                            <label htmlFor="reg-authorise">
                                                                                                I
                                                                                                authorize
                                                                                                this
                                                                                                registry
                                                                                                to
                                                                                                submit
                                                                                                required
                                                                                                data
                                                                                                on
                                                                                                the
                                                                                                organization&#39;s
                                                                                                behalf
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            {registryQuestion &&
                                                                registryData &&
                                                                registryData.isRegistryUsing ==
                                                                    "YES" &&
                                                                registryData.registryName ==
                                                                    CONSTANTS.REGISTRY_OTHER && (
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            className="aui-row-th"
                                                                        >
                                                                            Please
                                                                            specify
                                                                            the
                                                                            Registry
                                                                            name
                                                                        </th>
                                                                        <td className="aui-row-td">
                                                                            <input
                                                                                title={
                                                                                    CONSTANTS.ENTER_ALPHANUMERIC_TEXT
                                                                                }
                                                                                name="customRegistryName"
                                                                                onChange={
                                                                                    handleRegistryChange
                                                                                }
                                                                                value={
                                                                                    registryData &&
                                                                                    registryData.customRegistryName
                                                                                }
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="customRegistryName"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            {corpQuestionInd &&
                                                                corporationQuestion && (
                                                                    <tr
                                                                        role="radiogroup"
                                                                        aria-labelledby="corporationQuestion"
                                                                    >
                                                                        <th
                                                                            scope="row"
                                                                            className="aui-row-th"
                                                                            id="corporationQuestion"
                                                                        >
                                                                            {
                                                                                corporationQuestion
                                                                            }
                                                                        </th>
                                                                        <td className="aui-row-td">
                                                                            <div className="d-flex">
                                                                                <div className="form-radio mr-5">
                                                                                    <input
                                                                                        name="isCorporationUsing"
                                                                                        onChange={
                                                                                            handleCorporationChange
                                                                                        }
                                                                                        checked={
                                                                                            isCorporationUsing ==
                                                                                            "YES"
                                                                                        }
                                                                                        type="radio"
                                                                                        className="form-control"
                                                                                        id="corpYes"
                                                                                        defaultValue="YES"
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="corpYes"
                                                                                        id="yes-record"
                                                                                    >
                                                                                        Yes
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-radio">
                                                                                    <input
                                                                                        name="isCorporationUsing"
                                                                                        onChange={
                                                                                            handleCorporationChange
                                                                                        }
                                                                                        checked={
                                                                                            isCorporationUsing ==
                                                                                            "NO"
                                                                                        }
                                                                                        defaultValue="NO"
                                                                                        type="radio"
                                                                                        className="form-control"
                                                                                        id="corpNo"
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="corpNo"
                                                                                        id="no-record"
                                                                                    >
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            {corpQuestionInd &&
                                                                corporationQuestion &&
                                                                corporationData &&
                                                                isCorporationUsing ==
                                                                    "YES" && (
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            className="aui-row-th"
                                                                        >
                                                                            <label
                                                                                htmlFor="corporationName"
                                                                                id="corporationName-label"
                                                                            >
                                                                                Please
                                                                                select
                                                                                the
                                                                                Corporation
                                                                                name
                                                                            </label>
                                                                        </th>
                                                                        <td className="aui-row-td">
                                                                            <SearchDropdown
                                                                                id="corporationName"
                                                                                disabled={
                                                                                    loadingInd
                                                                                }
                                                                                isLoading={
                                                                                    loadingInd
                                                                                }
                                                                                items={
                                                                                    corporationData?.corporations
                                                                                        ? corporationData.corporations
                                                                                        : []
                                                                                }
                                                                                selectedValue={
                                                                                    corporationData &&
                                                                                    corporationData.corporationName
                                                                                }
                                                                                callParentOnSelect={(
                                                                                    value: any
                                                                                ) => {
                                                                                    setSaveDisableInd(
                                                                                        false
                                                                                    );
                                                                                    setCorporationData(
                                                                                        {
                                                                                            ...corporationData,
                                                                                            corporationName:
                                                                                                value.trim(),
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                label="Select Corporation"
                                                                                placeholder="Select"
                                                                                mandatoryInd
                                                                            />
                                                                            {corporationData.corporationName && (
                                                                                <div className="w-100 reg-authorise">
                                                                                    <div className="form-group form-check m-0">
                                                                                        <input
                                                                                            required
                                                                                            type="checkbox"
                                                                                            value=""
                                                                                            id="corporation-authorise"
                                                                                            defaultChecked={
                                                                                                isCorpAuthorized
                                                                                            }
                                                                                        />
                                                                                        <label htmlFor="corporation-authorise">
                                                                                            I
                                                                                            authorize
                                                                                            this
                                                                                            Corporation
                                                                                            to
                                                                                            submit
                                                                                            documents,
                                                                                            data,
                                                                                            and
                                                                                            make
                                                                                            payments
                                                                                            on
                                                                                            the
                                                                                            organization&#39;s
                                                                                            behalf
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {((registryQuestion &&
                                        programDetails &&
                                        finalAgreementFileName) ||
                                        (corporationQuestion &&
                                            programDetails &&
                                            finalAgreementFileName)) && (
                                        <div className="modal-footer">
                                            <div className="prg-modal-btn">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-round"
                                                    aria-label="Save program details"
                                                    disabled={saveDisableInd}
                                                >
                                                    {" "}
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </FocusTrap>
            )}
        </div>
    );
};

export default ProgramsCard;
