import React, { useEffect, useState } from "react";
import { HospitalTableWrapper } from "components/AdminHospitalTable/styled";
import { hospitalApprovalStatus } from "common/utils";
import CONSTANTS from "common/constants";
import { titleCase } from "utils/common.utils";

import { useDispatch, useSelector } from "react-redux";

interface Props {
    pageNumber: number;
    actionURL: string;
    sortInd: any;
    close: any;
    isTableLoading: boolean;
    hospitals: any;
    awardValue?: any;
    searchValue?: any;
    stateValue?: any;
}

export const EMSListingTable = ({
    isTableLoading,
    pageNumber,
    actionURL,
    sortInd,
    close,
    hospitals,
    awardValue,
    searchValue,
    stateValue,
}: Props) => {
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const [isSearch, setIsSearch] = useState<boolean>(false);
    useEffect(() => {
        if (searchFilter.logsSearchKey == "") {
            setIsSearch(false);
        } else {
            setIsSearch(true);
        }
    }, [searchFilter.logsSearchKey]);
    const [noDataCheck, setNoDataCheck] = useState<boolean>(true);
    useEffect(() => {
        const searchCheck = searchValue === "";
        const stateCheck =
            stateValue === CONSTANTS.ALL || stateValue === CONSTANTS.ALL_VALUE;
        const awardCheck =
            searchFilter.award === CONSTANTS.ALL ||
            searchFilter.award === CONSTANTS.ALL_VALUE ||
            searchFilter.award === undefined;
        setNoDataCheck(searchCheck && stateCheck && awardCheck);
    }, [searchFilter, stateValue, awardValue, searchValue]);

    return (
        <HospitalTableWrapper className="listing-table w-100">
            <table
                id="listing-table"
                className={`aui-responsive-status-table ${
                    isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                }`}
            >
                <thead>
                    <tr>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Organization Details
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Location Details
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Awarded By
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Award
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Status
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hospitals.length > 0 ? (
                        hospitals.map((hospital: any) => {
                            return (
                                <tr
                                    className={`${hospitalApprovalStatus(
                                        hospital.organizationStatus
                                    )}`}
                                >
                                    <td data-title="Organization Details">
                                        <div className="aui-td">
                                            <div className="organization-details">
                                                {hospital.facilityName}
                                                <div className="mt-1">
                                                    <span aria-hidden="true">
                                                        AHA EMS ID
                                                    </span>
                                                    <span className="sr-only">
                                                        A H A E M S I D
                                                    </span>{" "}
                                                    :{" "}
                                                    <span>
                                                        {hospital.emsId}
                                                    </span>{" "}
                                                    | Code :{" "}
                                                    <span>
                                                        {hospital.facilityCode}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Location Details">
                                        <div className="aui-td">
                                            <div className="location-details">
                                                Location:{" "}
                                                <span>
                                                    {hospital.countryName}
                                                </span>
                                                <div className="mt-1">
                                                    State:{" "}
                                                    <span>
                                                        {hospital.state}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Awarded By">
                                        <div className="aui-td">
                                            {hospital.awardedBy}
                                        </div>
                                    </td>
                                    <td data-title="Award">
                                        <div className="aui-td">
                                            <div className="award">
                                                {hospital.award}
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Status">
                                        <div className="aui-td">
                                            {titleCase(
                                                hospital.organizationStatus
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : noDataCheck ? (
                        <tr className="aui-status-table-emptystate">
                            <td colSpan={5}>
                                <div className="aui-td" role="alert">
                                    No data to display
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <tr className="aui-status-table-emptystate">
                            <td colSpan={5}>
                                <div className="aui-td" role="alert">
                                    No organization match your search.
                                    <br />
                                    Please try modifying your search criteria
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </HospitalTableWrapper>
    );
};

export default EMSListingTable;
