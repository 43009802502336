import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CONSTANTS from "common/constants";
import { any } from "prop-types";

export interface ISearchFilter {
    searchKey: string;
    programCategory: any;
    countryCode: any;
    stateCode: any;
    sortOrganizations: any;
    award: any;
    measure: any;
    tab: any;
    logsStateCode: any;
    logsSearchKey: any;
}

export const initialState: ISearchFilter = {
    searchKey: "",
    programCategory: CONSTANTS.ALL,
    countryCode: CONSTANTS.ALL,
    stateCode: CONSTANTS.ALL,
    sortOrganizations: CONSTANTS.ALL,
    award: CONSTANTS.ALL_VALUE,
    measure: CONSTANTS.ALL_VALUE,
    tab: any,
    logsStateCode: CONSTANTS.ALL,
    logsSearchKey: "",
};

const searchKeySlice = createSlice({
    name: "searchKey",
    initialState,
    reducers: {
        setSearchKey: (state, action: PayloadAction<string>) => {
            state.searchKey = action.payload;
        },
        setProgramCategoryId: (state, action: PayloadAction<string>) => {
            state.programCategory = action.payload;
        },
        setCountryCode: (state, action: PayloadAction<string>) => {
            state.countryCode = action.payload;
        },
        setStateCode: (state, action: PayloadAction<string>) => {
            state.stateCode = action.payload;
        },
        setSortOrganizations: (state, action: PayloadAction<string>) => {
            state.sortOrganizations = action.payload;
        },
        setAward: (state, action: PayloadAction<string>) => {
            state.award = action.payload;
        },
        setMeasure: (state, action: PayloadAction<string>) => {
            state.measure = action.payload;
        },
        setPageTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload;
        },
        setLogsStateCode: (state, action: PayloadAction<string>) => {
            state.logsStateCode = action.payload;
        },
        setLogsSearchKey: (state, action: PayloadAction<string>) => {
            state.logsSearchKey = action.payload;
        },
    },
});
const { actions, reducer } = searchKeySlice;

export const {
    setSearchKey,
    setProgramCategoryId,
    setCountryCode,
    setStateCode,
    setSortOrganizations,
    setAward,
    setMeasure,
    setPageTab,
    setLogsStateCode,
    setLogsSearchKey,
} = actions;
export default reducer;
