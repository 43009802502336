import React, { useEffect, useState } from "react";
import CONSTANTS from "common/constants";
import { useDispatch } from "react-redux";
import { HospitalTableWrapper } from "components/AdminHospitalTable/styled";
import { Link } from "react-router-dom";
import { dateToYYYYMMMDDFormat } from "common/utils";

interface Props {
    isTableLoading: boolean;
    tab: string;
    pageNumber: number;
    actionURL: string;
    sortInd: any;
    close: any;
    logsData: {
        key: any;
        serviceYear: any;
        renewedOn: any;
        renewedBy: any;
        totalNoOfOrganization: any;
    }[];
}

export const EMSLogsTable = ({
    isTableLoading,
    close,
    tab,
    pageNumber,
    actionURL,
    sortInd,
    logsData,
}: Props) => {
    return (
        <HospitalTableWrapper className="logs-table w-100">
            <table
                id="logs-table"
                className={`aui-responsive-status-table ${
                    isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                }`}
            >
                <thead>
                    <tr>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Service Year
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Renewed On
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Renewed By
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Total Organizations
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {logsData.length > 0 ? (
                        logsData?.map((log) => (
                            <tr
                                key={log.key}
                                className="aui-table-status-normal"
                            >
                                <td data-title="Service Year">
                                    <div className="aui-td">
                                        <Link
                                            to={{
                                                pathname: `${actionURL}/logs-list/${log.serviceYear}`,
                                                state: {
                                                    backToOrganizationLogs:
                                                        "/emsadmin/organization-logs",
                                                    emsAdminOrganizationLogsPage:
                                                        pageNumber,
                                                    pageSort: sortInd,
                                                    serviceYear:
                                                        log.serviceYear,
                                                    isTableLoading,
                                                },
                                            }}
                                            onClick={() => {
                                                localStorage.setItem(
                                                    "resetFilter",
                                                    "false"
                                                );
                                            }}
                                            className="btn-text-link-uline"
                                        >
                                            {log.serviceYear}
                                        </Link>
                                    </div>
                                </td>
                                <td data-title="Renewed On">
                                    <div className="aui-td">
                                        <div className="renewed-on">
                                            <div>
                                                {dateToYYYYMMMDDFormat(
                                                    log.renewedOn
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td data-title="Renewed By">
                                    <div className="aui-td">
                                        {log.renewedBy}
                                    </div>
                                </td>
                                <td data-title="Total Organization">
                                    <div className="aui-td">
                                        {log.totalNoOfOrganization}
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr className="aui-status-table-emptystate">
                            <td colSpan={4}>
                                <div className="aui-td" role="alert">
                                    No data to display
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </HospitalTableWrapper>
    );
};

export default EMSLogsTable;
