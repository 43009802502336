import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import config from "config";
import APIUtils from "utils/API.utils";
import CONSTANTS from "common/constants";

const API = new APIUtils();

export const getMeasureMetricChartData = async (
    hospitalId: any,
    programId: any,
    categoryId: number,
    startDate: string,
    endDate: string,
    interval: string,
    countryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes: any = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalDashboardSummary/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}?startDate=${startDate}&endDate=${endDate}&Interval=${interval}&countryId=${countryId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getQuarterlyBenchmark = async (
    categoryId: number,
    startDate: string,
    endDate: string,
    interval: string,
    countryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes: any = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalDashboardSummary/benchmarks/categories/${categoryId}?startDate=${startDate}&endDate=${endDate}&Interval=${interval}&countryId=${countryId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getAnnualMeasureMetricChartData = async (
    hospitalId: any,
    categoryId: number,
    years: any,
    countryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const apiEndpoint = `${
                config[config.env].apiEndpoints.accounts
            }/hospitalDashboardSummary/annualCalculations/hospitals/${hospitalId}/categories/${categoryId}?year=${JSON.stringify(
                years
            )}&countryId=${countryId}`;
            const APIRes: any = await API.GET(apiEndpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getAnnualBenchmark = async (
    categoryId: number,
    years: any,
    countryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const apiEndpoint = `${
                config[config.env].apiEndpoints.accounts
            }/hospitalDashboardSummary/annualBenchmarks/categories/${categoryId}?year=${JSON.stringify(
                years
            )}&countryId=${countryId}`;
            const APIRes: any = await API.GET(apiEndpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getExportExcel = async (
    hospitalId: any,
    programId: any,
    categoryId: number,
    startDate: string,
    endDate: string,
    interval: string,
    measureMetricChartData: any,
    allYears: any,
    countryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = measureMetricChartData;
            let endPoint;
            if (interval === CONSTANTS.INTERVAL.QUARTERLY) {
                endPoint = `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalDashboardSummary/export/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}?startDate=${startDate}&endDate=${endDate}&Interval=Quarterly&countryId=${countryId}`;
            } else if (interval === CONSTANTS.INTERVAL.ANNUAL) {
                endPoint = `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalDashboardSummary/export/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}?year=${JSON.stringify(
                    allYears
                )}&Interval=Annual&countryId=${countryId}`;
            }
            const APIRes = await API.POST(endPoint, reqBody);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const measureMetricGroupData = async (
    year: number[],
    countryId: string | null,
    categoryId: string
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const urlString = `${
                config[config.env].apiEndpoints.catalogs
            }/categories/${categoryId}/measureMetricGroupData?countryId=${countryId}&year=${JSON.stringify(
                year
            )}`;

            const ApiRes = await API.GET(urlString);
            if (ApiRes.success) {
                resolve(ApiRes);
            } else {
                throw ApiRes.error;
            }
        } catch (err) {
            const error: any = err;
            store.dispatch(showToast(error));
            reject(err);
        }
    });
};
