import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { exportDetails, showModal } from "common/utils";
import store from "app/store";
import { getAssetDetails } from "api/certificateAPI";
import CONSTANTS from "common/constants";
import axios from "axios";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { BreadCrumb } from "components/BreadCrumb";
import MoreActions from "components/MoreActions/MoreActions";
import FocusTrap from "focus-trap-react";
import { getArchivalAccordionDatesAPI } from "api/archivalDocumentIntegrationAPI";
import PaymentDetails from "./PaymentDetails";
import AgreementDetails from "./AgreementDetails";
import BillingDetails from "./BillingDetails";
import ProgramsDetails from "./ProgramDetails";
import HospitalDetails from "./HospitalDetails";
import FacilityDetailsBanner from "../HospitalBanner/FacilityDetailsBanner";
import { FacilityDetailsWrapper } from "./styled";

import {
    approveHospital,
    deactivateHospital,
    getSubscriptionDetails,
} from "../../../api/adminDashboardAPI";

interface paymentProps {
    paymentStatus: string;
    modeOfPayment: string;
    verifiedBy: string;
}

interface Props {
    hospital: any;
    returnPath: any;
    programData: any;
    certificateData?: any;
    updateHospitalInfo?: any;
    detailsInd?: boolean;
}

export const FacilityDetails = forwardRef((props: Props, ref) => {
    const [hospital, setHospital] = useState<any>(props.hospital);
    const [billingInfo, setBillingInfo] = useState<any>({});
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [registryInfo, setRegistryInfo] = useState<any>({});
    const [agreementInfo, setAgreementInfo] = useState<any>({});
    const [programData, setProgramData] = useState<any>(props.programData);
    const [registryQuestionData, setRegistryQuestionData] = useState<any>({});
    const [activeTab, setActiveTab] = useState<any>(1);
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const [archivedDate, setArchivedDate] = useState<any>();
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [orderId, setOrderId] = useState<any>();
    const [activateFacilityActivateInd, setActivateFacilityActivateInd] =
        useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const params: any = useParams();
    const mangangementTab = location.state?.bT
        ? location.state?.bT
        : CONSTANTS.ACTIVE;

    const mangangementPage = location.state?.bP ? location.state?.bP : 1;

    useEffect(() => {
        setHospital(props.hospital);
        props.hospital && modifyLastEditedData(props.hospital);
        if (
            !props.returnPath?.state?.hRP &&
            !props.returnPath?.state?.adminfacilityMangemnetTab
        ) {
            const elementsArray = [];
            elementsArray.push(
                {
                    label: "Dashboard",
                    returnPath: props.returnPath,
                },
                {
                    label: "Registration Details",
                    returnPath: props.returnPath,
                }
            );
            setBreadcrumbItems(elementsArray);
        }
        getSubscriptionDetails(
            params.hospitalId,
            params.programId,
            params.categoryId
        )
            .then((response: any) => {
                if (response.success) {
                    setOrderId(response.data.orderId);
                }
            })
            .catch((error: any) => {
                dispatch(showToast(error));
            });
    }, [props.hospital]);

    useEffect(() => {
        if (hospital.approvalStatus === CONSTANTS.PENDING) {
            getArchivalAccordionDatesAPI(params.hospitalId).then((res: any) => {
                if (res.statusCode == 200) {
                    const archivalDate = res.data.archiveDates;
                    setArchivedDate(
                        res.data.archiveDates[archivalDate.length - 1]
                    );
                }
            });
        }
    }, []);

    useEffect(() => {
        const elementsArray = [];
        if (props.returnPath?.corporationId && props.returnPath?.state?.hRP) {
            elementsArray.push(
                {
                    label: "Corporation Management",
                    returnPath: {
                        pathname: "/corporation/corporation-management",
                        state: {
                            t: mangangementTab,
                            p: mangangementPage,
                        },
                    },
                },
                {
                    label: props.returnPath.state.corporationName,
                    returnPath: {
                        pathname: `/corporation/corporation-management/corporation-details/${props.returnPath.corporationId}`,
                        state: {
                            bT: mangangementTab,
                            bP: mangangementPage,
                        },
                    },
                },
                {
                    label: "Organizations Registered",
                    returnPath: props.returnPath,
                },
                {
                    label: props.returnPath.state.facilityName,
                    returnPath: props.returnPath,
                }
            );
        } else if (
            props.returnPath?.registryId &&
            props.returnPath?.state?.hRP
        ) {
            elementsArray.push(
                {
                    label: "Registry Management",
                    returnPath: "/registry/registry-management",
                },
                {
                    label: "Organizations Registered",
                    returnPath: props.returnPath,
                },
                {
                    label: "Registration Details",
                    returnPath: props.returnPath,
                }
            );
        } else if (props.returnPath?.state?.adminfacilityMangemnetTab) {
            elementsArray.push(
                {
                    label: "Organization Management",
                    returnPath: props.returnPath,
                },
                {
                    label:
                        props.returnPath?.state?.adminfacilityMangemnetTab ==
                        CONSTANTS.PENDING
                            ? "Pending Approval"
                            : props.returnPath?.state
                                  ?.adminfacilityMangemnetTab ==
                              CONSTANTS.REGISTRED
                            ? "Registered Organization"
                            : props.returnPath?.state
                                  ?.adminfacilityMangemnetTab ==
                              CONSTANTS.CERTIFIED
                            ? "Certified Organization"
                            : "Deactivated Organization",
                    returnPath: props.returnPath,
                },
                {
                    label: location.state.facilityName,
                    returnPath: props.returnPath,
                }
            );
        }
        setBreadcrumbItems(elementsArray);
    }, [props.returnPath]);

    useEffect(() => {
        setProgramData(props.programData);
        props.programData && modifyLastEditedData(props.programData);
    }, [props.programData]);

    useEffect(() => {
        paymentInfo && modifyLastEditedData(paymentInfo);
    }, [paymentInfo]);

    useEffect(() => {
        props.updateHospitalInfo && props.updateHospitalInfo(hospital);
    }, [hospital]);

    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };

    const handleHospitalDataChange = (modifiedData: any) => {
        setHospital(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleProgramDataChange = (modifiedData: any) => {
        setProgramData(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleBillingDataChange = (modifiedData: any) => {
        setBillingInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handlePaymentDataChange = (modifiedData: any) => {
        setPaymentInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleAgreementDataChange = (modifiedData: any) => {
        setAgreementInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const close = () => {
        history.push(props.returnPath || "/admin/hospital-management");
    };

    const exportFacilityData = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getAssetDetails(CONSTANTS.EXPORT_FACILITY_TEMPLATE, true).then(
            async (presignedURL: any) => {
                if (
                    presignedURL.data?.assets?.[0].isDownloadable &&
                    presignedURL.data?.assets?.[0].presignedUrl
                ) {
                    const iframe: any = document.createElement("iframe");
                    iframe.setAttribute("height", "0");
                    iframe.setAttribute("width", "0");
                    iframe.className = "hiddenFrame";

                    try {
                        const axiosResponse = await axios({
                            method: "get",
                            url: presignedURL.data?.assets?.[0].presignedUrl,
                        });

                        if (axiosResponse.data) {
                            const templateHtml = axiosResponse.data;
                            /* add iframe to the main document for canvas creation and print */
                            document.body.appendChild(iframe);

                            iframe.onload = async () => {
                                await exportDetails(
                                    hospital,
                                    props.programData,
                                    registryQuestionData,
                                    registryInfo,
                                    agreementInfo,
                                    billingInfo,
                                    paymentInfo
                                );
                                const toast = {
                                    message:
                                        "Organization details successfully exported.",
                                    code: "Success:",
                                    type: "success",
                                };
                                dispatch(showToast(toast));

                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            };

                            iframe.contentWindow.document.open();
                            iframe.contentWindow.document.write(templateHtml);
                            iframe.contentWindow.document.close();
                        }
                    } catch {
                        const toast = {
                            message: "Export template doesn't exist.",
                        };

                        dispatch(showToast(toast));
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                }
            }
        );
    };
    const sortDropdown = [
        {
            label: CONSTANTS.EXPORT,
            value: CONSTANTS.EXPORT,
            className: "aha-icon-export menu-cst-icon",
        },
    ];
    if (orderId !== null) {
        sortDropdown.push({
            label:
                hospital.approvalStatus === CONSTANTS.PENDING
                    ? CONSTANTS.APPROVE
                    : CONSTANTS.ACTIVATE,
            value:
                hospital.approvalStatus === CONSTANTS.PENDING
                    ? CONSTANTS.APPROVE
                    : CONSTANTS.ACTIVATE,
            className: "aha-icon-tick-mark mr-2 font-red",
        });
    }
    if (hospital.approvalStatus !== CONSTANTS.PENDING) {
        sortDropdown.push({
            label: CONSTANTS.ARCHIVED,
            value: CONSTANTS.ARCHIVED,
            className: "aha-icon-archived menu-cst-icon",
        });
    }

    const callParentOnSelect = (value: any) => {
        if (value === CONSTANTS.EXPORT) {
            return exportFacilityData();
        }
        if (value === CONSTANTS.APPROVE) {
            return approveFacility();
        }
        if (value === CONSTANTS.ACTIVATE) {
            return activateFacilityConfirmationPopupModal();
        }
        if (value === CONSTANTS.ARCHIVED) {
            history.push(
                `/admin/organization-management/archived-view/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}`,
                {
                    customState: props.returnPath,
                    isArchived: true,
                    facilityName: hospital.facilityName,
                    pathname: "/admin/organization-management",
                    search: location?.state?.search,
                    isDeactived: true,
                }
            );
        }
    };
    const approveFacility = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        approveHospital(
            hospital.hospitalId,
            params.programId,
            params.categoryId,
            "APPROVED"
        )
            .then((response) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (response.success && response.statusCode === 200) {
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "Organization Approved:",
                            message: `Organization ${hospital.facilityName} successfully approved for the ${programData.programCategoryName} program.`,
                            type: "success",
                        })
                    );
                    close();
                }
            })
            .catch((error) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                store.dispatch(showToast(error));
            });
    };
    const activateFacilityConfirmationPopupModal = () => {
        setActivateFacilityActivateInd(true);
        showModal();
    };
    const activateFacilityConfirmationDismissModal = () => {
        setActivateFacilityActivateInd(false);
        showModal();
    };
    const activateFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const activateResult = await deactivateHospital(
            hospital.facilityCode,
            true,
            hospital.hospitalId,
            null,
            parseInt(params.categoryId, 10)
        );
        if (activateResult.success) {
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
            const toast = {
                message: `${hospital.facilityName} successfully activated.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            close();
            activateFacilityConfirmationDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };
    useImperativeHandle(ref, () => ({
        exportFacilityData,
        close,
    }));
    return (
        <FacilityDetailsWrapper>
            {(hospital.approvalStatus === CONSTANTS.PENDING ||
                hospital.isActive == false) && (
                <BreadCrumb breadcrumbItems={breadcrumbItems} />
            )}
            {(hospital.approvalStatus === CONSTANTS.PENDING ||
                (!hospital.isActive &&
                    (hospital.approvalStatus === CONSTANTS.REGISTRED ||
                        hospital.approvalStatus === CONSTANTS.RENEWING ||
                        hospital.approvalStatus === CONSTANTS.CERTIFIED ||
                        hospital.approvalStatus === CONSTANTS.AWARDED))) && (
                <div className="d-flex justify-content-between align-items-center mb-4 fac-heading">
                    <h1 className="h2 mb-0 font-400 ">
                        Organization Management
                    </h1>

                    <div className="d-flex">
                        <div
                            id="moreactions"
                            className="d-flex align-items-center more-actions"
                        >
                            <div
                                className="export-dropdown ml-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="More Actions"
                                data-container="#moreactions"
                            >
                                <MoreActions
                                    sortDropdown={sortDropdown}
                                    hospital={hospital}
                                    callParentOnSelect={callParentOnSelect}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {hospital.isActive === false && activateFacilityActivateInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal show fade aui-modal"
                        tabIndex={-1}
                        aria-labelledby="modalLabel"
                        aria-modal="true"
                        role="dialog"
                        style={{
                            display: activateFacilityActivateInd
                                ? "block"
                                : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <span className="sr-only">
                                            Confirm Activate Organization
                                        </span>
                                        <p className="pb-2">
                                            Are you sure you want to
                                            activate&nbsp;
                                            <b>{hospital.facilityName}</b>{" "}
                                            organization?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                aria-label="Cancel Activation and close the modal"
                                                onClick={
                                                    activateFacilityConfirmationDismissModal
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                onClick={activateFacility}
                                                aria-label="Confirm Activate"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
            <FacilityDetailsBanner
                hospital={hospital}
                programData={programData}
                lastEditedData={lastEditedData}
                exportFacilityDetailsData={exportFacilityData}
                certificateData={props.certificateData}
                close={close}
                orderId={orderId}
                isAdmin
                facilityId="detailsFacility"
                id="detailsSettings"
                confirmDeactivateModalId="detailsConfirmDeactiveModal"
                confirmRemoveModalId="detailsConfirmRemoveModal"
                detailsTitleBannerId="detailsTitleBanner"
            />
            <div
                className="d-flex flex-column flex-md-row flex-wrap aui-accordion-arrowtab"
                id="facilityDetails"
                role="tablist"
            >
                <button
                    onClick={() => setActiveTab(1)}
                    className="aui-acc-arrowtab-item aui-acc-arrtab-z1 aui-accordion-arrowtab1"
                    data-toggle="collapse"
                    data-target="#hospitalDetails"
                    aria-expanded={activeTab == 1}
                    role="tab"
                    id="admin-facilitydetails"
                >
                    <span className="aui-acc-arrowtab-btn">
                        <span className="btn btn-text aui-acc-arrowtab-text">
                            Organization Details
                            <i
                                className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                aria-hidden="true"
                            />
                        </span>
                    </span>
                </button>
                <button
                    onClick={() => setActiveTab(2)}
                    className="aui-acc-arrowtab-item aui-acc-arrtab-z2 aui-accordion-arrowtab2"
                    data-toggle="collapse"
                    data-target="#programDetails"
                    aria-expanded={activeTab == 2}
                    role="tab"
                    id="admin-programdetails"
                >
                    <span className="aui-acc-arrowtab-btn">
                        <span className="btn btn-text aui-acc-arrowtab-text">
                            Program Details
                            <i
                                className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                aria-hidden="true"
                            />
                        </span>
                    </span>
                </button>
                <button
                    onClick={() => setActiveTab(3)}
                    className="aui-acc-arrowtab-item aui-acc-arrtab-z3 aui-accordion-arrowtab3"
                    data-toggle="collapse"
                    data-target="#agreementDetails"
                    aria-expanded={activeTab == 3}
                    role="tab"
                    id="admin-agreementdetails"
                    disabled={!programData?.agreementReferenceId}
                >
                    <span className="aui-acc-arrowtab-btn">
                        <span className="btn btn-text aui-acc-arrowtab-text">
                            Agreement Details
                            <i
                                className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                aria-hidden="true"
                            />
                        </span>
                    </span>
                </button>
                <button
                    onClick={() => setActiveTab(4)}
                    className="aui-acc-arrowtab-item aui-acc-arrtab-z4 aui-accordion-arrowtab4"
                    data-toggle="collapse"
                    data-target="#billingDetails"
                    aria-expanded={activeTab == 4}
                    role="tab"
                    id="admin-billingdetails"
                    disabled={
                        !(
                            paymentInfo?.paymentStatus == CONSTANTS.COMPLETED ||
                            (paymentInfo?.paymentStatus == CONSTANTS.PENDING &&
                                paymentInfo?.modeOfPayment ==
                                    CONSTANTS.PAYMENT_OFFLINE)
                        )
                    }
                >
                    <span className="aui-acc-arrowtab-btn">
                        <span className="btn btn-text aui-acc-arrowtab-text">
                            Billing Details
                            <i
                                className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                aria-hidden="true"
                            />
                        </span>
                    </span>
                </button>
                <button
                    onClick={() => setActiveTab(5)}
                    className="aui-acc-arrowtab-item aui-acc-arrtab-z5 aui-accordion-arrowtab5"
                    data-toggle="collapse"
                    data-target="#paymentDetails"
                    aria-expanded={activeTab == 5}
                    role="tab"
                    id="admin-paymentdetails"
                    disabled={
                        !(
                            paymentInfo?.paymentStatus == CONSTANTS.COMPLETED ||
                            (paymentInfo?.paymentStatus == CONSTANTS.PENDING &&
                                paymentInfo?.modeOfPayment ==
                                    CONSTANTS.PAYMENT_OFFLINE)
                        )
                    }
                >
                    <span className="aui-acc-arrowtab-btn">
                        <span className="btn btn-text aui-acc-arrowtab-text">
                            Payment Details
                            <i
                                className="aha-icon-arrow-down aui-acc-dropicon mx-2 d-inline-block d-md-none"
                                aria-hidden="true"
                            />
                        </span>
                    </span>
                </button>
                <div
                    id="hospitalDetails"
                    className={`collapse aui-accordion-arrowcontent aui-acc-arrc1 ${
                        activeTab == 1 ? "show" : "hide"
                    }`}
                    data-parent="#facilityDetails"
                    role="tabpanel"
                >
                    <HospitalDetails
                        hospital={hospital}
                        setHospital={handleHospitalDataChange}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        close={close}
                        orderId={orderId}
                    />
                </div>
                <div
                    id="programDetails"
                    className={`collapse aui-accordion-arrowcontent aui-acc-arrc2 ${
                        activeTab == 2 ? "show" : "hide"
                    }`}
                    data-parent="#facilityDetails"
                    role="tabpanel"
                >
                    <ProgramsDetails
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        programData={props.programData}
                        close={close}
                        setRegistryInfo={setRegistryInfo}
                        setRegistryQuestionData={setRegistryQuestionData}
                        setProgramData={handleProgramDataChange}
                        hospital={hospital}
                        orderId={orderId}
                    />
                </div>
                <div
                    id="agreementDetails"
                    className={`collapse aui-accordion-arrowcontent aui-acc-arrc3 ${
                        activeTab == 3 ? "show" : "hide"
                    }`}
                    data-parent="#facilityDetails"
                    role="tabpanel"
                >
                    <AgreementDetails
                        hospital={hospital}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        close={close}
                        setAgreementInfo={handleAgreementDataChange}
                        orderId={orderId}
                    />
                </div>
                <div
                    id="billingDetails"
                    className={`collapse aui-accordion-arrowcontent aui-acc-arrc4 ${
                        activeTab == 4 ? "show" : "hide"
                    }`}
                    data-parent="#facilityDetails"
                    role="tabpanel"
                >
                    <BillingDetails
                        paymentInfo={paymentInfo}
                        hospital={hospital}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        close={close}
                        setBillingInfo={handleBillingDataChange}
                        orderId={orderId}
                        detailsInd={props.detailsInd}
                    />
                </div>
                <div
                    id="paymentDetails"
                    className={`collapse aui-accordion-arrowcontent aui-acc-arrc5 ${
                        activeTab == 5 ? "show" : "hide"
                    }`}
                    data-parent="#facilityDetails"
                    role="tabpanel"
                >
                    <PaymentDetails
                        detailsInd={props.detailsInd}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        close={close}
                        setPaymentInfo={handlePaymentDataChange}
                        hospital={hospital}
                        programCategoryName={programData?.programCategoryName}
                        certificateExpiryDate={
                            props.certificateData?.programCertificateExpiryDate
                        }
                    />
                </div>
            </div>
        </FacilityDetailsWrapper>
    );
});

export default FacilityDetails;
