import CONSTANTS from "common/constants";
import React, { createRef, useEffect, useState } from "react";
import { GroupedMultiSelectDropdownWrapper } from "./styled";

interface Props {
    items: any;
    selectedValue: any;
    callParentOnSelect: any;
    id: string;
    disabled?: boolean;
    isLoading?: boolean;
    groupHeadings?: any;
    hasAllOption?: boolean;
}

const GroupedMultiSelectDropdown = (props: Props) => {
    const [timeStamp, setTimeStamp] = useState<number>(Date.now());
    const {
        items,
        selectedValue,
        callParentOnSelect,
        id,
        disabled,
        isLoading,
        groupHeadings,
    } = props;
    const [showItems, setShowItems] = useState<boolean>(false);
    const [focusOptionIndex, setFocusOptionIndex] = useState<number>(0);
    const refsArray = items.map(() => createRef());
    const dropDownRef = createRef<HTMLDivElement>();
    const allOptionRef = createRef<HTMLInputElement>();

    useEffect(() => {
        document.addEventListener("click", function (e: any) {
            if (
                !document
                    ?.getElementById(`dropdown-wrapper-${id}`)
                    ?.contains(e.target)
            ) {
                setShowItems(false); // Clicked outside the box - Close Dropdown
            }
        });
    }, [items, selectedValue, id]);

    useEffect(() => {
        if (showItems) {
            if (props.hasAllOption) {
                refsArray[focusOptionIndex].current.focus();
            } else {
                allOptionRef.current?.focus();
            }
        }
    }, [showItems]);

    const toggleDropDown = () => {
        if (disabled) return;
        setShowItems(!showItems);
    };

    const toggleDropdownOnKeyPress = (event: any) => {
        if (event.which === 32 || event.which === 13) {
            setFocusOptionIndex(0);
            event && event.preventDefault();
            toggleDropDown();
        }
    };

    const removeValueFromSelection = (item: any) => {
        let index: number = -1;
        let newSelectedValue = selectedValue;
        if (item.value !== CONSTANTS.ALL_VALUE) {
            for (let i = 0; i < selectedValue.length; i++) {
                if (selectedValue[i].value == item.value) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                newSelectedValue.splice(index, 1);
            }
            if (props.hasAllOption) {
                const allIndex = newSelectedValue.findIndex(
                    (selectedValue: any) =>
                        selectedValue.value === CONSTANTS.ALL_VALUE
                );
                if (allIndex > -1) {
                    newSelectedValue.splice(allIndex, 1);
                }
            }
        } else {
            newSelectedValue = [];
        }

        return newSelectedValue;
    };

    const selectOption = (item: any, index: number) => {
        if (isChecked(item)) {
            const newSelectedValues = removeValueFromSelection(item);
            callParentOnSelect(newSelectedValues);
        } else {
            let newSelectedValues = selectedValue;
            if (item.value !== CONSTANTS.ALL_VALUE) {
                newSelectedValues.push(item);
                if (
                    props.hasAllOption &&
                    newSelectedValues.length === items.length - 1
                ) {
                    newSelectedValues.push({
                        label: CONSTANTS.ALL_VALUE,
                        value: CONSTANTS.ALL_VALUE,
                    });
                }
            } else {
                newSelectedValues = [...items];
            }
            callParentOnSelect(newSelectedValues);
        }
        setTimeStamp(Date.now());
        refsArray[index].current.focus();
    };

    const upAndDownOption = (event: any, item: any, index: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.keyCode === 27 || event.keyCode === 9) {
            // On Escape Toggle Dropdown
            dropDownRef && dropDownRef.current && dropDownRef.current.focus();
            toggleDropDown();
            return;
        }
        // On Key UP Arrow
        if (event.which === 38) {
            if (focusOptionIndex > 0) {
                const setIndex = focusOptionIndex - 1;
                setFocusOptionIndex(setIndex);
                refsArray[setIndex].current.focus();
            } else if (focusOptionIndex === 0 && !props.hasAllOption) {
                allOptionRef.current?.focus();
            }
        }
        // On Key Down Arrow
        if (event.which === 40 && focusOptionIndex < items.length - 1) {
            const setIndex = focusOptionIndex + 1;
            setFocusOptionIndex(setIndex);
            refsArray[setIndex].current.focus();
        }
        // On Key Enter or Spacebar
        if (event.which === 13 || event.which === 32) {
            selectOption(item, index);
        }
    };

    const isChecked = (item: any) => {
        let checked = false;
        for (let i = 0; i < selectedValue.length; i++) {
            if (selectedValue[i].value == item.value) {
                checked = true;
                break;
            }
        }
        return checked;
    };

    const checkIfAllChecked = () => {
        if (items.length === selectedValue.length) {
            return true;
        }
        return false;
    };

    const selectOrUnselectAll = (event: any) => {
        if (!event.target.checked) {
            callParentOnSelect([]);
        } else {
            callParentOnSelect([...items]);
        }
    };

    const getLabelToDisplay = () => {
        const selectedExceptAll = selectedValue.filter(
            (select: any) => select.value !== CONSTANTS.ALL_VALUE
        );
        const itemsExceptAll = items.filter(
            (itemObject: any) => itemObject.value !== CONSTANTS.ALL_VALUE
        );
        if (selectedExceptAll.length === itemsExceptAll.length) {
            return CONSTANTS.ALL_VALUE;
        }
        return selectedExceptAll.length;
    };

    const changeFocus = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.keyCode === 27 || event.keyCode === 9) {
            // On Escape Toggle Dropdown
            dropDownRef && dropDownRef.current && dropDownRef.current.focus();
            toggleDropDown();
            return;
        }
        // on down
        if (event.which === 40) {
            setFocusOptionIndex(0);
            refsArray[0].current.focus();
        }
        // on enter or spacebar
        if (event.which === 13 || event.which === 32) {
            if (allOptionRef.current?.checked) {
                event = { target: { checked: false } };
            } else {
                event = { target: { checked: true } };
            }
            selectOrUnselectAll(event);
        }
    };

    return (
        <GroupedMultiSelectDropdownWrapper>
            <div
                id={`dropdown-wrapper-${id}`}
                className={`cst-select ${
                    disabled ? "cst-select-grouped-disabled" : ""
                } ${isLoading ? "cst-select-grouped-loading" : ""}`}
            >
                <div
                    className="cst-grouped-select-fld cst-multiselect-fld"
                    role="combobox"
                    ref={dropDownRef}
                    aria-expanded={!!showItems}
                    onClick={() => toggleDropDown()}
                    onKeyDown={toggleDropdownOnKeyPress}
                    aria-haspopup="listbox"
                    aria-owns={`${id}-select-dropdown`}
                    aria-controls={`${id}-select-dropdown`}
                    id={`${id}-box`}
                    tabIndex={0}
                    aria-labelledby={`dropdown-label-${id}`}
                >
                    <span
                        className="sr-only cst-grouped-select-required"
                        id={`cst-select-required-${id}`}
                    >
                        required
                    </span>
                    <button
                        type="button"
                        value=""
                        className="cst-grouped-selected-item"
                        // id={`${id}`}
                        tabIndex={-1}
                    >
                        {timeStamp && (
                            <span key={`${timeStamp}`}>
                                {getLabelToDisplay()} Selected
                            </span>
                        )}
                        <span className="sr-only">dropdown</span>
                    </button>
                    <div
                        className={`cst-arrow aha-icon-arrow-down ${
                            showItems ? "cst-arrow-up" : "cst-arrow-down"
                        }`}
                        aria-hidden="true"
                    />
                </div>
                <ul
                    style={{ display: showItems ? "block" : "none" }}
                    className="cst-select-dropdown"
                    id={`${id}-select-dropdown`}
                    aria-label="dropdown items"
                >
                    {groupHeadings && groupHeadings.length > 0 && (
                        <li className="cst-grouped-section p-0">
                            <ul className="cst-grouped-list m-0">
                                <li className="cst-grouped-item">
                                    <div>
                                        <div className="form-check-bordered form-check-bordered form-check-all">
                                            {selectedValue.length > -1 && (
                                                <input
                                                    type="checkbox"
                                                    id={`${id}-select-check-all`}
                                                    checked={checkIfAllChecked()}
                                                    onChange={
                                                        selectOrUnselectAll
                                                    }
                                                    onKeyDown={changeFocus}
                                                    ref={allOptionRef}
                                                />
                                            )}
                                            <label
                                                htmlFor={`${id}-select-check-all`}
                                            >
                                                All
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    )}
                    {groupHeadings && groupHeadings.length > 0
                        ? groupHeadings.map((heading: any) => (
                              <li
                                  key={heading}
                                  role="group"
                                  className="cst-grouped-section"
                                  aria-labelledby={`${id}-label-${heading}`}
                              >
                                  <div
                                      className="font-600 cst-grouped-label"
                                      id={`${id}-label-${heading}`}
                                  >
                                      {heading}
                                  </div>
                                  <ul className="cst-grouped-list">
                                      {items.map(
                                          (item: any, index: number) =>
                                              /* eslint-disable */

                                              item.category === heading ? (
                                                  <li
                                                      key={"li-" + item.label}
                                                      className="cst-grouped-item"
                                                  >
                                                      {/* Add class 'cst-select-disabled' if disabled */}
                                                      <div
                                                          onClick={() =>
                                                              selectOption(
                                                                  item,
                                                                  index
                                                              )
                                                          }
                                                          className={
                                                              item.disableInd
                                                                  ? "cst-select-disabled"
                                                                  : ""
                                                          }
                                                      >
                                                          <div className="form-check-bordered">
                                                              {selectedValue.length >
                                                                  -1 && (
                                                                  // Add attribute disabled if disabled
                                                                  <input
                                                                      type="checkbox"
                                                                      value={
                                                                          item
                                                                      }
                                                                      checked={isChecked(
                                                                          item
                                                                      )}
                                                                      id={`${id}-select-check-${index}`}
                                                                      onKeyDown={(
                                                                          event: any
                                                                      ) =>
                                                                          upAndDownOption(
                                                                              event,
                                                                              item,
                                                                              index
                                                                          )
                                                                      }
                                                                      ref={
                                                                          refsArray[
                                                                              index
                                                                          ]
                                                                      }
                                                                      key={`option-${item.label}`}
                                                                      disabled={
                                                                          item.disableInd
                                                                              ? true
                                                                              : false
                                                                      }
                                                                  />
                                                              )}
                                                              <label
                                                                  htmlFor={`${id}-select-check-${index}`}
                                                              >
                                                                  {item.label}
                                                              </label>
                                                          </div>
                                                      </div>
                                                  </li>
                                              ) : null

                                          /* eslint-enable */
                                      )}
                                  </ul>
                              </li>
                          ))
                        : items.map(
                              (item: any, index: number) => (
                                  /* eslint-disable */
                                  <li
                                      key={"li-" + item.label}
                                      className="cst-grouped-item"
                                  >
                                      {/* Add class 'cst-select-disabled' if disabled */}
                                      <div
                                          onClick={() =>
                                              selectOption(item, index)
                                          }
                                          className={
                                              item.disableInd
                                                  ? "cst-select-disabled"
                                                  : ""
                                          }
                                      >
                                          <div className="form-check-bordered">
                                              {selectedValue.length > -1 && (
                                                  // Add attribute disabled if disabled
                                                  <input
                                                      type="checkbox"
                                                      value={item}
                                                      checked={isChecked(item)}
                                                      id={`${id}-select-check-${index}`}
                                                      onKeyDown={(event: any) =>
                                                          upAndDownOption(
                                                              event,
                                                              item,
                                                              index
                                                          )
                                                      }
                                                      ref={refsArray[index]}
                                                      key={`option-${item.label}`}
                                                      disabled={
                                                          item.disableInd
                                                              ? true
                                                              : false
                                                      }
                                                  />
                                              )}
                                              <label
                                                  htmlFor={`${id}-select-check-${index}`}
                                              >
                                                  {item.label}
                                              </label>
                                          </div>
                                      </div>
                                  </li>
                              )

                              /* eslint-enable */
                          )}
                </ul>
            </div>
        </GroupedMultiSelectDropdownWrapper>
    );
};

export default GroupedMultiSelectDropdown;
