import styled from "styled-components";

export const DetailsWrapper = styled.div`
    .tabs-nav {
        border-bottom: 1px solid #e3e3e3;
        .nav-tabs .nav-link {
            border-radius: 0;
        }
    }
    .aui-global-menubtn .btn-mainmenu-sm i {
        font-size: 8px;
    }
    .btn-mainmenu-sm {
        font-size: 16px;
        font-weight: 600;
    }
    .aui-global-nav .navbar-toggler {
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding: 0.25rem 1.25rem;
    }
    .tabs-nav .nav-tabs .nav-item {
        @media only screen and (min-width: 992px) {
            margin-left: 33px;
            margin-right: 33px;
            :first-child {
                margin-left: 40px;
            }
        }
        @media only screen and (min-width: 1361px) {
            :first-child {
                margin-left: 0;
            }
        }

        @media only screen and (max-width: 992px) {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
    .aui-p-tabs .navbar-collapse .tab-menuview {
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        margin: 0 auto;
        border-bottom: none;
        @media only screen and (min-width: 1361px) {
            width: 1280px;
        }
    }
`;
