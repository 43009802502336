import styled from "styled-components";

export const SearchFilterWrapper = styled.div`
    &.search-filter-block {
        margin-bottom: 20px;
    }
    .search-filter-field {
        display: flex;
        flex-wrap: wrap;
        @media only screen and (min-width: 1200px) {
            flex-wrap: nowrap;
            margin: 0;
        }
    }
    .search-filter {
        @media only screen and (min-width: 1200px) {
            flex-grow: 1;
            width: auto;
        }
        > * {
            width: 100%;
        }
    }
    .search-filter,
    .location-filter,
    .state-filter,
    .award-filter,
    .measure-filter,
    .program-filter {
        width: 100%;
        margin-bottom: 12px;
        @media only screen and (min-width: 576px) {
            width: 49%;
            margin-bottom: 20px;
        }
        @media only screen and (min-width: 768px) {
            margin-bottom: 16px;
        }
        @media only screen and (min-width: 1200px) {
            margin-bottom: 0;
        }
    }
    .location-filter,
    .state-filter,
    .award-filter,
    .measure-filter,
    .program-filter {
        @media only screen and (min-width: 1200px) {
            flex: 0 0 190px;
        }
    }
    .search-filter + .location-filter {
        @media only screen and (min-width: 576px) {
            margin-left: 2%;
        }
        @media only screen and (min-width: 1200px) {
            margin-left: 12px;
        }
    }
    .location-filter + .state-filter {
        @media only screen and (min-width: 1200px) {
            margin-left: 12px;
        }
    }
    .search-filter + .state-filter {
        @media only screen and (min-width: 576px) {
            margin-left: 0;
        }
        @media only screen and (min-width: 1200px) {
            margin-left: 12px;
        }
    }
    .state-filter + .award-filter {
        @media only screen and (min-width: 576px) {
            margin-left: 0;
        }
        @media only screen and (min-width: 1200px) {
            margin-left: 12px;
        }
    }
    .award-filter + .measure-filter {
        @media only screen and (min-width: 576px) {
            margin-left: 0;
        }
        @media only screen and (min-width: 1200px) {
            margin-left: 14px;
        }
    }
    .state-filter + .program-filter {
        @media only screen and (min-width: 576px) {
            margin-left: 2%;
        }
        @media only screen and (min-width: 1200px) {
            margin-left: 12px;
        }
    }
`;
