import { RootState } from "app/rootReducer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoaderWrapper } from "./styled";

export const Loader = () => {
    // REDUX variables
    const loader = useSelector((state: RootState) => state.loader);
    const [isLoading, setIsLoading] = useState<boolean>(loader.isPageLoading);
    const [isloadingWithMessage, setIsloadingWithMessage] = useState<boolean>(
        loader.isloadingWithMessage ?? false
    );
    const { message } = loader;

    useEffect(() => {
        setIsLoading(loader.isPageLoading);
        setIsloadingWithMessage(loader.isloadingWithMessage ?? false);
    }, [loader]);

    return (
        <>
            {isLoading ? (
                <>
                    <div
                        className="aui-loader"
                        role="alert"
                        aria-live="assertive"
                        tabIndex={0}
                        aria-label="Page is loading"
                    />
                    {isloadingWithMessage && (
                        <LoaderWrapper
                            className="aui-loader aui-loader-dark d-flex align-items-center justify-content-center"
                            role="alert"
                            aria-live="assertive"
                            tabIndex={0}
                        >
                            <div className="loading-message">
                                <div className="message-light">{message}</div>
                                <div className="message-dark">
                                    Please do not refresh the page
                                </div>
                            </div>
                        </LoaderWrapper>
                    )}
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default Loader;
