import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CONSTANTS from "common/constants";
import {
    setSearchKey,
    setSortOrganizations,
} from "components/SearchFilter/searchFilter.slice";
import { TabsWrapper } from "./Styled";

interface Props {
    activeTab: string;
    tabName?: any;
}

const CorporationTabs = ({ activeTab, tabName }: Props) => {
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const dispatch = useDispatch();
    useEffect(() => {
        if (
            activeTab === "corporationDashboard" ||
            activeTab === "corporationBulkDocumentSubmission"
        ) {
            dispatch(setSortOrganizations(CONSTANTS.ALL));
            dispatch(setSearchKey(""));
        }
        if (
            activeTab !== "corporationFaciltyCertified" &&
            (searchFilter.sortOrganizations === CONSTANTS.EXPIRY_ASCENDING ||
                searchFilter.sortOrganizations === CONSTANTS.EXPIRY_DESCENDING)
        ) {
            if (tabName === "function") {
                tabName("corporationFaciltyCertified");
            }
            dispatch(setSortOrganizations(CONSTANTS.ALL));
        }
    }, []);

    const clearFilteredThings = () => {
        if (activeTab === "corporationBulkDocumentSubmission") {
            dispatch(setSortOrganizations(CONSTANTS.ALL));
        }
    };
    return (
        <TabsWrapper className="aui-global-nav corporate-nav">
            <div className="aui-menu-tabs">
                <div className="aui-menu-tabs-container">
                    <div className="navbar-expand-lg">
                        <div className="d-flex flex-row-reverse aui-global-menubtn">
                            <button
                                type="button"
                                className="navbar-toggler btn-mainmenu-sm collapsed"
                                data-toggle="collapse"
                                data-target="#dashboardItems"
                                aria-controls="navbarDashboard"
                                aria-expanded="false"
                                aria-label="main menu navigation"
                            >
                                Menu
                                <i
                                    className="aha-icon-arrow-down"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                        <nav id="navbarDashboard">
                            <ul
                                id="dashboardItems"
                                className="collapse navbar-collapse aui-global-menu"
                            >
                                <li
                                    className={
                                        activeTab == "corporationDashboard"
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <Link
                                        to="/corporation/dashboard"
                                        className="btn btn-text"
                                        aria-current={
                                            activeTab == "corporationDashboard"
                                                ? "page"
                                                : false
                                        }
                                    >
                                        Dashboard
                                    </Link>
                                </li>
                                <li
                                    className={
                                        [
                                            "corporationPendingFacilties",
                                            "corporationFaciltyRegistered",
                                            "corporationRenewingFacilties",
                                            "corporationFaciltyCertified",
                                            "corporationDeactivatedFacilties",
                                        ].indexOf(activeTab) > -1
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <button
                                        type="button"
                                        className="btn btn-text dropdown-toggle flex-grow-1 text-left nav-link"
                                        id="facilityMenuDropdown"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        role="link"
                                    >
                                        Organization Management
                                    </button>
                                    {/* <div> */}
                                    <ul
                                        role="menu"
                                        aria-labelledby="facilityMenuDropdown"
                                        className="dropdown-menu p-lg-0 aui-header-sm-dropdown"
                                    >
                                        <li
                                            className={
                                                activeTab ==
                                                "corporationPendingFacilties"
                                                    ? "active"
                                                    : ""
                                            }
                                            role="none"
                                        >
                                            <Link
                                                to="/corporation/pending-approval"
                                                className="btn btn-text dropdown-item"
                                                role="menuitem"
                                                aria-current={
                                                    activeTab ==
                                                    "corporationPendingFacilties"
                                                        ? "page"
                                                        : false
                                                }
                                                onClick={() => {
                                                    clearFilteredThings();
                                                }}
                                            >
                                                Pending
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                activeTab ==
                                                "corporationFaciltyRegistered"
                                                    ? "active"
                                                    : ""
                                            }
                                            role="none"
                                        >
                                            <Link
                                                to="/corporation/registered-organization"
                                                className="btn btn-text dropdown-item"
                                                role="menuitem"
                                                aria-current={
                                                    activeTab ==
                                                    "corporationFaciltyRegistered"
                                                        ? "page"
                                                        : false
                                                }
                                                onClick={() => {
                                                    clearFilteredThings();
                                                }}
                                            >
                                                Registered
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                activeTab ==
                                                "corporationRenewingFacilties"
                                                    ? "active"
                                                    : ""
                                            }
                                            role="none"
                                        >
                                            <Link
                                                to="/corporation/renewed-organizations"
                                                className="btn btn-text dropdown-item"
                                                role="menuitem"
                                                aria-current={
                                                    activeTab ==
                                                    "corporationRenewingFacilties"
                                                        ? "page"
                                                        : false
                                                }
                                                onClick={() => {
                                                    clearFilteredThings();
                                                }}
                                            >
                                                Renewing
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                activeTab ==
                                                "corporationFaciltyCertified"
                                                    ? "active"
                                                    : ""
                                            }
                                            role="none"
                                        >
                                            <Link
                                                to="/corporation/certified-organization"
                                                className="btn btn-text dropdown-item"
                                                role="menuitem"
                                                aria-current={
                                                    activeTab ==
                                                    "corporationFaciltyCertified"
                                                        ? "page"
                                                        : false
                                                }
                                                onClick={() => {
                                                    clearFilteredThings();
                                                }}
                                            >
                                                Certified
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                activeTab ==
                                                "corporationDeactivatedFacilties"
                                                    ? "active"
                                                    : ""
                                            }
                                            role="none"
                                        >
                                            <Link
                                                to="/corporation/deactivated-organization"
                                                className="btn btn-text dropdown-item"
                                                role="menuitem"
                                                aria-current={
                                                    activeTab ==
                                                    "corporationDeactivatedFacilties"
                                                        ? "page"
                                                        : false
                                                }
                                                onClick={() => {
                                                    clearFilteredThings();
                                                }}
                                            >
                                                Deactivated
                                            </Link>
                                        </li>
                                    </ul>
                                    {/* </div> */}
                                </li>
                                <li
                                    className={
                                        activeTab ==
                                        "corporationBulkDocumentSubmission"
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <button
                                        type="button"
                                        className="btn btn-text dropdown-toggle flex-grow-1 text-left nav-link"
                                        id="bulkDocMenuDropdown"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        role="link"
                                    >
                                        Bulk Documents
                                    </button>
                                    <ul
                                        role="menu"
                                        className="dropdown-menu p-lg-0 aui-header-dropdown"
                                        aria-labelledby="bulkDocMenuDropdown"
                                    >
                                        <li
                                            className={
                                                activeTab ==
                                                "corporationBulkDocumentSubmission"
                                                    ? "active"
                                                    : ""
                                            }
                                            role="none"
                                        >
                                            <Link
                                                to="/corporation/bulk-document-submission"
                                                className="btn btn-text dropdown-item"
                                                role="menuitem"
                                                aria-current={
                                                    activeTab ==
                                                    "corporationBulkDocumentSubmission"
                                                        ? "page"
                                                        : false
                                                }
                                            >
                                                Document Submission
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </TabsWrapper>
    );
};

export default CorporationTabs;
