import styled from "styled-components";

export const HospitalManagementMearuresWrapper = styled.div`
    .measure-block-title {
        margin-bottom: 20px;
        .filter-item {
            width: 165px;
        }
    }
    .measure-filters {
        color: #000;
        .measure-filter-item {
            flex-grow: 1;
            > div {
                width: 165px;
            }
            @media only screen and (min-width: 768px) {
                flex-grow: unset;
            }
        }
        .measure-filter-item + .measure-filter-item {
            margin-left: 16px;
        }
        .cst-select {
            color: #000;
            border-radius: 4px;
            flex-grow: 1;
            @media only screen and (min-width: 768px) {
                width: 100%;
            }
        }
    }
    .hidden {
        display: none !important;
    }
    .admin-measure-table {
        .measure-table {
            thead {
                @media only screen and (min-width: 992px) {
                    th:nth-child(1) {
                        width: 12%;
                    }
                    th:nth-child(2) {
                        width: 17%;
                    }
                    th:nth-child(3) {
                        width: 30%;
                    }
                    th:nth-child(4) {
                        width: 16%;
                    }
                    th:nth-child(5) {
                        width: 16%;
                    }
                    th:nth-child(6) {
                        width: 9%;
                    }
                }
                @media only screen and (min-width: 1200px) {
                    th:nth-child(1) {
                        width: 15%;
                    }
                    th:nth-child(2) {
                        width: 19%;
                    }
                    th:nth-child(3) {
                        width: 29%;
                    }
                    th:nth-child(4) {
                        width: 13%;
                    }
                    th:nth-child(5) {
                        width: 15%;
                    }
                    th:nth-child(6) {
                        width: 9%;
                    }
                }
            }
        }
    }
`;
