import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import CertificationPage from "../Certification/CertificationPage";
import Measures from "../Measures/MeasuresPage";
import config from "../../config";
import { DetailsWrapper } from "./styled";

export const RegisteredEMSEUPDetails = () => {
    const [programsId, setProgramsId] = useState<any>();
    const [hideMeasure, setHideMeasure] = useState<boolean>(false);
    const location = useLocation();
    const [activateMeasuresTab, setActivateMeasuresTab] = useState(
        location?.state?.activateMeasuresTab || false
    );
    const [activeTab, setActiveTab] = useState(
        activateMeasuresTab ? "measuresNav" : "awardNav"
    );
    useEffect(() => {
        if (activateMeasuresTab) {
            setActiveTab("measuresNav");
        }
    }, [activateMeasuresTab]);

    useEffect(() => {
        setProgramsId(localStorage.getItem("selectedProgramId"));
        if (programsId == 16) {
            setHideMeasure(true);
        }
    }, [programsId]);
    return (
        <DetailsWrapper>
            <div className="navbar-expand-lg mt-1">
                <div className="d-flex flex-row-reverse aui-global-menubtn">
                    <button
                        type="button"
                        className="navbar-toggler btn-mainmenu-sm collapsed"
                        data-toggle="collapse"
                        data-target="#navbarList"
                        aria-controls="navbarDashboard"
                        aria-expanded="false"
                        aria-label="main menu navigation"
                    >
                        Menu
                        <i className="aha-icon-arrow-down" aria-hidden="true" />
                    </button>
                </div>
                <div className="aui-p-tabs">
                    <nav
                        id="navbarList"
                        className="collapse navbar-collapse tabs-nav"
                    >
                        <ul className="nav nav-tabs tab-menuview">
                            {!hideMeasure && (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${
                                            activeTab === "measuresNav"
                                                ? "active"
                                                : ""
                                        }`}
                                        data-toggle="tab"
                                        href="#measuresNav"
                                        onClick={() => {
                                            setActiveTab("measuresNav");
                                            setActivateMeasuresTab(true);
                                        }}
                                        aria-current={
                                            activeTab == "measuresNav"
                                                ? "page"
                                                : false
                                        }
                                    >
                                        Measures
                                    </a>
                                </li>
                            )}
                            {config &&
                            config[config.env].featureConfig &&
                            config[config.env].featureConfig.certification ==
                                "ON" ? (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${
                                            activeTab === "awardNav"
                                                ? "active"
                                                : ""
                                        }`}
                                        data-toggle="tab"
                                        href="#awardNav"
                                        onClick={() => {
                                            setActiveTab("awardNav");
                                            setActivateMeasuresTab(false);
                                        }}
                                        aria-current={
                                            activeTab == "awardNav"
                                                ? "page"
                                                : false
                                        }
                                    >
                                        Award
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    </nav>

                    <div className="tab-content">
                        <div
                            id="measuresNav"
                            className={`tab-pane ${
                                activeTab === "measuresNav" ? "active" : "fade"
                            } pt-3`}
                        >
                            <Measures />
                        </div>
                        <div
                            id="awardNav"
                            className={`tab-pane ${
                                activeTab === "awardNav" ? "active" : "fade"
                            } pt-3`}
                        >
                            <CertificationPage setActiveTab={setActiveTab} />
                        </div>
                    </div>
                </div>
            </div>
        </DetailsWrapper>
    );
};

export default RegisteredEMSEUPDetails;
