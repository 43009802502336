import CONSTANTS from "common/constants";
import preloadCharts, {
    getChartArialabel,
    getColorForSeries,
    getColumnsToShow,
    getFilteredChart,
    getMeasureMetricDashboardData,
    getOptions,
} from "common/googleChartUtils";
import { renderTableData, setMeasureCodeAndColorObject } from "common/utils";
import GroupedMultiSelectDropdown from "components/GroupedMultiSelectDropdown";
import React, { useEffect, useState } from "react";
import { GraphCardsWrapper } from "./styled";
import "styles/tooltip.scss";

interface Props {
    chartId: string;
    filterItems: any;
    measureMetricChart: any;
    title: string;
    interval: string;
    tooltips: any;
    programName: string;
    measureDropdownDetails?: any;
    metricDropdownDetails?: any;
    setMeasureDropdownDetails?: any;
    setMetricDropdownDetails?: any;
    benchmarkDetails?: any;
    benchmarkValue?: boolean;
    measureMetricChartDetails: any;
    setMeasureMetricChartDetails: any;
    dashboardType?: any;
    tableRenderInd?: any;
}

export const GraphCards = ({
    chartId,
    filterItems,
    measureMetricChart,
    title,
    interval,
    tooltips,
    programName,
    measureDropdownDetails,
    metricDropdownDetails,
    setMeasureDropdownDetails,
    setMetricDropdownDetails,
    benchmarkDetails,
    benchmarkValue,
    measureMetricChartDetails,
    setMeasureMetricChartDetails,
    dashboardType,
    tableRenderInd,
}: Props) => {
    const [chartType, setChartType] = useState<string>(
        CONSTANTS.CHART_TYPE.COLUMN
    );
    const [filterListSelected, setFilterListSelected] = useState<any>([]);
    const [allIdsList, setAllIdsList] = useState<any>([]);
    const [benchmarkInd, setBenchmarkInd] = useState<boolean>(false);
    const [chartURIData, setChartURIData] = useState<any>();
    const [hideMetricForAnnual, setHideMetricForAnnual] =
        useState<boolean>(false);
    const [selectedCode, setSelectedCode] = useState<any>([]);
    const [colorObject, setColorObject] = useState<any>();

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    useEffect(() => {
        if (dashboardType) {
            if (benchmarkInd && filterListSelected.length > 0) {
                preloadCharts(
                    redrawChartWithBenchmark,
                    filterListSelected,
                    chartType
                );
            } else if (!benchmarkInd && filterListSelected.length > 0) {
                preloadCharts(redrawChart, filterListSelected, chartType);
            }
        }
    }, [dashboardType]);

    useEffect(() => {
        if (measureMetricChartDetails) {
            const requiredChartIndex = measureMetricChartDetails.findIndex(
                (chartDetail: any) =>
                    chartDetail.groupName === measureMetricChart.groupName
            );
            setChartURIData(measureMetricChartDetails[requiredChartIndex]);
        }
    }, [measureMetricChartDetails]);

    useEffect(() => {
        setFilterListSelected([...filterItems]);
        setAllIdsList([...filterItems]);
        if (benchmarkValue !== undefined) {
            setBenchmarkInd(benchmarkValue);
        }
    }, [filterItems, benchmarkValue]);

    useEffect(() => {
        const { hideMetric, measureCode, colorCodeList } =
            setMeasureCodeAndColorObject(
                measureMetricChart?.filterLabel,
                interval,
                filterListSelected,
                allIdsList
            );
        setHideMetricForAnnual(hideMetric);
        setSelectedCode(measureCode);
        setColorObject(colorCodeList);
    }, [dashboardType, tableRenderInd, filterListSelected]);

    const setSelectedChart = (event: any) => {
        const requiredChartIndex = measureMetricChartDetails.findIndex(
            (chartDetail: any) =>
                chartDetail.groupName === measureMetricChart.groupName
        );
        if (filterListSelected.length) {
            if (!event.target.checked && benchmarkInd) {
                preloadCharts(
                    redrawChartWithBenchmark,
                    filterListSelected,
                    CONSTANTS.CHART_TYPE.COLUMN
                );
            } else {
                preloadCharts(
                    redrawChart,
                    filterListSelected,
                    event.target.checked
                        ? CONSTANTS.CHART_TYPE.LINE
                        : CONSTANTS.CHART_TYPE.COLUMN
                );
            }
        }
        if (event.target.checked) {
            setChartType(CONSTANTS.CHART_TYPE.LINE);
            measureMetricChartDetails[requiredChartIndex].lineChartType.URI =
                chartURIData.lineChartType.URI;
            measureMetricChartDetails[
                requiredChartIndex
            ].lineChartType.id = `line-${chartId}`;
            measureMetricChartDetails[requiredChartIndex].URI =
                chartURIData.lineChartType.URI;
            measureMetricChartDetails[requiredChartIndex].isBenchMark = false;
        } else {
            setChartType(CONSTANTS.CHART_TYPE.COLUMN);
            measureMetricChartDetails[requiredChartIndex].columnChartType.URI =
                chartURIData.columnChartType.URI;
            measureMetricChartDetails[
                requiredChartIndex
            ].id = `column-${chartId}`;
            measureMetricChartDetails[requiredChartIndex].URI =
                chartURIData.columnChartType.URI;
            measureMetricChartDetails[requiredChartIndex].isBenchMark =
                benchmarkInd == true;
        }
        setMeasureMetricChartDetails([...measureMetricChartDetails]);
    };

    const redrawChart = (
        google: any,
        filteredIds: any,
        selectedChartType: string
    ) => {
        if (measureMetricChart.noData) return;
        const newChart = getFilteredChart(measureMetricChart, filteredIds);
        const filteredChartData = getMeasureMetricDashboardData(
            google,
            newChart,
            tooltips,
            interval === CONSTANTS.INTERVAL.QUARTERLY ? "Quarter" : "Year"
        );
        const chartdata = getMeasureMetricDashboardData(
            google,
            measureMetricChart,
            tooltips,
            interval === CONSTANTS.INTERVAL.QUARTERLY ? "Quarter" : "Year"
        );
        const { columnsToShow, colorIndex } = getColumnsToShow(
            chartdata,
            filteredIds
        );
        const view = new google.visualization.DataView(chartdata);
        view.setColumns(columnsToShow);
        const allColors = getColorForSeries();
        const requiredColors = colorIndex.map((eachItem) => {
            return allColors[eachItem];
        });
        const currentUrl = window.location.href;
        const options = getOptions(
            title,
            measureMetricChart.metricType === "Number"
                ? "Value"
                : measureMetricChart.metricType,
            newChart.zeroValueInd ||
                measureMetricChart.filterLabel ===
                    CONSTANTS.CHART_ID_LABELS.MEASURE,
            requiredColors,
            currentUrl.includes("dashboard") ? 1135 : 982
        );
        const columnChart = new google.visualization.ColumnChart(
            document.getElementById(`column-${chartId}`)
        );

        const ids = `${filteredIds.map(
            (filteredItem: any) => filteredItem.label
        )}`;
        const selIds = allIdsList.filter(
            (selectedId: any) => ids.indexOf(selectedId.label) > -1
        );
        const chartURIs: any = {
            lineChartType: {},
            columnChartType: {},
            groupName: measureMetricChart.groupName,
            filteredCodes: selIds,
        };
        let columnCharObject: any = {};
        const requiredChartIndex = measureMetricChartDetails.findIndex(
            (chartDetail: any) =>
                chartDetail.groupName === measureMetricChart.groupName
        );
        measureMetricChartDetails[requiredChartIndex].filteredCodes = selIds;
        measureMetricChartDetails[requiredChartIndex].hideInd = false;
        measureMetricChartDetails[requiredChartIndex].isBenchMark = false;
        google.visualization.events.addListener(
            columnChart,
            "ready",
            function () {
                if (selectedChartType === CONSTANTS.CHART_TYPE.COLUMN) {
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].columnChartType.URI = columnChart.getImageURI();
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].columnChartType.id = `column-${chartId}`;
                    measureMetricChartDetails[requiredChartIndex].URI =
                        columnChart.getImageURI();
                    setMeasureMetricChartDetails([
                        ...measureMetricChartDetails,
                    ]);
                }
                const columnImageData: any = {};
                columnImageData.id = `column-${chartId}`;
                columnImageData.URI = columnChart.getImageURI();
                chartURIs.columnChartType = columnImageData;
                columnCharObject = columnImageData;
                setChartURIData({
                    ...chartURIs,
                    columnChartType: columnImageData,
                });
                getChartArialabel(
                    measureMetricChart.filterLabel,
                    programName,
                    title,
                    filteredChartData,
                    interval,
                    measureMetricChart.metricType,
                    columnChart,
                    CONSTANTS.CHART_TYPE.COLUMN
                );
            }
        );
        const lineChart = new google.visualization.LineChart(
            document.getElementById(`line-${chartId}`)
        );
        google.visualization.events.addListener(
            lineChart,
            "ready",
            function () {
                if (selectedChartType === CONSTANTS.CHART_TYPE.LINE) {
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].lineChartType.URI = lineChart.getImageURI();
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].columnChartType = columnCharObject;
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].lineChartType.id = `line-${chartId}`;
                    measureMetricChartDetails[requiredChartIndex].URI =
                        lineChart.getImageURI();
                    setMeasureMetricChartDetails([
                        ...measureMetricChartDetails,
                    ]);
                }
                const lineImageData: any = {};
                lineImageData.id = `line-${chartId}`;
                lineImageData.URI = lineChart.getImageURI();
                chartURIs.lineChartType = lineImageData;
                setChartURIData({ ...chartURIs, lineChartType: lineImageData });
                getChartArialabel(
                    measureMetricChart.filterLabel,
                    programName,
                    title,
                    filteredChartData,
                    interval,
                    measureMetricChart.metricType,
                    lineChart,
                    CONSTANTS.CHART_TYPE.LINE
                );
            }
        );
        columnChart.draw(view, options);
        lineChart.draw(view, options);
    };

    const setDisableIndForIds = (value: any) => {
        setFilterListSelected([...value]);
        if (value.length) {
            if (benchmarkInd) {
                preloadCharts(redrawChartWithBenchmark, value, chartType);
            } else {
                preloadCharts(redrawChart, value, chartType);
            }
        } else {
            setBenchmarkInd(false);
            const requiredChartIndex = measureMetricChartDetails.findIndex(
                (chartDetail: any) =>
                    chartDetail.groupName === measureMetricChart.groupName
            );
            measureMetricChartDetails[requiredChartIndex].hideInd = true;
            measureMetricChartDetails[requiredChartIndex].isBenchMark = false;
            setMeasureMetricChartDetails([...measureMetricChartDetails]);
            const columnChartDiv: any = document.getElementById(
                `column-${chartId}`
            );
            const lineChartDiv: any = document.getElementById(
                `line-${chartId}`
            );
            const message = `Please select at least one ${measureMetricChart.filterLabel
                ?.split(" ")[0]
                ?.charAt(0)
                ?.toLowerCase()}${measureMetricChart.filterLabel
                ?.split(" ")[0]
                ?.slice(1)} to display on the dashboard.`;
            const htmlMessage = `<div class="graph-emptystate-container d-flex flex-column justify-content-center"><div class="graph-emptystate-img"><img src="/images/graph-emptystate.png" alt="no data" /></div><p>${message}</p></div>`;
            columnChartDiv.innerHTML = htmlMessage;
            lineChartDiv.innerHTML = htmlMessage;
        }
        if (measureDropdownDetails) {
            const data = measureDropdownDetails.map((item: any) => {
                if (measureMetricChart.groupName === item.groupName) {
                    const allIdList = [
                        ...allIdsList.map((item: any) => item.value),
                    ];
                    const values = [
                        ...value.map((item: any) =>
                            item.value != "All" ? item.value : null
                        ),
                    ];
                    const dropDownId = allIdList.filter((item: any) =>
                        values.includes(item)
                    );
                    item.code = [...dropDownId];
                }
                return item;
            });
            setMeasureDropdownDetails(data);
        } else {
            const data = metricDropdownDetails.map((item: any) => {
                if (measureMetricChart.groupName === item.groupName) {
                    const allIdList = [
                        ...allIdsList.map((item: any) => item.value),
                    ];
                    const values = [
                        ...value.map((item: any) =>
                            item.value != "All" ? item.value : null
                        ),
                    ];
                    const dropDownId = allIdList.filter((item: any) =>
                        values.includes(item)
                    );
                    item.code = [...dropDownId];
                }
                return item;
            });
            setMetricDropdownDetails(data);
        }
    };

    const getAriaLabel = () => {
        const ids = `${filterListSelected
            .filter((item: any) => item.label !== "All")
            .map((filteredItem: any) => filteredItem.label)}`;
        const filterIds = allIdsList.filter(
            (selectedId: any) => ids.indexOf(selectedId.label) > -1
        );
        return filterIds.map((filterItem: any) => ` ${filterItem.label}`);
    };

    const redrawChartWithBenchmark = (
        google: any,
        filteredIds: any,
        selectedChartType: string
    ) => {
        const newChart = getFilteredChart(measureMetricChart, filteredIds);
        const filteredBenchmarkChart = getFilteredChart(
            benchmarkDetails,
            filteredIds
        );
        const chartdata = getMeasureMetricDashboardData(
            google,
            measureMetricChart,
            tooltips,
            interval === CONSTANTS.INTERVAL.QUARTERLY ? "Quarter" : "Year",
            true
        );
        const benchMarkChartdata = getMeasureMetricDashboardData(
            google,
            benchmarkDetails,
            tooltips,
            interval === CONSTANTS.INTERVAL.QUARTERLY ? "Quarter" : "Year",
            true
        );
        const allColors = getColorForSeries();
        const columnChart = new google.visualization.ColumnChart(
            document.getElementById(`column-${chartId}`)
        );
        const ids = `${filteredIds.map(
            (filteredItem: any) => filteredItem.label
        )}`;
        const selIds = allIdsList.filter(
            (selectedId: any) => ids.indexOf(selectedId.label) > -1
        );
        const measureCodeAll = selIds.map((codeObj: any) => codeObj.label);
        const measureCode = measureCodeAll.filter(
            (code: any) => code !== "All"
        );
        setSelectedCode(measureCode);
        const diffData = columnChart.computeDiff(benchMarkChartdata, chartdata);
        const { columnsToShow, colorIndex } = getColumnsToShow(
            diffData,
            filteredIds,
            true
        );
        const view = new google.visualization.DataView(diffData);
        view.setColumns(columnsToShow);

        const requiredColors = colorIndex.map((eachItem) => {
            return allColors[eachItem];
        });
        const currentUrl = window.location.href;
        const options = getOptions(
            title,
            measureMetricChart.metricType === "Number"
                ? "Value"
                : measureMetricChart.metricType,
            (newChart.zeroValueInd && filteredBenchmarkChart.zeroValueInd) ||
                measureMetricChart.filterLabel ===
                    CONSTANTS.CHART_ID_LABELS.MEASURE,
            requiredColors,
            currentUrl.includes("dashboard") ? 1135 : 982
        );
        const chartURIs: any = {
            lineChartType: {},
            columnChartType: {},
            groupName: measureMetricChart.groupName,
            filteredCodes: selIds,
        };
        let columnCharObject: any = {};
        const requiredChartIndex = measureMetricChartDetails.findIndex(
            (chartDetail: any) =>
                chartDetail.groupName === measureMetricChart.groupName
        );
        measureMetricChartDetails[requiredChartIndex].filteredCodes = selIds;
        measureMetricChartDetails[requiredChartIndex].hideInd = false;
        measureMetricChartDetails[requiredChartIndex].isBenchMark =
            selectedChartType == CONSTANTS.CHART_TYPE.COLUMN;
        google.visualization.events.addListener(
            columnChart,
            "ready",
            function () {
                const allGs = document.getElementsByTagName("g");
                for (let i = 0; i < allGs.length; i++) {
                    if (allGs[i]?.children[0]?.innerHTML === "Previous data") {
                        allGs[i].children[0].innerHTML = "Benchmark";
                    }
                }
                if (selectedChartType === CONSTANTS.CHART_TYPE.COLUMN) {
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].columnChartType.URI = columnChart.getImageURI();
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].columnChartType.id = `column-${chartId}`;
                    measureMetricChartDetails[requiredChartIndex].URI =
                        columnChart.getImageURI();
                    setMeasureMetricChartDetails([
                        ...measureMetricChartDetails,
                    ]);
                }
                const columnImageData: any = {};
                columnImageData.id = `column-${chartId}`;
                columnImageData.URI = columnChart.getImageURI();
                chartURIs.columnChartType = columnImageData;
                columnCharObject = columnImageData;
                setChartURIData({
                    ...chartURIs,
                    columnChartType: columnImageData,
                });
                getChartArialabel(
                    measureMetricChart.filterLabel,
                    programName,
                    title,
                    view,
                    interval,
                    measureMetricChart.metricType,
                    columnChart,
                    CONSTANTS.CHART_TYPE.COLUMN,
                    true
                );
            }
        );
        columnChart.draw(view, options);
        const linechartdata = getMeasureMetricDashboardData(
            google,
            measureMetricChart,
            tooltips,
            interval === CONSTANTS.INTERVAL.QUARTERLY ? "Quarter" : "Year"
        );
        const lineColorsAndValues = getColumnsToShow(
            linechartdata,
            filteredIds
        );
        const lineview = new google.visualization.DataView(linechartdata);
        lineview.setColumns(lineColorsAndValues.columnsToShow);
        const requiredColorsForLine = lineColorsAndValues.colorIndex.map(
            (eachItem) => {
                return allColors[eachItem];
            }
        );

        const lineOptions = getOptions(
            title,
            measureMetricChart.metricType === "Number"
                ? "Value"
                : measureMetricChart.metricType,
            newChart.zeroValueInd ||
                measureMetricChart.filterLabel ===
                    CONSTANTS.CHART_ID_LABELS.MEASURE,
            requiredColorsForLine,
            currentUrl.includes("dashboard") ? 1135 : 982
        );
        const filteredChartData = getMeasureMetricDashboardData(
            google,
            newChart,
            tooltips,
            interval === CONSTANTS.INTERVAL.QUARTERLY ? "Quarter" : "Year"
        );
        const lineChart = new google.visualization.LineChart(
            document.getElementById(`line-${chartId}`)
        );
        google.visualization.events.addListener(
            lineChart,
            "ready",
            function () {
                if (selectedChartType === CONSTANTS.CHART_TYPE.LINE) {
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].lineChartType.URI = lineChart.getImageURI();
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].columnChartType = columnCharObject;
                    measureMetricChartDetails[
                        requiredChartIndex
                    ].lineChartType.id = `line-${chartId}`;
                    measureMetricChartDetails[requiredChartIndex].URI =
                        lineChart.getImageURI();
                    setMeasureMetricChartDetails([
                        ...measureMetricChartDetails,
                    ]);
                }
                const lineImageData: any = {};
                lineImageData.id = `line-${chartId}`;
                lineImageData.URI = lineChart.getImageURI();
                chartURIs.lineChartType = lineImageData;
                setChartURIData({ ...chartURIs, lineChartType: lineImageData });
                getChartArialabel(
                    measureMetricChart.filterLabel,
                    programName,
                    title,
                    filteredChartData,
                    interval,
                    measureMetricChart.metricType,
                    lineChart,
                    CONSTANTS.CHART_TYPE.LINE
                );
            }
        );
        lineChart.draw(lineview, lineOptions);
    };

    const viewBenchmark = (event: any) => {
        setBenchmarkInd(event.target.checked);
        if (event.target.checked) {
            preloadCharts(
                redrawChartWithBenchmark,
                filterListSelected,
                chartType
            );
        } else {
            preloadCharts(redrawChart, filterListSelected, chartType);
        }
    };

    return (
        <GraphCardsWrapper
            role="group"
            aria-labelledby={`chart-group-label-${chartId}`}
        >
            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                <h3
                    className="h7 graph-sub-heading"
                    id={`chart-group-label-${chartId}`}
                >
                    {measureMetricChart.groupName}
                </h3>
                {!measureMetricChart.filterNoShowInd && (
                    <div className="d-flex align-items-end justify-content-between">
                        <div className="measureid-dropdown">
                            <label
                                id={`dropdown-label-chartMeasureMetricId-${chartId}`}
                            >
                                {measureMetricChart.filterLabel}
                            </label>
                            <GroupedMultiSelectDropdown
                                hasAllOption
                                items={allIdsList}
                                selectedValue={filterListSelected}
                                callParentOnSelect={(value: any) => {
                                    setDisableIndForIds(value);
                                }}
                                id={`chartMeasureMetricId-${chartId}`}
                            />
                        </div>
                        {dashboardType === CONSTANTS.DASHBOARD_TYPE.CHART && (
                            <label
                                className="graph-toggle-switch mb-0 ml-4"
                                htmlFor={`chart-input-${chartId}`}
                            >
                                <input
                                    type="checkbox"
                                    className="graph-toggle-input"
                                    onChange={setSelectedChart}
                                    checked={
                                        chartType === CONSTANTS.CHART_TYPE.LINE
                                    }
                                    id={`chart-input-${chartId}`}
                                    role="button"
                                    aria-pressed={
                                        chartType === CONSTANTS.CHART_TYPE.LINE
                                    }
                                />
                                <span className="graph-toggle-slider">
                                    <span className="toggle-graph-icon1 toggle-graph-icon">
                                        <i className="aha-icon-column-chart" />
                                    </span>
                                    <span className="toggle-graph-icon2 toggle-graph-icon">
                                        <i className="aha-icon-line-chart" />
                                    </span>
                                </span>
                                <span
                                    className="tooltip-div"
                                    aria-describedby={`chart-tooltip-${chartId}`}
                                    aria-hidden="true"
                                >
                                    <>
                                        {chartType ===
                                        CONSTANTS.CHART_TYPE.COLUMN
                                            ? "Column Chart - Click to display line chart"
                                            : "Line Chart - Click to display column chart"}
                                    </>
                                </span>
                                <span
                                    id={`chart-tooltip-${chartId}`}
                                    className="sr-only"
                                >
                                    {chartType === CONSTANTS.CHART_TYPE.COLUMN
                                        ? "Column Chart - Click to display line chart"
                                        : "Line Chart - Click to display column chart"}
                                </span>
                            </label>
                        )}
                    </div>
                )}
            </div>
            <div
                id={`container-${chartId}`}
                className={
                    dashboardType == CONSTANTS.DASHBOARD_TYPE.CHART ||
                    !filterListSelected.length ||
                    hideMetricForAnnual ||
                    measureMetricChart.noData
                        ? "graph-container text-center"
                        : "visuallyhidden"
                }
                aria-hidden={
                    !(
                        dashboardType == CONSTANTS.DASHBOARD_TYPE.CHART ||
                        !filterListSelected.length ||
                        hideMetricForAnnual
                    )
                }
            >
                {chartType === CONSTANTS.CHART_TYPE.COLUMN &&
                    filterListSelected.length > 0 &&
                    !measureMetricChart.hideBenchMarkInd && (
                        <div
                            className="benchmark d-flex align-items-start"
                            id={`bm-${chartId}`}
                        >
                            <div
                                className="form-group form-check mb-0"
                                id={`chart-${chartId}`}
                            >
                                <input
                                    type="checkbox"
                                    id={`check-${chartId}`}
                                    onChange={viewBenchmark}
                                    checked={benchmarkInd}
                                />
                                <label htmlFor={`check-${chartId}`}>
                                    View Benchmark
                                </label>
                            </div>
                            <div className="cst-tooltip">
                                <button
                                    type="button"
                                    className="aha-icon-help font-red benchmark-icon tooltip-item"
                                    aria-describedby={`helpbm-${chartId}`}
                                >
                                    <span className="sr-only">
                                        What is benckmark
                                    </span>
                                </button>
                                <div
                                    className="tooltip tooltip-bottom bm-help"
                                    role="tooltip"
                                    id={`helpbm-${chartId}`}
                                >
                                    <div className="arrow" />
                                    <div className="tooltip-inner">
                                        Value for all organizations that have
                                        contributed data to this measure over
                                        the selected time period.
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                <div
                    id={`column-${chartId}`}
                    className={`dashboard-chart ${
                        chartType !== CONSTANTS.CHART_TYPE.COLUMN
                            ? "visuallyhidden"
                            : ""
                    }`}
                    aria-hidden={
                        chartType !== CONSTANTS.CHART_TYPE.COLUMN
                            ? "true"
                            : "false"
                    }
                    role="img"
                    aria-label={`Column Chart for ${measureMetricChart.groupName}`}
                />
                <div
                    id={`line-${chartId}`}
                    className={`dashboard-chart ${
                        chartType !== CONSTANTS.CHART_TYPE.LINE
                            ? "visuallyhidden"
                            : ""
                    }`}
                    aria-hidden={
                        chartType !== CONSTANTS.CHART_TYPE.LINE
                            ? "true"
                            : "false"
                    }
                    role="img"
                    aria-label={`Line Chart for ${measureMetricChart.groupName}`}
                />
            </div>
            <div
                id={`dashBoardTable-${chartId}`}
                className={
                    dashboardType == CONSTANTS.DASHBOARD_TYPE.TABLE &&
                    filterListSelected.length > 0 &&
                    !hideMetricForAnnual &&
                    !measureMetricChart.noData
                        ? "mb-4 dashboard-chart-table"
                        : "d-none"
                }
            >
                <div className="d-flex justify-content-between flex-lg-row flex-column">
                    <div className="d-flex flex-column flex-sm-row">
                        <h4 className="mr-2 mr-lg-4 h8">{title}</h4>
                        {chartType === CONSTANTS.CHART_TYPE.COLUMN &&
                            filterListSelected.length > 0 &&
                            !measureMetricChart.hideBenchMarkInd && (
                                <div
                                    className="benchmark d-flex align-items-start ml-sm-2"
                                    id={`bmv-${chartId}`}
                                >
                                    <div
                                        className="form-group form-check mb-0"
                                        id={`chart-${chartId}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id={`check-${chartId}`}
                                            onChange={viewBenchmark}
                                            checked={benchmarkInd}
                                        />
                                        <label htmlFor={`check-${chartId}`}>
                                            View Benchmark
                                        </label>
                                    </div>
                                    <button
                                        type="button"
                                        className="aha-icon-help font-red benchmark-icon"
                                        data-toggle="tooltip"
                                        data-trigger="focus hover"
                                        tabIndex={0}
                                        title="Value for all organizations that have contributed data to this measure over the selected time period."
                                        data-container={`#bmv-${chartId}`}
                                    >
                                        <span className="sr-only">
                                            What is benckmark
                                        </span>
                                    </button>
                                </div>
                            )}
                    </div>
                    {measureMetricChart?.filterLabel == "Measure ID" &&
                        (!benchmarkInd ||
                            chartType === CONSTANTS.CHART_TYPE.LINE) && (
                            <div
                                role="note"
                                id={`tableLabel-${chartId}`}
                                className="d-flex justify-content-lg-end dashboard-chart-legend flex-wrap mt-2 mt-md-0 mb-2 mb-lg-0"
                            >
                                <span>N (Numerator)</span>
                                ,&nbsp;<span>D (Denominator)</span>
                                ,&nbsp;<span>P (Percentage)</span>
                            </div>
                        )}
                    {measureMetricChart?.filterLabel == "Measure ID" &&
                        benchmarkInd &&
                        chartType === CONSTANTS.CHART_TYPE.COLUMN && (
                            <div
                                role="note"
                                id={`tableLabel-${chartId}`}
                                className="d-flex justify-content-sm-end dashboard-chart-legend flex-wrap ml-2"
                            >
                                <span>N (Numerator)</span>
                                ,&nbsp;<span>D (Denominator)</span>
                                ,&nbsp;<span>P (Percentage)</span>
                                ,&nbsp;<span>BM (Benchmark)</span>
                            </div>
                        )}
                </div>
                <table className="aui-responsive-status-table position-relative dashboard-table">
                    <thead id={`tableHeader-${chartId}`}>
                        <tr>
                            <th scope="col">
                                <div className="aui-th">Period</div>
                            </th>
                            {selectedCode.length > 0 &&
                                selectedCode.map((code: any) => {
                                    return (
                                        <th scope="col">
                                            <div className="aui-th flex-wrap">
                                                <div
                                                    className="legend-symbol"
                                                    style={{
                                                        backgroundColor:
                                                            colorObject[code],
                                                    }}
                                                />
                                                <div>
                                                    <span className="sr-only">
                                                        {
                                                            measureMetricChart.filterLabel
                                                        }
                                                    </span>
                                                    {code}
                                                </div>
                                            </div>
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    <tbody id={`tableBody-${chartId}`}>
                        {measureMetricChart.quarterOrAnnualValues.length &&
                            measureMetricChart.quarterOrAnnualValues.map(
                                (data: any, index: any) => {
                                    const measureValue =
                                        data.measureMetricValues;
                                    const benchMarkDetail = benchmarkDetails
                                        ?.quarterOrAnnualValues[index]
                                        ?.measureMetricValues
                                        ? benchmarkDetails
                                              ?.quarterOrAnnualValues[index]
                                              ?.measureMetricValues
                                        : [];
                                    return (
                                        <tr className="aui-table-status-normal">
                                            <td data-title="Period">
                                                <div className="aui-td align-items-start">
                                                    {data.quarterOrYear}
                                                </div>
                                            </td>
                                            {selectedCode.map((row: any) => {
                                                const annualValueIndex =
                                                    measureValue.findIndex(
                                                        (annual: any) =>
                                                            row ===
                                                            annual.measureMetricId
                                                    );
                                                const benchMarkIndex =
                                                    benchMarkDetail.findIndex(
                                                        (benchMark: any) =>
                                                            row ===
                                                            benchMark.measureMetricId
                                                    );

                                                const {
                                                    numerator,
                                                    denominator,
                                                    value,
                                                    benchMarkValue,
                                                } = renderTableData(
                                                    measureValue,
                                                    annualValueIndex,
                                                    measureMetricChart?.filterLabel,
                                                    measureMetricChart.metricType,
                                                    interval,
                                                    {
                                                        benchmarkInd,
                                                        benchMarkDetail,
                                                        benchMarkIndex,
                                                    }
                                                );
                                                return (
                                                    <td data-title={row}>
                                                        <div className="aui-td">
                                                            <div className="d-flex flex-row flex-lg-column">
                                                                {measureMetricChart?.filterLabel ==
                                                                    "Measure ID" &&
                                                                    numerator && (
                                                                        <div>
                                                                            <span className="d-inline-block">
                                                                                {measureMetricChart?.filterLabel ==
                                                                                    "Measure ID" &&
                                                                                    numerator && (
                                                                                        <span className="font-600">
                                                                                            {" "}
                                                                                            N:{" "}
                                                                                        </span>
                                                                                    )}
                                                                                {
                                                                                    numerator
                                                                                }
                                                                            </span>
                                                                            &nbsp;&nbsp;
                                                                            <span className="sr-only">
                                                                                and
                                                                            </span>
                                                                            <span className="d-inline-block ml-2 ml-lg-0">
                                                                                {measureMetricChart?.filterLabel ==
                                                                                    "Measure ID" &&
                                                                                    denominator && (
                                                                                        <span className="font-600">
                                                                                            {" "}
                                                                                            D:{" "}
                                                                                        </span>
                                                                                    )}
                                                                                {
                                                                                    denominator
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                <div className="ml-3 ml-lg-0">
                                                                    {measureMetricChart?.filterLabel ==
                                                                        "Measure ID" &&
                                                                        value && (
                                                                            <span className="font-600">
                                                                                {" "}
                                                                                P:{" "}
                                                                            </span>
                                                                        )}
                                                                    {value}
                                                                </div>
                                                                {benchmarkInd &&
                                                                    chartType ==
                                                                        CONSTANTS
                                                                            .CHART_TYPE
                                                                            .COLUMN && (
                                                                        <div>
                                                                            {benchMarkValue && (
                                                                                <span className="font-600">
                                                                                    {" "}
                                                                                    BM:{" "}
                                                                                </span>
                                                                            )}
                                                                            {
                                                                                benchMarkValue
                                                                            }
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                }
                            )}
                    </tbody>
                </table>
            </div>
        </GraphCardsWrapper>
    );
};

export default GraphCards;
