import CONSTANTS from "common/constants";
import UploadDocument from "components/DocumentSubmission/UploadDocument";
import Dropdown from "components/Dropdown";
import Pagination, { IPagination } from "components/Pagination";
import SelectAllCheckbox from "components/SelectAllCheckbox";
import TableRowCount from "components/TableRowCount";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SmartSearch from "components/SmartSearch";
import DocumentServiceAPI from "api/documentsAPI";
import { useDispatch, useSelector } from "react-redux";
import SortTableColumnButton from "components/SortTableColumnButton";
import SortTableColumnDropdown from "components/SortTableColumnDropdown";
import FocusTrap from "focus-trap-react";
import SearchDropdown from "components/SearchDropdown";
import { setSortOrganizations } from "components/SearchFilter/searchFilter.slice";
import {
    getFacilitiesForBulkUpload,
    getCorporationDetailsById,
} from "../../../api/corporationAPI";
import { parseJwt, wordSplit, showModal } from "../../../common/utils";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import { BulkDocumentSubmissionWrapper } from "./Styled";
import { IUploadDocForm } from "../../../components/DocumentSubmission/UploadDocumentModalContent";
import { getCategoriesForPrograms } from "../../../api/programs";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import config from "../../../config";

const documentServiceAPI = new DocumentServiceAPI();

const CorporationBulkDocumentSubmission = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [isTableLoading, setIstableLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(
        location?.state?.pageNumberFilter ? location.state.pageNumberFilter : 1
    );
    const [facilities, setFacilities] = useState<any>([]);
    const [allFacilities, setAllFacilities] = useState<any>([]);
    const [allFacilitiesWithoutFilter, setAllFacilitiesWithoutFilter] =
        useState<any>([]);
    const [selection, setSelection] = useState<any>(
        location?.state?.userSelection ? location.state.userSelection : {}
    );
    const [sortInd, setSortInd] = useState<any>(
        location?.state?.sortingInd ? location.state.sortingInd : undefined
    );
    const [selectedProgram, setSelectedProgram] = useState<any>([]);
    const [program, setProgram] = useState<any>(
        location?.state?.programFilter
            ? location.state.programFilter
            : undefined
    );
    const [previousDispatch, setPreviousDispatch] = useState<any>(null);
    const [previousSortInd, setPreviousSortInd] = useState<any>(null);
    const [status, setStatus] = useState<any>(
        location?.state?.typeFilter ? location.state.typeFilter : "All"
    );
    const [searchKey, setSearchKey] = useState<string>(
        location?.state?.searchFilter ? location.state.searchFilter : ""
    ); // todo
    const [mounted, setMounted] = useState<boolean>(false);
    const [count, setCount] = useState<number>(
        location?.state?.selectCount ? location.state.selectCount : 0
    );
    const [facilityIds, setFacilityIds] = useState<any>(
        location?.state?.selectedFacilityIds
            ? location.state.selectedFacilityIds
            : []
    );
    const [selectAllOption, setSelectAllOption] = useState<boolean>(false);
    const [selectCurrentOption, setSelectCurrentOption] =
        useState<boolean>(false);
    const [statusDropdown, setStatusDropdown] = useState(
        CONSTANTS.STATUS_DROPDOWN_VALUES
    );
    const [selectAll, setSelectAll] = useState<any>(
        location?.state?.userSelectAll ? location.state.userSelectAll : {}
    );
    const [uploadProgram, setUploadProgram] = useState<any>({
        programCategoryName: "",
    });
    const [standards, setStandards] = useState<any>([]);
    const [modalPageNumber, setModalPageNumber] = useState<number>(1);
    const [selectedFacilitiesModal, setSelectedFacilitiesModal] = useState<any>(
        {}
    );
    const [allSelectedFacilityList, setAllSelectedFacilityList] = useState<any>(
        []
    );
    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<any>();
    const [sortIndModal, setSortIndModal] = useState<any>();
    const [selectedTotalCount, setSelectedTotalCount] = useState<number>(0);
    const modalPageSize: number = 25;
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: location?.state?.pageNumberFilter
            ? location.state.pageNumberFilter
            : 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [modalPagination, setModalPagination] = useState<any>({});
    const [roleValue, setRoleValue] = useState<string>("none");
    const [modalRole, setModalRole] = useState<string>("none");
    const [uploadDocumentInd, setuploadDocumentInd] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLButtonElement>(null);
    const [programsData, setProgramsData] = useState<any>([]);
    const corporationId = parseJwt(
        window.localStorage.getItem("userAccessToken")
    ).corporation_id;

    const initUploadDocForm: IUploadDocForm = {
        title: "",
        programId: "",
        programName: "",
        uploadStandard: "###", // ### is sperating the standardId & SandardName
        uploadDescription: "",
        file: null,
        facilityMessage: "",
        fileUploadError: false,
        setFileUploadSuccess: false,
    };
    const [uploadDocForm, setUploadDocForm] =
        useState<IUploadDocForm>(initUploadDocForm);
    const [selectedFacilitiesActivateInd, setSelectedFacilitiesActivateInd] =
        useState<boolean>(false);
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const sortIndValue = (val: any) => {
        setSortInd(val);
    };
    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        localStorage.setItem("clearFilter", "true");
        if (!mounted) {
            getCorporationDetailsById(corporationId)
                .then((corporationResponse: any) => {
                    if (corporationResponse.success) {
                        const selectedProgramsMap: any = [];
                        corporationResponse.data.corporationDetails.corporationProgramDetails.map(
                            (programs: any) => {
                                selectedProgramsMap.push(programs.programId);
                            }
                        );
                        const selectedcategoriesMap: any = [];
                        getCategoriesForPrograms(selectedProgramsMap).then(
                            (categoriesResponse: any) => {
                                if (categoriesResponse.success) {
                                    setProgramsData(
                                        categoriesResponse.data.categories
                                    );
                                    categoriesResponse.data.categories.map(
                                        (category: any) => {
                                            selectedcategoriesMap.push({
                                                label: category.categoryName,
                                                value: category.categoryId,
                                                programId: category.programId,
                                            });
                                        }
                                    );
                                    if (!program) {
                                        setProgram(
                                            selectedcategoriesMap[0].value
                                        );
                                    }
                                    const programValue =
                                        program ||
                                        selectedcategoriesMap[0].value;
                                    setSelectedProgram(selectedcategoriesMap);
                                    getSearchedFacilities(
                                        programValue,
                                        searchKey,
                                        status,
                                        pageNumber
                                    );
                                    dispatch(
                                        setPageLoadingStatus({
                                            isPageLoading: false,
                                        })
                                    );
                                    setMounted(true);
                                }
                            }
                        );
                    }
                })
                .catch((error: any) => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    setIstableLoading(false);
                });
        } else {
            if (selectedFacilitiesActivateInd) {
                getPopUpSortedFacilityList();
            } else {
                getSearchedFacilities(program, searchKey, status, pageNumber);
            }
            window.scrollTo(0, 150);
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    }, [pageNumber, sortInd]);
    useEffect(() => {
        const updatedDropdown = [...CONSTANTS.STATUS_DROPDOWN_VALUES];
        let selectedProgramObject: any;
        if (program) {
            selectedProgramObject = programsData?.find(
                (obj: { categoryId: any }) => obj.categoryId === program
            );
            if (!selectedProgramObject) {
                selectedProgramObject = programsData?.find(
                    (item: any) =>
                        item.categoryId === location?.state?.programFilter
                );
            }
            // Determine which status option to add
            const statusOption = selectedProgramObject?.isQuality
                ? CONSTANTS.AWARDED_STATUS_OPTION
                : CONSTANTS.CERTIFIED_STATUS_OPTION;
            updatedDropdown.push(statusOption ?? []);
        }
        setStatusDropdown(updatedDropdown);
    }, [program, programsData]);

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    useEffect(() => {
        getOnlySelectedFacilities(modalPageNumber, sortIndModal);
    }, [modalPageNumber, sortIndModal]);

    const history = useHistory();
    useEffect(() => {
        window.onbeforeunload = function () {
            history.replace();
            return undefined;
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const getCheckboxStatus = (
        facilitiesListed: any,
        selectedfacilityIds: any
    ) => {
        if (facilitiesListed.length > 0) {
            const allFacilityIds = selectedfacilityIds.map(
                (facility: any) => facility.hospitalId
            );
            const currentPageFacilities = facilitiesListed.filter(
                (facility: any) =>
                    allFacilityIds.indexOf(facility.hospitalId) > -1
            );
            if (currentPageFacilities.length === 0) {
                return false;
            }
            if (currentPageFacilities.length === facilitiesListed.length) {
                return true;
            }
            if (currentPageFacilities.length > 0) {
                return "intermediate";
            }
        } else {
            return false;
        }
    };

    const getAllFacilitiesAvailable = (
        categoryId: any,
        facilityStatus: any,
        searchKeyword: any
    ) => {
        getFacilitiesForBulkUpload(
            corporationId,
            0,
            0,
            facilityStatus,
            sortInd,
            categoryId,
            searchKeyword
        ).then(async (response: any) => {
            if (response.success) {
                let allFacilityDetails = [];
                if (facilityStatus === CONSTANTS.CERTIFIED) {
                    allFacilityDetails = response.data.certifiedFacilityDetails;
                } else {
                    allFacilityDetails = response.data.facilityDetails;
                }
                setAllFacilities(allFacilityDetails);
            }
        });
        getFacilitiesForBulkUpload(
            corporationId,
            0,
            0,
            "",
            sortInd,
            categoryId,
            ""
        ).then(async (response: any) => {
            if (response.success) {
                setAllFacilitiesWithoutFilter(response.data.facilityDetails);
            }
        });
    };
    const getPopUpSortedFacilityList = () => {
        if (searchFilter.sortOrganizations === CONSTANTS.ASCENDING) {
            setSortIndModal(true);
        } else if (searchFilter.sortOrganizations === CONSTANTS.DESCENDING) {
            setSortIndModal(false);
        } else {
            setSortIndModal(undefined);
        }
        setIstableLoading(false);
    };

    const getSearchedFacilities = (
        categoryId: any,
        searchKeyword: any,
        facilityStatus: any,
        pageNum: number,
        clearInd: boolean = false
    ) => {
        getFacilitiesForBulkUpload(
            corporationId,
            pageNum,
            pagination.pageSize,
            facilityStatus,
            sortInd,
            categoryId,
            searchKeyword
        )
            .then(async (response: any) => {
                if (response.success) {
                    const allStandards =
                        await documentServiceAPI.getStandardsByProgramId(
                            categoryId
                        );
                    let facilityDetails = [];
                    if (
                        facilityStatus === CONSTANTS.CERTIFIED ||
                        facilityStatus === CONSTANTS.AWARDED
                    ) {
                        facilityDetails =
                            response.data.certifiedFacilityDetails;
                    } else {
                        facilityDetails = response.data.facilityDetails;
                    }
                    if (facilityDetails[0]) {
                        setUploadProgram({
                            programCategoryName:
                                facilityDetails[0].programCategoryName,
                            programId: facilityDetails[0].programId,
                            programCategoryId:
                                facilityDetails[0].programCategoryId,
                        });
                    } else {
                        setUploadProgram({});
                    }
                    const uploadStandardsArray: any = [];
                    allStandards.data.standards.map((standardItem: any) => {
                        if (!standardItem.isAdmin) {
                            uploadStandardsArray.push(standardItem);
                        }
                    });
                    let pageCheckboxStatus: any = false;
                    if (
                        !clearInd &&
                        facilityDetails.length > 0 &&
                        facilityIds.length > 0
                    ) {
                        pageCheckboxStatus = getCheckboxStatus(
                            facilityDetails,
                            facilityIds
                        );
                    }
                    setSelectAll({
                        ...selectAll,
                        [`check.${pageNumber}`]: pageCheckboxStatus,
                    });
                    setStandards(uploadStandardsArray);
                    setFacilities(
                        facilityDetails.filter(
                            (facility: any) =>
                                facility.approvalStatus !== CONSTANTS.PENDING
                        )
                    );
                    setAllSelectedFacilityList(
                        facilityDetails.filter(
                            (facility: any) =>
                                facility.approvalStatus !== CONSTANTS.PENDING &&
                                allSelectedFacilityList.some(
                                    (selectedItem: any) =>
                                        selectedItem.facilityName ===
                                        facility.facilityName
                                )
                        )
                    );
                    if (mounted) {
                        setRoleValue("alert");
                    }
                    setIstableLoading(false);
                    setPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                }
            })
            .catch((error: any) => {
                setIstableLoading(false);
            });
        getAllFacilitiesAvailable(categoryId, facilityStatus, searchKeyword);
    };

    const setSelectedCount = (selectedFacility: any) => {
        if (Object.keys(selectedFacility).length > 0) {
            const selectedFacilities = Object.keys(selectedFacility).filter(
                (key) => selectedFacility[key] === true
            );
            if (selectedFacilities.length === allFacilities.length) {
                setSelectAllOption(true);
                setSelectCurrentOption(false);
            }
            setCount(selectedFacilities.length);
            const selectedfacilityIds = selectedFacilities.map((facility) => {
                return facility?.split(".")[1];
            });
            const selectedFacilitiesDetails = allFacilitiesWithoutFilter.filter(
                (facilityDetail: any) =>
                    selectedfacilityIds.indexOf(
                        facilityDetail.hospitalId.toString()
                    ) > -1
            );
            setFacilityIds(selectedFacilitiesDetails);
            return getCheckboxStatus(facilities, selectedFacilitiesDetails);
        }
        setCount(0);
    };

    const handleChange = (event: any) => {
        const selectionValues = {
            ...selection,
            [event.target.name]: event.target.checked,
        };
        setSelection(selectionValues);
        const pageCheckboxStatus = setSelectedCount(selectionValues);
        setSelectAll({
            ...selectAll,
            [`check.${pagination.pageNumber}`]: pageCheckboxStatus,
        });
    };

    const selectCurrentPageFacilities = (event: any) => {
        if (event.target.checked) {
            setSelectAllOption(false);
            setSelectCurrentOption(true);
        } else {
            setSelectAllOption(false);
            setSelectCurrentOption(false);
        }
        setSelectAll({
            ...selectAll,
            [`check.${pagination.pageNumber}`]: event.target.checked,
        });
        if (event.target.checked && facilities.length > 0) {
            for (let index = 0; index < facilities.length; index++) {
                selection[`facility.${facilities[index].hospitalId}`] = true;
            }
        } else if (!event.target.checked && facilities.length > 0) {
            for (
                let facilityIndex = 0;
                facilityIndex < facilities.length;
                facilityIndex++
            ) {
                selection[`facility.${facilities[facilityIndex].hospitalId}`] =
                    false;
            }
        }
        setSelectedCount(selection);
        setSelection({ ...selection });
    };

    const selectAllFacilities = (selectInd = false) => {
        if (selectInd) {
            setSelectCurrentOption(false);
            setSelectAllOption(true);
        } else {
            setSelectCurrentOption(false);
            setSelectAllOption(false);
        }
        if (allFacilities.length > 0 && selectInd) {
            // facilities to be changed to allFacilities
            for (let index = 0; index < allFacilities.length; index++) {
                selection[`facility.${allFacilities[index].hospitalId}`] =
                    selectInd;
            }
        } else if (facilityIds.length > 0 && !selectInd) {
            for (let index = 0; index < facilityIds.length; index++) {
                selection[`facility.${facilityIds[index].hospitalId}`] =
                    selectInd;
            }
        }
        if (pagination.totalPages) {
            for (
                let checkIndex = 1;
                checkIndex <= pagination.totalPages;
                checkIndex++
            ) {
                selectAll[`check.${checkIndex}`] = selectInd;
            }
        }
        setSelectAll({ ...selectAll });
        setSelectedCount(selection);
        setSelection({ ...selection });
    };

    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };

    const getTooltip = () => {
        let toolTip;
        if (count <= 1) {
            toolTip =
                "Please select at least 2 Organizations to upload document.";
        } else if (count > CONSTANTS.HOSPITAL_MAXUSERS) {
            toolTip = `Maximum limit of ${CONSTANTS.HOSPITAL_MAXUSERS} organizations exceeded!`;
        } else if (
            (config.env === "qa" || config.env === "stg") &&
            count > 50
        ) {
            toolTip = `Maximum limit of 50 organizations exceeded!`;
        } else {
            toolTip = "";
        }
        return toolTip;
    };

    const setFacilitiesCount = () => {
        if (facilities.length > 0) {
            const selectedFacility = facilityIds.map(
                (facility: any) => facility.hospitalId
            );
            const currentPageFacilities = facilities.filter(
                (facility: any) =>
                    selectedFacility.indexOf(facility.hospitalId) > -1
            );
            return currentPageFacilities.length;
        }
        return 0;
    };

    const getModalPagination = (
        pageNumberValue: number,
        selectedFacilityDetails: any
    ) => {
        const newPagination = {
            modalPageNumber: pageNumberValue,
            totalPages: 0,
            isFirst: true,
            isLast: false,
        };
        if (selectedFacilityDetails.length % modalPageSize !== 0) {
            newPagination.totalPages =
                Math.floor(selectedFacilityDetails.length / modalPageSize) + 1;
        } else {
            newPagination.totalPages =
                selectedFacilityDetails.length / modalPageSize;
        }
        if (pageNumberValue === 1 && newPagination.totalPages === 1) {
            newPagination.isFirst = true;
            newPagination.isLast = true;
        } else if (pageNumberValue === 1) {
            newPagination.isFirst = true;
            newPagination.isLast = false;
        } else if (pageNumberValue === newPagination.totalPages) {
            newPagination.isFirst = false;
            newPagination.isLast = true;
        } else {
            newPagination.isFirst = false;
            newPagination.isLast = false;
        }
        setModalPagination(newPagination);
    };

    const setCountOnModal = (modifiedValues: any) => {
        if (Object.keys(modifiedValues).length > 0) {
            const selectedFacilitiesTotal = Object.keys(modifiedValues).filter(
                (key) => modifiedValues[key] === true
            );
            setSelectedTotalCount(selectedFacilitiesTotal.length);
        } else {
            setSelectedTotalCount(0);
        }
    };

    const modifyFacilitySelection = (event: any) => {
        const modifiedValues = {
            ...selectedFacilitiesModal,
            [event.target.name]: event.target.checked,
        };
        setSelectedFacilitiesModal(modifiedValues);
        setCountOnModal(modifiedValues);
    };

    const getOnlySelectedFacilities = (
        facilityPage: number,
        modalSortInd: any,
        resetSeletionInd?: boolean
    ) => {
        const sortedFacilityList = sortFacilitiesOnModal(
            modalSortInd,
            facilityIds
        );
        if (sortedFacilityList.length <= modalPageSize) {
            setAllSelectedFacilityList(sortedFacilityList);
        } else {
            const selectedFacilitiesForPage: any = [];
            const indexUpperLimit =
                sortedFacilityList.length < modalPageSize * facilityPage
                    ? sortedFacilityList.length
                    : modalPageSize * facilityPage;
            for (
                let facilityIndex = modalPageSize * (facilityPage - 1);
                facilityIndex < indexUpperLimit;
                facilityIndex++
            ) {
                selectedFacilitiesForPage.push(
                    sortedFacilityList[facilityIndex]
                );
            }
            setAllSelectedFacilityList(selectedFacilitiesForPage);
        }
        if (resetSeletionInd) {
            const latestSelectedFacilities: any = {};
            for (let index = 0; index < sortedFacilityList.length; index++) {
                latestSelectedFacilities[
                    `facilityselected.${sortedFacilityList[index].hospitalId}`
                ] = true;
            }
            setSelectedFacilitiesModal({ ...latestSelectedFacilities });
            setCountOnModal(latestSelectedFacilities);
        } else {
            setCountOnModal(selectedFacilitiesModal);
        }
        getModalPagination(modalPageNumber, sortedFacilityList);
    };

    const sortFacilitiesOnModal = (value: any, facilityList: any) => {
        let sortedFacilities = [];
        if (value) {
            facilityList.sort(function (
                firstFacility: any,
                secondFacility: any
            ) {
                const firstInUpper = firstFacility.facilityName.toUpperCase();
                const secondInUpper = secondFacility.facilityName.toUpperCase();
                if (firstInUpper == secondInUpper) {
                    if (
                        firstFacility.facilityName < secondFacility.facilityName
                    ) {
                        return -1;
                    }
                    if (
                        firstFacility.facilityName > secondFacility.facilityName
                    ) {
                        return 1;
                    }
                    return 0;
                }
                if (firstInUpper < secondInUpper) {
                    return -1;
                }
                if (firstInUpper > secondInUpper) {
                    return 1;
                }
            });
            sortedFacilities = facilityList;
        } else if (value === false) {
            facilityList.sort(function (
                firstFacility: any,
                secondFacility: any
            ) {
                const firstInUpper = firstFacility.facilityName.toUpperCase();
                const secondInUpper = secondFacility.facilityName.toUpperCase();
                if (firstInUpper == secondInUpper) {
                    if (
                        firstFacility.facilityName < secondFacility.facilityName
                    ) {
                        return 1;
                    }
                    if (
                        firstFacility.facilityName > secondFacility.facilityName
                    ) {
                        return -1;
                    }
                    return 0;
                }
                if (firstInUpper < secondInUpper) {
                    return 1;
                }
                if (firstInUpper > secondInUpper) {
                    return -1;
                }
            });
            sortedFacilities = facilityList;
        } else {
            const facilitiesIdList = facilityIds.map(
                (facility: any) => facility.hospitalId
            );
            sortedFacilities = allFacilitiesWithoutFilter.filter(
                (facilityObject: any) =>
                    facilitiesIdList.indexOf(facilityObject.hospitalId) > -1
            );
        }
        return sortedFacilities;
    };

    const setMasterListSelection = () => {
        Object.keys(selectedFacilitiesModal).forEach((keyValue: string) => {
            if (selectedFacilitiesModal[keyValue] === false) {
                selection[`facility.${keyValue.split(".")[1]}`] = false;
                setSelection({ ...selection });
                const pageCheckboxStatus = setSelectedCount(selection);
                setSelectAll({
                    ...selectAll,
                    [`check.${pagination.pageNumber}`]: pageCheckboxStatus,
                });
            }
        });
        selectedFacilitiesDismissModal();
    };

    const navigateToScreen = (
        hospitalId: any,
        programId: any,
        programCategoryId: any
    ) => {
        history.push(
            `/corporation/organization-details/${hospitalId}/program/${programId}/category/${programCategoryId}`,
            {
                tab: CONSTANTS.BULKDOCUMENT,
                programFilter: program,
                searchFilter: searchKey,
                typeFilter: status,
                pageNumberFilter: pagination.pageNumber,
                sortingInd: sortInd,
                userSelection: selection,
                selectedFacilityIds: facilityIds,
                selectCount: count,
            }
        );
    };

    const checkForSelection = (typeValue: any) => {
        if (
            (typeValue === CONSTANTS.REGISTRED ||
                typeValue === CONSTANTS.CERTIFIED ||
                typeValue === CONSTANTS.AWARDED ||
                typeValue === CONSTANTS.RENEWING) &&
            count > 0
        ) {
            setSelectedType(typeValue);
            setShowConfirmationModal(true);
            showModal();
        } else {
            setStatus(typeValue);
            getSearchedFacilities(program, searchKey, typeValue, 1);
            setPageNumber(1);
            setSelectAll({});
            if (
                searchFilter.sortOrganizations !== CONSTANTS.ASCENDING &&
                searchFilter.sortOrganizations !== CONSTANTS.DESCENDING
            ) {
                setSortInd(undefined);
            }
        }
    };

    const setChangedPageValue = (pageNumberValue: number) => {
        setModalPageNumber(pageNumberValue);
        setModalRole("alert");
    };

    const selectedFacilitiesDismissModal = () => {
        setSelectedFacilitiesActivateInd(false);
        showModal();
        dispatch(setSortOrganizations(previousDispatch));
        setSortInd(previousSortInd);
    };

    return (
        <BulkDocumentSubmissionWrapper className="">
            <CorporationTabs activeTab="corporationBulkDocumentSubmission" />
            <div className="container pt-3 ">
                <h1 className="h4 font-500 mb-4">Document Submission</h1>
                <div
                    className="navbar-expand-md bulk-filter mb-4"
                    aria-labelledby="bulkDocsFilerby"
                    role="group"
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="h6 font-600 mb-0" id="bulkDocsFilerby">
                            Filter By
                        </h2>
                        <button
                            type="button"
                            className="navbar-toggler"
                            data-toggle="collapse"
                            data-target="#bulkDocFilters"
                            aria-controls="bulkDocFilters"
                            aria-expanded="false"
                            aria-label="Filter by"
                        >
                            <i
                                className="aha-icon-hamburger-round"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                    <div
                        className="collapse navbar-collapse flex-column align-items-stretch"
                        id="bulkDocFilters"
                    >
                        <div className="d-flex flex-column flex-md-row my-3 my-md-0">
                            <div className="d-xl-flex align-items-center bulk-doc-filter-field bulk-doc-filter-1">
                                <label
                                    htmlFor="selectProgram"
                                    id="selectProgram-label"
                                    className="mr-3 mb-0"
                                >
                                    Program
                                </label>
                                <div className="flex-grow-1">
                                    {selectedProgram && (
                                        <SearchDropdown
                                            id="selectProgram"
                                            items={selectedProgram}
                                            selectedValue={program}
                                            callParentOnSelect={(
                                                value: any
                                            ) => {
                                                if (value !== program) {
                                                    setProgram(value);
                                                    getSearchedFacilities(
                                                        value,
                                                        "",
                                                        CONSTANTS.ALL,
                                                        1
                                                    );
                                                    setSearchKey("");
                                                    setStatus(CONSTANTS.ALL);
                                                    setFacilityIds([]);
                                                    setSelection({});
                                                    setPageNumber(1);
                                                    setSelectAll({});
                                                    setCount(0);
                                                    setSortInd(sortInd);
                                                    setSelectedFacilitiesModal(
                                                        {}
                                                    );
                                                }
                                            }}
                                            placeholder="Select"
                                            label="Program"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="d-xl-flex align-items-center bulk-doc-filter-field bulk-doc-filter-2">
                                <label className="mr-3 mb-0">Search</label>
                                <div className="flex-grow-1">
                                    <SmartSearch
                                        searchKey={searchKey}
                                        setSearchKey={setSearchKey}
                                        getSearchedItems={(value: any) => {
                                            getSearchedFacilities(
                                                program,
                                                value,
                                                status,
                                                1
                                            );
                                            setPageNumber(1);
                                            setSelectAll({});
                                            setSortInd(sortInd);
                                        }}
                                        placeholder="By Organization Name/Code"
                                        arialabel="Search"
                                        id="bulk-search"
                                    />
                                </div>
                            </div>
                            <div className="d-xl-flex align-items-center bulk-doc-filter-field bulk-doc-filter-3">
                                <label
                                    htmlFor="programType"
                                    id="programType-label"
                                    className="mr-3 mb-0"
                                >
                                    Status
                                </label>
                                <div className="flex-grow-1">
                                    {statusDropdown && (
                                        <Dropdown
                                            id="programType"
                                            items={statusDropdown}
                                            selectedValue={status}
                                            callParentOnSelect={(value: any) =>
                                                checkForSelection(value)
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="h5 font-500 mb-3 mb-sm-0">
                    Select Organizations
                </h2>
                <div className="d-flex justify-content-sm-end">
                    {(count < 2 ||
                        count > CONSTANTS.HOSPITAL_MAXUSERS ||
                        ((config.env === "qa" || config.env === "stg") &&
                            count > 50)) && (
                        <div
                            className="bulk-upload-btn bulk-right-content"
                            id="bulk-fileupload-btn"
                        >
                            <button
                                className="btn btn-primary btn-round ml-0 ml-lg-4 upload-btn upload-docbtn 11"
                                data-toggle="popover"
                                {...(!isSafariBrowser()
                                    ? { "data-trigger": "focus" }
                                    : {})}
                                data-placement="bottom"
                                data-content={getTooltip()}
                                data-container="#bulk-fileupload-btn"
                            >
                                Upload Documents
                                <i
                                    className="aha-icon-upload ml-2 d-xl-inline-block d-md-none d-xl-inline-block"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    )}
                    {count >= 2 &&
                        ((config.env !== "qa" &&
                            config.env !== "stg" &&
                            count <= CONSTANTS.HOSPITAL_MAXUSERS) ||
                            ((config.env === "qa" || config.env === "stg") &&
                                count <= 50)) && (
                            <div className="bulk-upload-btn bulk-right-content">
                                <button
                                    className="btn btn-primary btn-round ml-0 ml-lg-4 upload-btn upload-docbtn 22"
                                    onClick={() => {
                                        setuploadDocumentInd(true);
                                        showModal();
                                    }}
                                >
                                    Upload Documents
                                    <i className="aha-icon-upload ml-2 d-xl-inline-block d-md-none d-xl-inline-block" />
                                </button>
                            </div>
                        )}
                    {uploadDocumentInd ? (
                        <FocusTrap
                            focusTrapOptions={{
                                escapeDeactivates: false,
                                clickOutsideDeactivates: false,
                            }}
                        >
                            <UploadDocument
                                uploadProgram={uploadProgram}
                                standards={standards}
                                setRefreshTableDateNow={undefined}
                                setUploadDocForm={setUploadDocForm}
                                isEUP={false}
                                initUploadDocForm={initUploadDocForm}
                                uploadDocForm={uploadDocForm}
                                setuploadDocumentInd={setuploadDocumentInd}
                                uploadDocumentInd={uploadDocumentInd}
                                facilityCount={count}
                                facilityIds={facilityIds}
                                setCount={setCount}
                                setSelectAll={setSelectAll}
                                setSelection={setSelection}
                                setFacilityIds={setFacilityIds}
                            />
                        </FocusTrap>
                    ) : null}
                </div>
                <div className="mt-3 mt-md-0 d-block d-lg-none">
                    {pagination?.totalCount != undefined &&
                        pagination?.totalCount > 0 && (
                            <SortTableColumnDropdown
                                pageNumber={1}
                                sortIndValue={sortIndValue}
                                pageSize={pagination.pageSize}
                                id="bulkSort-sm"
                            />
                        )}
                </div>
                <div className="d-flex justify-content-between align-items-lg-end mb-3 flex-column flex-lg-row mt-4 ">
                    <div className="bulk-left-content">
                        <div className="d-flex align-items-lg-center flex-column flex-lg-row">
                            <div className="d-lg-none d-sm-flex justify-content-between">
                                <div className="mb-0 mb-lg-3 mr-3 pt-sm-2">
                                    {pagination?.totalCount != undefined &&
                                        pagination?.totalCount > 0 &&
                                        !isTableLoading && (
                                            <SelectAllCheckbox
                                                selectCurrentPageFacilities={
                                                    selectCurrentPageFacilities
                                                }
                                                selectAll={selectAll}
                                                selectAllFacilities={
                                                    selectAllFacilities
                                                }
                                                totalCount={
                                                    pagination?.totalCount
                                                }
                                                pageNumber={
                                                    pagination.pageNumber
                                                }
                                                isLast={pagination.isLast}
                                                selectAllOption={
                                                    selectAllOption
                                                }
                                                selectCurrentOption={
                                                    selectCurrentOption
                                                }
                                                count={count}
                                                id="tableSmCheckbox"
                                                dropdownRef={dropdownRef}
                                            />
                                        )}
                                </div>
                            </div>
                            <div className="bulk-table-count">
                                <TableRowCount
                                    isFirst={pagination.isFirst}
                                    pageNumber={pagination.pageNumber}
                                    pageSize={pagination.pageSize}
                                    isLast={pagination.isLast}
                                    totalCount={pagination.totalCount}
                                    roleValue={roleValue}
                                />
                            </div>
                        </div>
                        {count > 0 && (
                            <div
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View Selected Organizations"
                                className="d-inline-block ml-3"
                            >
                                <button
                                    type="button"
                                    className="tag-count mt-3 mt-sm-0 mb-2 mb-lg-0"
                                    data-toggle="modal"
                                    data-target="#selctedFacilityModal"
                                    onClick={() => {
                                        getOnlySelectedFacilities(
                                            1,
                                            undefined,
                                            true
                                        );
                                        setSortIndModal(undefined);
                                        dispatch(
                                            setSortOrganizations(CONSTANTS.ALL)
                                        );
                                        setPreviousDispatch(
                                            searchFilter?.sortOrganizations
                                        );
                                        setPreviousSortInd(sortInd);
                                        setModalPageNumber(1);
                                        setSelectedFacilitiesActivateInd(true);
                                        showModal();
                                    }}
                                    aria-label={`View selected organizations ${count} Selected`}
                                >
                                    <span aria-live="assertive" role="alert">
                                        {count} Selected
                                    </span>
                                </button>
                            </div>
                        )}
                        {/* Selected Facility Modal */}
                        {selectedFacilitiesActivateInd ? (
                            <FocusTrap
                                focusTrapOptions={{
                                    escapeDeactivates: false,
                                    clickOutsideDeactivates: false,
                                }}
                            >
                                <div
                                    className="modal fade show aui-modal bulk-selected-modal"
                                    style={{
                                        display: selectedFacilitiesActivateInd
                                            ? "block"
                                            : "none",
                                    }}
                                    id="selctedFacilityModal"
                                    tabIndex={-1}
                                    aria-labelledby="selctedFacilityModalLabel"
                                    aria-modal="true"
                                    role="dialog"
                                    data-backdrop="static"
                                    data-keyboard="false"
                                >
                                    <div className="modal-dialog modal-dialog-centered modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <div className="d-flex align-items-center">
                                                    <h2
                                                        className="h4 m-0"
                                                        id="selctedFacilityModalLabel"
                                                    >
                                                        Selected Organizations
                                                    </h2>
                                                    <div
                                                        className="tag-count mt-3 mt-sm-0 ml-3"
                                                        aria-live="assertive"
                                                        role="alert"
                                                    >
                                                        {selectedTotalCount}{" "}
                                                        Selected
                                                    </div>
                                                </div>
                                                <button
                                                    id="closeselectedFacilitiesModal"
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close selected organizations modal"
                                                    onClick={
                                                        selectedFacilitiesDismissModal
                                                    }
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className="aha-icon-cross"
                                                    />
                                                </button>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <TableRowCount
                                                    isFirst={
                                                        modalPagination.isFirst
                                                    }
                                                    pageNumber={
                                                        modalPagination.modalPageNumber
                                                    }
                                                    pageSize={modalPageSize}
                                                    isLast={
                                                        modalPagination.isLast
                                                    }
                                                    totalCount={count}
                                                    roleValue={modalRole}
                                                />
                                                <div className="mt-3 mt-md-0 d-none d-lg-block">
                                                    {pagination?.totalCount !=
                                                        undefined &&
                                                        pagination?.totalCount >
                                                            0 && (
                                                            <SortTableColumnDropdown
                                                                pageNumber={1}
                                                                sortIndValue={
                                                                    sortIndValue
                                                                }
                                                                pageSize={
                                                                    pagination.pageSize
                                                                }
                                                                id="selectedBulkSort"
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                            <div className="modal-body pt-2 px-0 pb-0">
                                                <table
                                                    className={`aui-responsive-status-table bulk-docselected-table ${
                                                        isTableLoading
                                                            ? CONSTANTS.TABLE_SPINNER
                                                            : ""
                                                    }`}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <div className="aui-th justify-content-center">
                                                                    <div className="sr-only">
                                                                        Select
                                                                        organizations
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div className="aui-th">
                                                                    Organization
                                                                    Code
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div className="aui-th">
                                                                    Organization
                                                                    Name
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allSelectedFacilityList.length >
                                                        0 ? (
                                                            allSelectedFacilityList.map(
                                                                (
                                                                    facility: any
                                                                ) => {
                                                                    return (
                                                                        <tr
                                                                            key={`SelectedFacility-${facility.facilityCode}`}
                                                                            className="aui-table-status-normal"
                                                                        >
                                                                            <td data-title="Select Facility">
                                                                                <div className="aui-td justify-content-center">
                                                                                    <div className="form-group form-check mb-0">
                                                                                        <span className="sr-only">
                                                                                            select
                                                                                            organization
                                                                                        </span>
                                                                                        <input
                                                                                            name={`facilityselected.${facility.hospitalId}`}
                                                                                            onChange={
                                                                                                modifyFacilitySelection
                                                                                            }
                                                                                            checked={
                                                                                                selectedFacilitiesModal[
                                                                                                    `facilityselected.${facility.hospitalId}`
                                                                                                ] ==
                                                                                                true
                                                                                            }
                                                                                            type="checkbox"
                                                                                            value=""
                                                                                            id={`checkSelected${facility.hospitalId}`}
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={`checkSelected${facility.hospitalId}`}
                                                                                        >
                                                                                            <span className="sr-only">
                                                                                                {
                                                                                                    facility.facilityName
                                                                                                }
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td data-title="Organization Code">
                                                                                <div className="aui-td">
                                                                                    <span className="sr-only">
                                                                                        organization
                                                                                        code:
                                                                                    </span>
                                                                                    <span className="abbr-code">
                                                                                        {wordSplit(
                                                                                            facility.facilityCode
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td data-title="Organization Name">
                                                                                <div className="aui-td">
                                                                                    <span className="sr-only">
                                                                                        organization
                                                                                        name:
                                                                                    </span>
                                                                                    <div>
                                                                                        <button
                                                                                            className="btn-text-link-uline text-left"
                                                                                            // aria-describedby={`bulk-docselected-${facility.facilityCode}`}
                                                                                            data-dismiss="modal"
                                                                                            onClick={() => {
                                                                                                selectedFacilitiesDismissModal();
                                                                                                navigateToScreen(
                                                                                                    facility.hospitalId,
                                                                                                    facility.programId,
                                                                                                    facility.programCategoryId
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <span className="sr-only">
                                                                                                organization
                                                                                                name:
                                                                                            </span>
                                                                                            {
                                                                                                facility.facilityName
                                                                                            }
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <tr className="aui-status-table-emptystate">
                                                                <td colSpan={3}>
                                                                    <div className="aui-td">
                                                                        No data
                                                                        to
                                                                        display
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {Number(pagination.totalPages) >
                                                    0 && (
                                                    <Pagination
                                                        pageSize={modalPageSize}
                                                        pageNumber={
                                                            modalPagination.modalPageNumber
                                                        }
                                                        totalPages={
                                                            modalPagination.totalPages
                                                        }
                                                        isFirst={
                                                            modalPagination.isFirst
                                                        }
                                                        isLast={
                                                            modalPagination.isLast
                                                        }
                                                        setPageNumber={
                                                            setChangedPageValue
                                                        }
                                                        paginationSetLimit={5}
                                                    />
                                                )}
                                                <div className="d-flex flex-sm-row flex-column justify-content-center mt-4">
                                                    <button
                                                        className="btn btn-secondary btn-round mx-sm-2 mb-3 mb-sm-0"
                                                        aria-label="Cancel selected organizations"
                                                        onClick={
                                                            selectedFacilitiesDismissModal
                                                        }
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={
                                                            setMasterListSelection
                                                        }
                                                        className="btn btn-primary btn-round mx-sm-2"
                                                        data-dismiss="modal"
                                                        aria-label="Save selected organizations"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FocusTrap>
                        ) : null}
                    </div>
                    <div className="mt-3 mt-md-0 d-none d-lg-block">
                        {pagination?.totalCount != undefined &&
                            pagination?.totalCount > 0 && (
                                <SortTableColumnDropdown
                                    pageNumber={1}
                                    sortIndValue={sortIndValue}
                                    pageSize={pagination.pageSize}
                                    id="bulkSort"
                                />
                            )}
                    </div>
                </div>
                {isTableLoading ? (
                    <div
                        role="alert"
                        aria-live="assertive"
                        aria-label="Bulk Document submission table is loading"
                        className="sr-only"
                    />
                ) : (
                    ""
                )}
                <table
                    className={`aui-responsive-status-table bulk-doc-table ${
                        isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                    }`}
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="aui-th justify-content-center">
                                    {pagination?.totalCount != undefined &&
                                        pagination?.totalCount > 0 &&
                                        !isTableLoading && (
                                            <SelectAllCheckbox
                                                selectCurrentPageFacilities={
                                                    selectCurrentPageFacilities
                                                }
                                                selectAllFacilities={
                                                    selectAllFacilities
                                                }
                                                selectAll={selectAll}
                                                totalCount={
                                                    pagination?.totalCount
                                                }
                                                pageNumber={
                                                    pagination.pageNumber
                                                }
                                                isLast={pagination.isLast}
                                                selectAllOption={
                                                    selectAllOption
                                                }
                                                selectCurrentOption={
                                                    selectCurrentOption
                                                }
                                                count={count}
                                                id="tablecheckbox"
                                                dropdownRef={dropdownRef}
                                            />
                                        )}
                                </div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">
                                    Organization Details
                                </div>
                            </th>
                            <th scope="col">
                                <div className="aui-th justify-content-between">
                                    Location Details
                                </div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Status</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {facilities.length > 0 &&
                            facilities
                                .filter(
                                    (facility: { approvalStatus: string }) =>
                                        facility.approvalStatus !== "PENDING"
                                )
                                .map((facility: any) => {
                                    return (
                                        <tr
                                            key={facility.hospitalId}
                                            className="aui-table-status-normal"
                                        >
                                            <td data-title="Select Organization">
                                                <div className="aui-td justify-content-center">
                                                    <div className="form-group form-check mb-0 ss">
                                                        <input
                                                            name={`facility.${facility.hospitalId}`}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            checked={
                                                                selection[
                                                                    `facility.${facility.hospitalId}`
                                                                ] == true
                                                            }
                                                            type="checkbox"
                                                            value=""
                                                            id={`check${facility.hospitalId}`}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Select Organization"
                                                            data-trigger="focus"
                                                            aria-label={
                                                                facility.facilityName
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={`check${facility.hospitalId}`}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-title="Organization Code">
                                                <div className="aui-td">
                                                    <div>
                                                        <Link
                                                            className="btn-text-link-uline text-left"
                                                            to={{
                                                                pathname: `/corporation/organization-details/${facility.hospitalId}/program/${facility.programId}/category/${facility.programCategoryId}`,
                                                                state: {
                                                                    tab: CONSTANTS.BULKDOCUMENT,
                                                                    programFilter:
                                                                        program,
                                                                    searchFilter:
                                                                        searchKey,
                                                                    typeFilter:
                                                                        status,
                                                                    pageNumberFilter:
                                                                        pagination.pageNumber,
                                                                    sortingInd:
                                                                        sortInd,
                                                                    userSelection:
                                                                        selection,
                                                                    selectedFacilityIds:
                                                                        facilityIds,
                                                                    selectCount:
                                                                        count,
                                                                },
                                                            }}
                                                            id={
                                                                facility.facilityCode
                                                            }
                                                        >
                                                            {
                                                                facility.facilityName
                                                            }
                                                        </Link>
                                                        <div className="mt-1">
                                                            Code:{" "}
                                                            <span className="abbr-code">
                                                                {wordSplit(
                                                                    facility.facilityCode
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-title="Location Details">
                                                <div className="aui-td flex-column justify-content-center">
                                                    <div className="d-flex w-100">
                                                        <div className="user-loc-title">
                                                            Location:
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            {
                                                                facility.countryName
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex w-100">
                                                        <div className="user-loc-title">
                                                            State:
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            {facility.state}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-title="Status">
                                                <div className="aui-td">
                                                    {`${facility.approvalStatus
                                                        .charAt(0)
                                                        .toUpperCase()}${facility.approvalStatus
                                                        .slice(1)
                                                        .toLowerCase()}`}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                    </tbody>
                </table>
                <div
                    role="alert"
                    aria-atomic="true"
                    className="table-emptystate-outer"
                >
                    {facilities.length < 1 && <div>No data to display</div>}
                </div>
                {!isTableLoading &&
                pagination &&
                Number(pagination.totalPages) > 0 ? (
                    <Pagination
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        totalCount={pagination.totalCount}
                        totalPages={pagination.totalPages}
                        isFirst={pagination.isFirst}
                        isLast={pagination.isLast}
                        setPageNumber={pagination.setPageNumber}
                        paginationSetLimit={paginationSetLimit}
                    />
                ) : null}
            </div>
            <div
                className="facility-confirm-modal modal fade aui-modal"
                id="clearcurrentPageFacilityModal"
                tabIndex={-1}
                aria-labelledby="clearcurrentPageFacilityModalLabel"
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="text-center">
                                <span
                                    id="clearcurrentPageFacilityModalLabel"
                                    className="sr-only"
                                >
                                    Confirm clear organization
                                </span>
                                <p className="pb-2">
                                    Are your sure you want to clear the selected
                                    Organizations?
                                </p>
                                <div className="mt-4 d-flex justify-content-center">
                                    <button
                                        className="btn btn-secondary btn-round btn-sm mx-2"
                                        data-dismiss="modal"
                                        aria-label="No and close the modal"
                                        onClick={() =>
                                            dropdownRef.current?.click()
                                        }
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-primary btn-round btn-sm mx-1"
                                        data-dismiss="modal"
                                        aria-label={`Current page ${setFacilitiesCount()} clear organizations`}
                                        onClick={() => {
                                            selectCurrentPageFacilities({
                                                target: { checked: false },
                                            });
                                            dropdownRef.current?.focus();
                                        }}
                                    >
                                        Current page ({setFacilitiesCount()})
                                    </button>
                                    <button
                                        className="btn btn-primary btn-round btn-sm mx-1"
                                        data-dismiss="modal"
                                        onClick={() => {
                                            selectAllFacilities(false);
                                            dropdownRef.current?.focus();
                                        }}
                                        aria-label={`All ${count} clear organizations`}
                                    >
                                        All ({count})
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Clear Selection Confirmation Modal */}
            <div
                className="facility-confirm-modal modal fade show aui-modal"
                id="clearFacilitySelectionModal"
                tabIndex={-1}
                aria-labelledby="clearFacilitySelectionModalLabel"
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="text-center">
                                <span
                                    id="clearFacilitySelectionModalLabel"
                                    className="sr-only"
                                >
                                    clear the selected organizations
                                </span>
                                <p className="pb-2">
                                    Are your sure you want to clear the selected
                                    organizations on the current page?
                                </p>
                                <div className="mt-4 d-flex justify-content-center">
                                    <button
                                        className="btn btn-secondary btn-round btn-sm mx-2"
                                        data-dismiss="modal"
                                        aria-label="No and close the modal"
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-primary btn-round btn-sm mx-2"
                                        data-dismiss="modal"
                                        onClick={(event) =>
                                            selectCurrentPageFacilities({
                                                target: { checked: false },
                                            })
                                        }
                                        aria-label="Yes clear selected organizations"
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmationModal && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="facility-confirm-modal modal fade show aui-modal"
                        id="clearPreviousSelectedFacilityModal"
                        tabIndex={-1}
                        aria-labelledby="clearPreviousSelectedFacilityModalLabel"
                        aria-modal="true"
                        role="dialog"
                        style={{
                            display: showConfirmationModal ? "block" : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <span
                                            id="clearPreviousSelectedFacilityModalLabel"
                                            className="sr-only"
                                        >
                                            Confirm clear previously selected
                                            organization
                                        </span>
                                        <p className="pb-2">
                                            Do you want to clear the previous
                                            selection?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                data-dismiss="modal"
                                                aria-label="Cancel clearing and close the modal"
                                                onClick={() => {
                                                    setShowConfirmationModal(
                                                        false
                                                    );
                                                    showModal();
                                                    document
                                                        .getElementById(
                                                            "programType"
                                                        )
                                                        ?.focus();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-1"
                                                data-dismiss="modal"
                                                aria-label="Clear previous selection and change filter"
                                                onClick={() => {
                                                    setStatus(selectedType);
                                                    getSearchedFacilities(
                                                        program,
                                                        searchKey,
                                                        selectedType,
                                                        1,
                                                        true
                                                    );
                                                    setFacilityIds([]);
                                                    setSelection({});
                                                    setPageNumber(1);
                                                    setSelectAll({});
                                                    setCount(0);
                                                    setSortInd(sortInd);
                                                    setSelectedFacilitiesModal(
                                                        {}
                                                    );
                                                    selectAllFacilities(false);
                                                    setShowConfirmationModal(
                                                        false
                                                    );
                                                    showModal();
                                                    document
                                                        .getElementById(
                                                            "programType"
                                                        )
                                                        ?.focus();
                                                }}
                                            >
                                                Clear
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-1"
                                                data-dismiss="modal"
                                                aria-label="Dont clear and change the filter"
                                                onClick={() => {
                                                    setStatus(selectedType);
                                                    getSearchedFacilities(
                                                        program,
                                                        searchKey,
                                                        selectedType,
                                                        1
                                                    );
                                                    setPageNumber(1);
                                                    setSelectAll({});
                                                    setSortInd(sortInd);
                                                    setShowConfirmationModal(
                                                        false
                                                    );
                                                    showModal();
                                                    document
                                                        .getElementById(
                                                            "programType"
                                                        )
                                                        ?.focus();
                                                }}
                                            >
                                                Don&#39;t Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
        </BulkDocumentSubmissionWrapper>
    );
};

export default CorporationBulkDocumentSubmission;
