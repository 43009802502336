import React, { useEffect, useState } from "react";
import "../../styles/styles.scss";
import Dropdown from "components/Dropdown";
import CONSTANTS from "common/constants";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import GroupedMultiSelectDropdown from "components/GroupedMultiSelectDropdown";
import SearchDropdown from "components/SearchDropdown";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import { getCountries } from "../../../api/countriesAPI";
import { getPrograms } from "../../../api/programs";
import { logError, logger } from "../../../utils/logger.utils";
import { saveCorporation } from "../../../api/corporationAPI";
import { showToast } from "../../../components/Toast/toast.slice";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";
import { CorporationFormWrapper } from "./styled";

interface Props {
    corporation: any;
    backToCorporationManagement: any;
    editMode: boolean;
    setEditMode: any;
    selectedPrograms: any;
}

export const AddOrEditCorporation = (props: Props) => {
    const [corporation, setCorporation] = useState<any>(props.corporation);
    const dispatch = useDispatch();
    const history = useHistory();
    const [countriesMap, setCountriesMap] = useState<any>([
        {
            label: CONSTANTS.DOMESTIC_COUNTRY_NAME,
            value: CONSTANTS.DOMESTIC_COUNTRY_NAME,
        },
    ]);
    const [programsMap, setProgramsMap] = useState<any>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [buttonState, setButtonState] = useState<boolean>(true);
    const [selectedPrograms, setSelectedPrograms] = useState<any>(
        props.selectedPrograms
    );
    const [programsLoadingInd, setProgramsLoadingInd] =
        useState<boolean>(false);
    const [allPrograms, setAllPrograms] = useState<any>([]);

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        setProgramsLoadingInd(true);
        Promise.all([getCountries(true), getPrograms()])
            .then((responses: any) => {
                const countriesResponse = responses[0];
                const programsResponse = responses[1];
                if (countriesResponse.success) {
                    const countries: any = [];
                    countriesResponse.data.countries.map((country: any) => {
                        countries.push({
                            label: country.countryName,
                            value: `${country.countryId}#${country.countryName}`,
                        });
                    });
                    setCountriesMap(countries);
                    if (programsResponse.success) {
                        const programs: any = [];
                        setAllPrograms(programsResponse.data.programDetails);
                        programsResponse.data.programDetails.map(
                            (program: any) => {
                                programs.push({
                                    label: program.program_name,
                                    value: program?.program_id,
                                });
                            }
                        );
                        corporation.corporationProgramDetails?.length &&
                            programs.map((program: any) => {
                                corporation.corporationProgramDetails.map(
                                    (corpProgram: any) => {
                                        if (
                                            program.value ===
                                                corpProgram.programId &&
                                            corpProgram.hospitalCount > 0
                                        ) {
                                            program.disableInd = true;
                                        }
                                    }
                                );
                            });
                        setProgramsMap(programs);
                        setProgramsLoadingInd(false);
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch((error: any) => {
                logger("programsAPI:", error);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);

    const handleChange = (event: any) => {
        setCorporation({
            ...corporation,
            [event.target.name]:
                event.target.name === "website"
                    ? event.target.value.toLowerCase()
                    : event.target.name === "corporationName" ||
                      event.target.name === "state"
                    ? event.target.value.replace(/[><]/g, "")
                    : event.target.value,
        });
        setButtonState(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setDisabled(true);
        const isUpdate = !!props.corporation?.id;
        const updateCorporation = corporation;

        if (!updateCorporation.countryId) {
            delete updateCorporation.countryId;
            delete updateCorporation.countryName;
        }

        if (isUpdate) {
            delete updateCorporation.updatedBy; // delete updated by
            [
                "registeredEmailId",
                "isActivated",
                "isDeleted",
                "activatedDate",
                "deactivatedDate",
                "activatedBy",
                "deactivatedBy",
                "createdBy",
                "activationStatus",
                "createdAt",
                "updatedAt",
                "hospitalCount",
            ].forEach((attribute) => delete updateCorporation[attribute]);
            Object.keys(corporation).forEach((attribute: any) => {
                if (corporation[attribute] === null) {
                    delete updateCorporation[attribute];
                }
            });
            setCorporation(updateCorporation);
        }

        if (!corporation.countryId) {
            const error = {
                error: "Error",
                message: "Please select location!",
                code: "Mandatory",
            };
            dispatch(showToast(error));
            setDisabled(false);
            return false; // Stop network call
        }
        if (selectedPrograms?.length > 0) {
            const programsSelected: any = [];
            selectedPrograms.map((selectedProgram: any) => {
                allPrograms.map((program: any) => {
                    if (selectedProgram.value == program.program_id) {
                        programsSelected.push({
                            programId: program.program_id,
                            programName: program.program_name,
                        });
                    }
                });
            });
            setCorporation({
                ...corporation,
                corporationProgramDetails: programsSelected,
            });
            updateCorporation.programs = programsSelected;
        } else {
            const error = {
                error: "Error",
                message: "Please select program(s).",
                code: "Mandatory",
            };
            dispatch(showToast(error));
            setDisabled(false);
            return false; // Stop network call
        }

        saveCorporation(updateCorporation, isUpdate)
            .then((response: any) => {
                if (response.success) {
                    const toast = {
                        message: `Corporation ${
                            isUpdate ? "updated" : "created"
                        } successfully.`,
                        code: "Success:",
                        type: "success",
                    };
                    dispatch(showToast(toast));
                    if (props.editMode) {
                        props.setEditMode(!props.editMode);
                    } else {
                        history.push(props.backToCorporationManagement);
                    }
                }
            })
            .catch((error: any) => {
                logError(error);
                setDisabled(false);
            });
    };

    return (
        <CorporationFormWrapper>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-10 d-flex flex-row-reverse">
                        <div className="pb-3 mand-field">
                            <sup>*</sup>mandatory fields
                        </div>
                    </div>
                </div>
                <fieldset className="row" disabled={disabled}>
                    <div className="col-lg-10">
                        <div className="form-group row required">
                            <div className="col-lg-4">
                                <label
                                    htmlFor="corporationCode"
                                    className="label-form"
                                >
                                    Corporation Code
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="corporationCode"
                                    name="corporationCode"
                                    value={
                                        corporation?.corporationCode
                                            ? corporation.corporationCode
                                            : ""
                                    }
                                    onChange={handleChange}
                                    pattern={CONSTANTS.REGEX_ALLOW_ALPHANUMERIC}
                                    maxLength={30}
                                    required
                                    aria-describedby="corpCode"
                                />
                                <div className="form-help" id="corpCode">
                                    (Enter 1 to 30 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-lg-4">
                                <label
                                    htmlFor="corporationName"
                                    className="label-form"
                                >
                                    Corporation Name
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="corporationName"
                                    name="corporationName"
                                    value={
                                        corporation?.corporationName
                                            ? corporation.corporationName
                                            : ""
                                    }
                                    onChange={handleChange}
                                    maxLength={100}
                                    required
                                    aria-describedby="corpName"
                                />
                                <div className="form-help" id="corpName">
                                    (Enter 2 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label htmlFor="corporationStreetAddress">
                                    Street Address
                                </label>
                            </div>
                            <div className="col-md-8">
                                <textarea
                                    className="form-control"
                                    id="corporationStreetAddress"
                                    name="corporationStreetAddress"
                                    value={
                                        corporation?.corporationStreetAddress
                                            ? corporation.corporationStreetAddress
                                            : ""
                                    }
                                    onChange={handleChange}
                                    maxLength={100}
                                    aria-describedby="corpAddress"
                                />
                                <div className="form-help" id="corpAddress">
                                    (Enter 1 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-lg-4">
                                <label
                                    htmlFor="countryName"
                                    id="countryName-label"
                                    className="label-form"
                                >
                                    Location
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <div className="flex-grow-1">
                                    {countriesMap && (
                                        <SearchDropdown
                                            disabled={disabled}
                                            items={countriesMap}
                                            selectedValue={`${corporation?.countryId}#${corporation?.countryName}`}
                                            callParentOnSelect={(
                                                value: any
                                            ) => {
                                                const countryIdAndName =
                                                    value?.split("#");
                                                setCorporation({
                                                    ...corporation,
                                                    countryId:
                                                        countryIdAndName[0],
                                                    countryName:
                                                        countryIdAndName[1],
                                                });
                                                setButtonState(false);
                                            }}
                                            id="countryName"
                                            label="Select Location"
                                            placeholder="Select"
                                            mandatoryInd
                                            ariaInnerLabelValue="countries"
                                        />
                                    )}
                                    <span
                                        className="sr-only"
                                        id="countryNamehelptext"
                                    >
                                        Please select the Location
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-lg-4">
                                <label htmlFor="state" className="label-form">
                                    State<sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="state"
                                    name="state"
                                    value={
                                        corporation?.state
                                            ? corporation.state
                                            : ""
                                    }
                                    onChange={handleChange}
                                    maxLength={30}
                                    required
                                    aria-describedby="corpState"
                                />
                                <div className="form-help" id="corpState">
                                    (Enter 2 to 30 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label htmlFor="description">Description</label>
                            </div>
                            <div className="col-md-8">
                                <textarea
                                    className="form-control"
                                    name="description"
                                    id="description"
                                    value={
                                        corporation?.description
                                            ? corporation.description
                                            : ""
                                    }
                                    onChange={handleChange}
                                    maxLength={300}
                                    aria-describedby="corpDescribed"
                                />
                                <div className="form-help" id="corpDescribed">
                                    (Enter 1 to 300 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label htmlFor="website">Website</label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    pattern={CONSTANTS.REGEX_ALLOW_WEBSITE}
                                    type="text"
                                    className="form-control"
                                    name="website"
                                    id="website"
                                    value={
                                        corporation?.website
                                            ? corporation.website
                                            : ""
                                    }
                                    onChange={handleChange}
                                    maxLength={100}
                                    aria-describedby="corpWebsite"
                                />
                                <div className="form-help" id="corpWebsite">
                                    (Enter 3 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-lg-4">
                                <label
                                    htmlFor="primaryContactName"
                                    className="label-form"
                                >
                                    Primary Contact Name
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="primaryContactName"
                                    name="primaryContactName"
                                    value={
                                        corporation?.primaryContactName
                                            ? corporation.primaryContactName
                                            : ""
                                    }
                                    onChange={handleChange}
                                    pattern={
                                        CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                    }
                                    maxLength={100}
                                    required
                                    aria-describedby="corpPriName"
                                />
                                <div className="form-help" id="corpPriName">
                                    (Enter 2 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label htmlFor="primaryContactPhoneNumber">
                                    Primary Contact Phone Number
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="primaryContactPhoneNumber"
                                    name="primaryContactPhoneNumber"
                                    value={
                                        corporation?.primaryContactPhoneNumber
                                            ? corporation.primaryContactPhoneNumber
                                            : ""
                                    }
                                    onChange={handleChange}
                                    minLength={10}
                                    maxLength={20}
                                    pattern={CONSTANTS.REGEX_ALLOW_NUMBERS}
                                    aria-describedby="corpPriNo"
                                />
                                <div className="form-help" id="corpPriNo">
                                    (Enter 10 to 20 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-lg-4">
                                <label
                                    htmlFor="primaryContactEmailAddress"
                                    className="label-form"
                                >
                                    Primary Contact Email Address
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="primaryContactEmailAddress"
                                    name="primaryContactEmailAddress"
                                    value={
                                        corporation?.primaryContactEmailAddress
                                            ? corporation.primaryContactEmailAddress
                                            : ""
                                    }
                                    onChange={handleChange}
                                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                    maxLength={100}
                                    required
                                    aria-describedby="corpPriEmail"
                                />
                                <div className="form-help" id="corpPriEmail">
                                    (Enter 3 to 100 characters)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <div className="col-lg-4">
                                <label
                                    htmlFor="corpprogramAssociated"
                                    id="dropdown-label-corpprogramAssociated"
                                    className="label-form"
                                >
                                    Programs Associated with
                                    <sup>*</sup>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <MultiSelectDropdown
                                    id="corpprogramAssociated"
                                    items={programsMap}
                                    isLoading={programsLoadingInd}
                                    disabled={programsLoadingInd}
                                    selectedValue={selectedPrograms}
                                    callParentOnSelect={(value: any) => {
                                        setSelectedPrograms(value);
                                        setButtonState(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="corp-table-btn row mx-0 corp-btn-edit">
                            <div className="col-md-8 offset-md-4 px-0 px-md-3 mb-4 d-flex justify-content-start flex-column flex-sm-row">
                                <button
                                    type="button"
                                    className="btn btn-round btn-secondary corp-btn-1"
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        if (props.editMode) {
                                            props.setEditMode(!props.editMode);
                                        } else {
                                            history.push(
                                                props.backToCorporationManagement
                                            );
                                        }
                                    }}
                                    disabled={disabled}
                                    aria-label="Cancel Corporation"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className={`btn btn-round btn-primary corp-btn-2 ${
                                        disabled ? CONSTANTS.BUTTON_SPINNER : ""
                                    }`}
                                    aria-label="Save Corporation"
                                    disabled={buttonState}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </CorporationFormWrapper>
    );
};

export default AddOrEditCorporation;
