import styled from "styled-components";

export const MeasuresEmptyStateWrapper = styled.div`
    .measure-emptystate {
        font-size: 22px;
        .folder-icon {
            max-width: 100%;
        }
        .block-edit-icon {
            max-width: 200px;
            @media only screen and (min-width: 768px) {
                max-width: none;
            }
        }
        p {
            @media only screen and (min-width: 576px) {
                width: 400px;
            }
        }
    }
    .create-measure-btn {
        margin: 36px 0;
    }
    .measure-disabled-text {
        @media only screen and (min-width: 576px) {
            white-space: nowrap;
        }
    }
`;
