import styled from "styled-components";

export const DocumentResultTableWrapper = styled.div`
    .doc-filter-main {
        position: relative;
    }
    .doc-filters {
        border: 1px solid #e3e3e3;
        background: #f8f8f8;
        @media only screen and (min-width: 576px) {
            border: none;
            background: none;
        }
        @media only screen and (min-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
            border: 1px solid #e3e3e3;
            background: #f8f8f8;
        }
        @media only screen and (min-width: 1200px) {
            width: 890px;
        }
        select {
            border-radius: 6px;
        }
        .doc-filter-title {
            min-width: 90px;
        }
    }
    .upload-btn {
        padding: 7px 16px;
        white-space: nowrap;
        width: 100%;
        @media only screen and (min-width: 576px) {
            position: static;
            width: auto;
        }
    }
    .doc-filter-container {
        margin: 4px 14px 14px;
        border-top: 1px solid #e3e3e3;
        padding-top: 12px;
        @media only screen and (min-width: 576px) {
            border: 1px solid #e3e3e3;
            background: #f8f8f8;
            margin: 5px 0 0 0;
            padding: 14px;
        }
        @media only screen and (min-width: 768px) {
            background: none;
            border: none;
            padding: 0;
            margin: 0;
        }
        .doc-programselect {
            width: 100%;
        }
    }
    .doc-filter-field {
        margin-bottom: 20px;
        @media only screen and (min-width: 768px) {
            padding: 12px 15px 12px 0;
            margin-bottom: 0;
        }
        @media only screen and (min-width: 1200px) {
            padding: 12px 21px 12px 0;
        }
        &:last-child {
            padding-right: 0;
            margin-bottom: 5px;
            @media only screen and (min-width: 768px) {
                margin-bottom: 0;
            }
        }
        label {
            white-space: nowrap;
        }
    }
    .doc-filter-1 {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: 34%;
        }
        @media only screen and (min-width: 992px) {
            width: auto;
            flex-grow: 1;
        }
        @media only screen and (min-width: 1200px) {
            width: 325px;
            flex-grow: initial;
        }
    }
    .doc-filter-2 {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: 42%;
        }
        @media only screen and (min-width: 992px) {
            width: auto;
            flex-grow: 1;
        }
        @media only screen and (min-width: 1200px) {
            width: 320px;
            flex-grow: initial;
        }
    }
    .doc-filter-3 {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: auto;
            flex-grow: 1;
        }
    }
    .cst-selected-item {
        padding: 6px 24px 6px 9px;
    }
    .doc-filter-header {
        padding: 5px 14px;
        width: 100%;
        border: 1px solid #e3e3e3;
        background: #f8f8f8;
        @media only screen and (min-width: 768px) {
            width: 200px;
        }
    }
    .doc-sort {
        width: auto;
        border-radius: 6px;
        flex-grow: 1;
        @media only screen and (min-width: 576px) {
            width: 150px;
            flex-grow: unset;
        }
    }
    .doc-table {
        margin-bottom: 50px;
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 13%;
                }
                th:nth-child(2) {
                    width: 29%;
                }
                th:nth-child(3) {
                    width: 10%;
                }
                th:nth-child(4) {
                    width: 20%;
                }
                th:nth-child(5) {
                    width: 15%;
                }
                th:nth-child(6) {
                    width: 13%;
                }
                th:nth-child(3),
                th:nth-child(4),
                th:nth-child(5),
                th:nth-child(6) {
                    white-space: nowrap;
                }
                th:nth-child(1),
                th:nth-child(2) {
                    .aui-th {
                        justify-content: flex-start;
                    }
                }
                th:nth-child(3),
                th:nth-child(4),
                th:nth-child(5),
                th:nth-child(6) {
                    .aui-th {
                        justify-content: center;
                    }
                }
            }
            @media only screen and (min-width: 1200px) {
                th:nth-child(1) {
                    width: 13%;
                }
                th:nth-child(2) {
                    width: 32%;
                }
                th:nth-child(3) {
                    width: 10%;
                }
                th:nth-child(4) {
                    width: 17%;
                }
                th:nth-child(5) {
                    width: 15%;
                }
                th:nth-child(6) {
                    width: 13%;
                }
            }
        }
        tbody {
            td:nth-child(1),
            td:nth-child(2) {
                .aui-td {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6) {
                .aui-td {
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        tr {
            td,
            th {
                @media only screen and (min-width: 992px) {
                    text-align: center;
                }
            }
            td:nth-child(1),
            td:nth-child(2),
            th:nth-child(1),
            th:nth-child(2) {
                text-align: left;
            }
            td:nth-child(3),
            td:nth-child(5),
            td:nth-child(6) {
                white-space: nowrap;
            }
            td {
                h6,
                p {
                    word-break: break-word;
                }
                p {
                    margin: 0;
                }
            }
        }
        .doc-table-title {
            h6 {
                line-height: 1.4;
            }
        }
        .doc-table-action {
            @media only screen and (min-width: 992px) {
                padding: 0;
            }
            .btn {
                padding: 0;
                border-width: 1px;
                font-size: 14px;
                text-align: center;
            }
            .doc-table-action-pipe {
                margin: 0 8px;
                width: 2px;
                height: 15px;
                background-color: #e3e3e3;
                display: inline-block;
            }
        }
    }
`;
