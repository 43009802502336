import store from "app/store";
import APIUtils from "utils/API.utils";
import { showToast } from "components/Toast/toast.slice";
import CONSTANTS from "../common/constants";
import config from "../config";
import { validJSON } from "../common/utils";

const API = new APIUtils();

export const getCorporations = async (
    tab: string,
    pageNumber: number,
    pageSize: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let orderBy: string = "activatedDate";
            if (tab == CONSTANTS.INACTIVE) {
                tab = `${CONSTANTS.INACTIVE},${CONSTANTS.PENDING}`;
                orderBy = "updatedAt";
            }
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/corporations?activationStatus=${tab}&orderBy=${orderBy},DESC&pageSize=${pageSize}&pageNumber=${pageNumber}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getCorporationDetailsById = (
    corporationId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/corporations/${corporationId}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error: any) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveCorporation = async (corporation: any, isUpdate: boolean) => {
    const corporationDetail = JSON.parse(JSON.stringify(corporation));
    if (!localStorage.getItem("userAccessToken")) {
        return false;
    }
    const myHeaders = new Headers();
    myHeaders.append(
        "authorization",
        localStorage.getItem("userAccessToken") || ""
    );
    myHeaders.append("Content-Type", "application/json");
    const { id } = corporationDetail;
    delete corporationDetail.id;
    delete corporationDetail.corporationProgramDetails;
    const raw = JSON.stringify(corporationDetail);
    const requestOptions = {
        method: isUpdate ? "PATCH" : "POST",
        headers: myHeaders,
        body: raw,
    };
    let url = `${config[config.env].apiEndpoints.accounts}/corporations`;
    if (isUpdate) {
        url = `${url}/${id}`;
    }

    return new Promise((resolve, reject) => {
        fetch(url, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                if (validJSON(result)) {
                    const addRegistryResponse = JSON.parse(result);
                    if (addRegistryResponse.success) {
                        resolve(addRegistryResponse);
                    } else {
                        throw addRegistryResponse.error;
                    }
                } else {
                    throw new Error("Invalid json string in the response");
                }
            })
            .catch((errorObj) => {
                const err: any = errorObj;
                store.dispatch(showToast(err));
                reject(errorObj);
            });
    });
};

export const getCorporationFacilities = async (
    requestObject: any
): Promise<any> => {
    const {
        corporationId,
        pageNumber,
        pageSize,
        status,
        region,
        categoryId,
        sortInd,
        country,
        stateCd,
        searchKey = "",
        sortOrganizations,
    } = requestObject;
    return new Promise(async (resolve, reject) => {
        try {
            let apiEndpoint = `${
                config[config.env].apiEndpoints.accounts
            }/corporations/${corporationId}/facilities?isActive=true&pageNumber=${pageNumber}&pageSize=${pageSize}`;
            if (status === CONSTANTS.CERTIFIED) {
                apiEndpoint = `${
                    config[config.env].apiEndpoints.accounts
                }/corporations/${corporationId}/facilities?isActive=true&pageNumber=${pageNumber}&pageSize=${pageSize}&certifiedFacility=true`;
            } else if (status === CONSTANTS.DEACTIVATED) {
                apiEndpoint = `${
                    config[config.env].apiEndpoints.accounts
                }/corporations/${corporationId}/facilities?isActive=false&pageNumber=${pageNumber}&pageSize=${pageSize}`;
            } else if (status === CONSTANTS.RENEWING) {
                apiEndpoint = `${
                    config[config.env].apiEndpoints.accounts
                }/corporations/${corporationId}/facilities?isActive=true&approvalStatus=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
            } else if (
                [CONSTANTS.REGISTRED, CONSTANTS.PENDING].includes(status)
            ) {
                apiEndpoint = `${
                    config[config.env].apiEndpoints.accounts
                }/corporations/${corporationId}/facilities?isActive=true&approvalStatus=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}&certifiedFacility=false`;
            }
            if (
                [CONSTANTS.DOMESTIC, CONSTANTS.INTERNATIONAL].indexOf(region) >=
                0
            ) {
                apiEndpoint = `${apiEndpoint}&region=${region}`;
            }
            if (categoryId !== CONSTANTS.ALL) {
                apiEndpoint = `${apiEndpoint}&categoryId=${categoryId}`;
            }
            if (
                country !== CONSTANTS.ALL &&
                region === CONSTANTS.INTERNATIONAL
            ) {
                apiEndpoint = `${apiEndpoint}&countryCode=${country}`;
            }
            if (stateCd !== CONSTANTS.ALL && region === CONSTANTS.DOMESTIC) {
                apiEndpoint = `${apiEndpoint}&stateName=${stateCd}`;
            }
            switch (sortInd) {
                case true:
                    if (
                        status === CONSTANTS.CERTIFIED &&
                        sortOrganizations === CONSTANTS.EXPIRY_ASCENDING
                    ) {
                        apiEndpoint = `${apiEndpoint}&orderBy=certificateExpiryDate,ASC`;
                    } else {
                        apiEndpoint = `${apiEndpoint}&orderBy=facilityName,ASC`;
                    }
                    break;
                case false:
                    if (
                        status === CONSTANTS.CERTIFIED &&
                        sortOrganizations === CONSTANTS.EXPIRY_DESCENDING
                    ) {
                        apiEndpoint = `${apiEndpoint}&orderBy=certificateExpiryDate,DESC`;
                    } else {
                        apiEndpoint = `${apiEndpoint}&orderBy=facilityName,DESC`;
                    }
                    break;
                default:
                    apiEndpoint = `${apiEndpoint}&orderBy=createdAt,DESC`;
            }
            apiEndpoint = `${apiEndpoint}&searchKeyword=${encodeURIComponent(
                searchKey
            )}`;
            const APIRes = await API.GET(apiEndpoint);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (errorData) {
            const err: any = errorData;
            store.dispatch(showToast(err));
            reject(errorData);
        }
    });
};

export const getFacilitiesForBulkUpload = async (
    corporationId: number,
    pageNumber: number,
    pageSize: number,
    status: string,
    sortInd: any,
    categoryId: number,
    searchKey: string = ""
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let apiEndpoint;

            switch (status) {
                case CONSTANTS.CERTIFIED:
                    apiEndpoint = `${
                        config[config.env].apiEndpoints.accounts
                    }/corporations/${corporationId}/facilities?isActive=true&certifiedFacility=true&facilityType=certification&isBulkUpload=true&categoryId=${categoryId}&searchKeyword=${encodeURIComponent(
                        searchKey
                    )}`;
                    break;
                case CONSTANTS.AWARDED:
                    apiEndpoint = `${
                        config[config.env].apiEndpoints.accounts
                    }/corporations/${corporationId}/facilities?isActive=true&certifiedFacility=true&facilityType=quality&isBulkUpload=true&categoryId=${categoryId}&searchKeyword=${encodeURIComponent(
                        searchKey
                    )}`;
                    break;
                case CONSTANTS.REGISTRED:
                case CONSTANTS.RENEWING:
                    apiEndpoint = `${
                        config[config.env].apiEndpoints.accounts
                    }/corporations/${corporationId}/facilities?isActive=true&approvalStatus=${status}&certifiedFacility=false&isBulkUpload=true&categoryId=${categoryId}&searchKeyword=${encodeURIComponent(
                        searchKey
                    )}`;
                    break;
                default:
                    apiEndpoint = `${
                        config[config.env].apiEndpoints.accounts
                    }/corporations/${corporationId}/facilities?isActive=true&isBulkUpload=true&categoryId=${categoryId}&searchKeyword=${encodeURIComponent(
                        searchKey
                    )}`;
                    break;
            }

            if (pageNumber && pageSize) {
                apiEndpoint = `${apiEndpoint}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
            }
            switch (sortInd) {
                case true:
                    apiEndpoint = `${apiEndpoint}&orderBy=facilityName,ASC`;
                    break;
                case false:
                    apiEndpoint = `${apiEndpoint}&orderBy=facilityName,DESC`;
                    break;
                default:
                    apiEndpoint = `${apiEndpoint}&orderBy=updatedAt,DESC`;
            }
            const APIRes = await API.GET(apiEndpoint);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (errorData) {
            const err: any = errorData;
            store.dispatch(showToast(err));
            reject(errorData);
        }
    });
};

export const getAllFacilitiesByCorpId = async (
    corporationId: number,
    pageNumber: number,
    pageSize: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/corporations/${corporationId}/getAllFacilities?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=createdAt,DESC`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const activateOrDeactivateCorporation = async (
    registeryObj: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = registeryObj;

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/admin/verify/corporation`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const unmapFacilityFromCorporation = async (
    corporationId: any,
    facilityId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.DELETE(
                `${
                    config[config.env].apiEndpoints.accounts
                }/corporations/${corporationId}/hospitals/${facilityId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getProgramCorporations = async (
    programId: string,
    pageSize: number,
    pageNumber: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/corporations/programs/${programId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const callProgramCoprporaions = async (
    programId: any,
    setQuestionInd: any,
    dispatch?: any,
    setPageLoadingStatus?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = [];
            let isLast = false;
            let pageNumber = 1;
            while (!isLast) {
                const corporation = await getProgramCorporations(
                    programId,
                    25,
                    pageNumber
                );
                if (dispatch) {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
                if (
                    corporation.data._pagination.isFirst &&
                    corporation.data.totalCorporationCount > 0
                ) {
                    setQuestionInd(true);
                }
                isLast = corporation.data._pagination.isLast;
                result.push(...corporation.data.corporations);
                pageNumber++;
            }
            resolve({ data: result });
        } catch (error) {
            const err: any = error;
            reject(err);
        }
    });
};
