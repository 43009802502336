import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "../../styles/styles.scss";
import Pagination, { IPagination } from "components/Pagination";
import EMSLogsTable from "components/EMSLogsTable";
import TableRowCount from "components/TableRowCount";
import SortTableColumnDropdown from "components/SortTableColumnDropdown";
import { getLogList } from "api/emsAPI";
import { logger } from "utils/logger.utils";
import CONSTANTS from "common/constants";
import Sidebar from "../Sidebar/Sidebar";
import { OrganizationLogsWrapper } from "./styled";

export const OrganizationLogs = () => {
    const location = useLocation();
    const history = useHistory();
    // REDUX variables
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    // RFC STATE variables
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<string>(CONSTANTS.DESC);
    const [sortInd, setSortInd] = useState<any>(location?.state?.pageSort);
    const [pageNumber, setPageNumber] = useState<number>(
        location?.state?.adminfacilitylogsPage || 1
    );
    const [tab, setTab] = useState<string>(
        location?.state?.emsAdminOrganizationLogsTab
    );
    const [roleValue, setRoleValue] = useState<string>("none");
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const setDefaultPagination = () => {
        setPageNumber(1);
        setPagination(defaultPagination);
    };
    const close = () => {
        history.push("/emsadmin/organization-logs");
    };
    const sortIndValue = (value: any) => {
        setSortInd(value);
    };
    const [logsData, setLogsData] = useState([]);
    useEffect(() => {
        const fetchLogData = async () => {
            setIsTableLoading(true);
            const response: any = await getLogList(
                pageNumber,
                pagination.pageSize,
                order
            );
            setLogsData(response?.data?.logResult?.rows);
            setPagination({
                ...response.data._pagination,
                setPageNumber,
            });
            setIsTableLoading(false);
        };
        fetchLogData();
    }, [pageNumber, pagination.pageSize]);

    return (
        <OrganizationLogsWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="organizationLogs" />

                <section className="d-flex admin-main-content container">
                    <div className="row no-gutters w-100 admin-main">
                        <div className="col-12">
                            <div className="d-md-flex justify-content-between org-logs align-items-center">
                                <h1 className="h2 font-400 mb-0">
                                    Organization Logs
                                </h1>
                            </div>
                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                <TableRowCount
                                    isFirst={pagination.isFirst}
                                    pageNumber={pagination.pageNumber}
                                    pageSize={pagination.pageSize}
                                    isLast={pagination.isLast}
                                    totalCount={pagination.totalCount}
                                    roleValue={roleValue}
                                    logs
                                />
                                <div className="mt-3 mt-md-0">
                                    <SortTableColumnDropdown
                                        pageNumber={pageNumber}
                                        getHospitalsForAdminManage={getLogList}
                                        pageSize={pagination.pageSize}
                                        id="sort"
                                        sortByLabel="Service Year"
                                        setIsTableLoading={setIsTableLoading}
                                        sortIndValue={sortIndValue}
                                        setLogsData={setLogsData}
                                        setPagination={setPagination}
                                        emsLogs
                                    />
                                </div>
                            </div>
                            <EMSLogsTable
                                isTableLoading={isTableLoading}
                                tab={tab}
                                pageNumber={pageNumber}
                                actionURL="/emsadmin/organization-logs"
                                sortInd=""
                                close={close}
                                logsData={logsData}
                            />

                            {pagination && Number(pagination.totalPages) > 0 ? (
                                <Pagination
                                    pageNumber={pagination.pageNumber}
                                    pageSize={pagination.pageSize}
                                    totalCount={pagination.totalCount}
                                    totalPages={pagination.totalPages}
                                    isFirst={pagination.isFirst}
                                    isLast={pagination.isLast}
                                    setPageNumber={pagination.setPageNumber}
                                    paginationSetLimit={paginationSetLimit}
                                />
                            ) : null}
                        </div>
                    </div>
                </section>
            </div>
        </OrganizationLogsWrapper>
    );
};

export default OrganizationLogs;
