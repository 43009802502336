import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    setSearchKey,
    setSortOrganizations,
    setStateCode,
    setLogsSearchKey,
    setAward,
    setLogsStateCode,
} from "components/SearchFilter/searchFilter.slice";
import CONSTANTS from "common/constants";
import { SidebarWrapper } from "./styled";

interface Props {
    activeTab: string;
}
const EMSAdminSidebar = ({ activeTab }: Props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (activeTab !== "organizationManagement") {
            dispatch(setSortOrganizations(CONSTANTS.ALL));
            dispatch(setSearchKey(""));
            dispatch(setStateCode(CONSTANTS.ALL));
        }
        if (activeTab !== "organizationLogs") {
            dispatch(setSortOrganizations(CONSTANTS.ALL));
            dispatch(setLogsSearchKey(""));
            dispatch(setLogsStateCode(CONSTANTS.ALL));
            dispatch(setAward(CONSTANTS.ALL_VALUE));
        }
    }, []);
    return (
        <SidebarWrapper className="aui-sidenav">
            <nav className="navbar-expand-lg">
                <button
                    className="navbar-toggler btn-mainmenu-sm collapsed m-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#emsadminNavbar"
                    aria-controls="emsadminNavbar"
                    aria-expanded="false"
                    aria-label="main menu navigation"
                >
                    Menu
                    <i className="aha-icon-arrow-down" aria-hidden="true" />
                </button>
                <div className="collapse navbar-collapse" id="emsadminNavbar">
                    <ul className="w-100">
                        <li>
                            <Link
                                to="/emsadmin/organization-management"
                                className={
                                    activeTab == "organizationManagement"
                                        ? "active"
                                        : ""
                                }
                                aria-current={
                                    activeTab == "organizationManagement"
                                        ? "page"
                                        : false
                                }
                            >
                                Organization Management
                            </Link>
                            <Link
                                to="/emsadmin/organization-logs"
                                className={
                                    activeTab == "organizationLogs"
                                        ? "active"
                                        : ""
                                }
                                aria-current={
                                    activeTab == "organizationLogs"
                                        ? "page"
                                        : false
                                }
                            >
                                Organization Logs
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </SidebarWrapper>
    );
};

export default EMSAdminSidebar;
