import styled from "styled-components";

export const AwardViewWrapper = styled.div`
    .cert-btns {
        background: #f8f8f8;
        border: 1px solid #e3e3e3;
        text-align: center;
    }
    .aui-responsive-table.aui-table-cols tr {
        border-bottom: 1px solid #fff;
    }
    .pl-35 {
        padding-left: 34px;
    }
`;
