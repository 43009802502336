import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import HospitalBanner from "pages/Admin/HospitalBanner/FacilityDetailsBanner";
import MoreActions from "components/MoreActions/MoreActions";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import { showToast } from "components/Toast/toast.slice";
import { deactivateHospital } from "api/adminDashboardAPI";
import FocusTrap from "focus-trap-react";
import { getArchivalAccordionDatesAPI } from "api/archivalDocumentIntegrationAPI";
import { FacilitiesDetailsWrapper } from "./styled";
import CONSTANTS from "../../../common/constants";
import { parseJwt, showModal } from "../../../common/utils";
import { unmapFacilityFromCorporation } from "../../../api/corporationAPI";

interface Props {
    returnPath: any;
    hospital: any;
    programData?: any;
    confirmDeactivateModalId?: any;
    lastEditedData?: any;
    confirmRemoveModalId?: any;
    certificateData?: any;
    isCorporation?: any;
    activeMenu: string;
    exportFacilityData?: any;
}

export const FacilitiesNav = ({
    returnPath,
    hospital,
    confirmDeactivateModalId,
    programData,
    lastEditedData,
    confirmRemoveModalId,
    certificateData,
    isCorporation,
    activeMenu,
    exportFacilityData,
}: Props) => {
    const { hospitalId, programId, categoryId }: any = useParams();
    const dispatch = useDispatch();
    const corporationId = parseJwt(
        window.localStorage.getItem("userAccessToken")
    ).corporation_id;
    const [removeFacilityActivateInd, setRemoveFacilityActivateInd] =
        useState<boolean>(false);
    const [deactivateFacilityActivateInd, setDeactivateFacilityActivateInd] =
        useState<boolean>(false);
    const [hideMeasure, setHideMeasure] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const [archivedDate, setArchivedDate] = useState<any>();

    const getReturnLabel = () => {
        switch (returnPath?.tab) {
            case CONSTANTS.PENDING:
                return "Pending Approval";
            case CONSTANTS.REGISTRED:
                return "Registered Organizations";
            case CONSTANTS.CERTIFIED:
                return "Certified Organizations";
            case CONSTANTS.RENEWING:
                return "Renewing Organizations";
            case CONSTANTS.DEACTIVATED:
                return "Deactivated Organizations";
            case CONSTANTS.BULKDOCUMENT:
                return "Document Submission";
            default:
                return "";
        }
    };

    const getReturnPath = () => {
        switch (returnPath?.tab) {
            case CONSTANTS.PENDING:
                return "/corporation/pending-approval";
            case CONSTANTS.REGISTRED:
                return "/corporation/registered-organization";
            case CONSTANTS.CERTIFIED:
                return "/corporation/certified-organization";
            case CONSTANTS.RENEWING:
                return "/corporation/renewed-organizations";
            case CONSTANTS.DEACTIVATED:
                return "/corporation/deactivated-organization";
            case CONSTANTS.BULKDOCUMENT:
                return "/corporation/bulk-document-submission";
            default:
                return "";
        }
    };
    const close = () => {
        history.push(getReturnPath());
    };
    const documentExportFunc = () => {
        exportFacilityData();
    };
    const removeFacilityConfirmationPopupModal = () => {
        setRemoveFacilityActivateInd(true);
        showModal();
    };
    const deactivateFacilityConfirmationPopupModal = () => {
        setDeactivateFacilityActivateInd(true);
        showModal();
    };
    const deactivateFacilityConfirmationDismissModal = () => {
        setDeactivateFacilityActivateInd(false);
        showModal();
    };
    const removeFacilityConfirmationDismissModal = () => {
        setRemoveFacilityActivateInd(false);
        showModal();
    };
    const unmapFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        unmapFacilityFromCorporation(corporationId, hospital.hospitalId)
            .then((data: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (data.success == true) {
                    dispatch(
                        showToast({
                            visibility: true,
                            message: `${hospital.facilityName} removed from the Corporation successfully.`,
                            type: "success",
                            code: "Success:",
                        })
                    );
                    close();
                    removeFacilityConfirmationDismissModal();
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    const deactivateFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const deactivateResult = await deactivateHospital(
            hospital.facilityCode,
            false
        );
        if (deactivateResult.success) {
            const toast = {
                message: `${hospital.facilityName} has been deactivated.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            close();
            deactivateFacilityConfirmationDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };

    useEffect(() => {
        if (programId == 16) {
            setHideMeasure(true);
        }
    }, []);
    const stateValue = {
        tab: returnPath?.tab,
        programFilter: location?.state.programFilter
            ? location.state.programFilter
            : null,
        searchFilter: location?.state.searchFilter
            ? location.state.searchFilter
            : null,
        typeFilter: location?.state.typeFilter
            ? location.state.typeFilter
            : null,
        pageNumberFilter: location?.state.pageNumberFilter
            ? location.state.pageNumberFilter
            : null,
        sortingInd: location?.state.sortingInd
            ? location.state.sortingInd
            : null,
        userSelection: location?.state.userSelection
            ? location.state.userSelection
            : null,
        selectedFacilityIds: location?.state.selectedFacilityIds
            ? location.state.selectedFacilityIds
            : null,
        selectCount: location?.state.selectCount
            ? location.state.selectCount
            : null,
        pageNumber: returnPath?.pageNumber,
        selectedProgramType: location?.state?.selectedProgramType,
    };
    const sortDropdown = isCorporation
        ? [
              {
                  label: CONSTANTS.EXPORT,
                  value: CONSTANTS.EXPORT,
                  className: "aha-icon-export font-red mr-2",
              },
              {
                  label: CONSTANTS.REMOVE,
                  value: CONSTANTS.REMOVE,
                  className: "aha-icon-close font-red mr-2",
              },
          ]
        : [
              {
                  label: CONSTANTS.EXPORT,
                  value: CONSTANTS.EXPORT,
                  className: "aha-icon-export menu-cst-icon",
              },
              {
                  label:
                      hospital.approvalStatus === CONSTANTS.PENDING
                          ? CONSTANTS.APPROVE
                          : CONSTANTS.ACTIVATE,
                  value:
                      hospital.approvalStatus === CONSTANTS.PENDING
                          ? CONSTANTS.APPROVE
                          : CONSTANTS.ACTIVATE,
                  className: "aha-icon-tick-mark mr-2 font-red",
              },
          ];
    if (
        hospital.approvalStatus &&
        hospital.approvalStatus !== CONSTANTS.PENDING
    ) {
        sortDropdown.push({
            label: CONSTANTS.ARCHIVED,
            value: CONSTANTS.ARCHIVED,
            className: "aha-icon-archived menu-cst-icon",
        });
    }
    const callParentOnSelect = (value: any) => {
        if (value === CONSTANTS.EXPORT) {
            return documentExportFunc();
        }
        if (value === CONSTANTS.REMOVE) {
            return removeFacilityConfirmationPopupModal();
        }
        if (value === CONSTANTS.ARCHIVED) {
            history.push(
                `/corporation/organization-details/archived-view/${hospitalId}/program/${programId}/category/${categoryId}`,
                {
                    customState: returnPath,
                    facilityName: hospital.facilityName,
                    tab: returnPath?.tab,
                    isArchived: true,
                    pathname: "/corporation/organization-details",
                }
            );
        }
    };
    useEffect(() => {
        if (
            hospital.approvalStatus &&
            hospital.approvalStatus !== CONSTANTS.PENDING
        ) {
            getArchivalAccordionDatesAPI(hospitalId).then((res: any) => {
                if (res.statusCode == 200) {
                    const archivalDate = res.data.archiveDates;
                    setArchivedDate(
                        res.data.archiveDates[archivalDate.length - 1]
                    );
                }
            });
        }
    }, []);
    return (
        <FacilitiesDetailsWrapper>
            {hospital?.facilityName && (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb aha-breadcrumb px-0 mb-2">
                        <li className="breadcrumb-item">
                            <Link
                                to={{
                                    pathname: getReturnPath(),
                                    state: {
                                        programFilter: location?.state
                                            .programFilter
                                            ? location.state.programFilter
                                            : null,
                                        searchFilter: location?.state
                                            .searchFilter
                                            ? location.state.searchFilter
                                            : null,
                                        typeFilter: location?.state.typeFilter
                                            ? location.state.typeFilter
                                            : null,
                                        pageNumberFilter: location?.state
                                            .pageNumberFilter
                                            ? location.state.pageNumberFilter
                                            : null,
                                        sortingInd: location?.state.sortingInd
                                            ? location.state.sortingInd
                                            : null,
                                        userSelection: location?.state
                                            .userSelection
                                            ? location.state.userSelection
                                            : null,
                                        selectedFacilityIds: location?.state
                                            .selectedFacilityIds
                                            ? location.state.selectedFacilityIds
                                            : null,
                                        selectCount: location?.state.selectCount
                                            ? location.state.selectCount
                                            : null,
                                        pageNumber: location?.state.pageNumber
                                            ? location.state.pageNumber
                                            : null,
                                        selectedProgramType:
                                            location?.state
                                                ?.selectedProgramType,
                                    },
                                }}
                            >
                                {getReturnLabel()}
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <div aria-current="page" role="link" tabIndex={0}>
                                {hospital.facilityName}
                            </div>
                        </li>
                    </ol>
                </nav>
            )}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="h2 font-400 mb-0">Organization Management</h1>
                <div className="d-flex">
                    <div
                        id="moreactions"
                        className="d-flex align-items-center more-actions"
                    >
                        <div
                            className="export-dropdown ml-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="More Actions"
                            data-container="#moreactions"
                        >
                            <MoreActions
                                sortDropdown={sortDropdown}
                                hospital={hospital}
                                callParentOnSelect={callParentOnSelect}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Deactivate Modal */}
            {hospital.approvalStatus == CONSTANTS.REGISTRED &&
                hospital.isActive == true &&
                deactivateFacilityActivateInd && (
                    <FocusTrap
                        focusTrapOptions={{
                            escapeDeactivates: false,
                            clickOutsideDeactivates: false,
                        }}
                    >
                        <div
                            className="modal fade show aui-modal"
                            tabIndex={-1}
                            aria-labelledby={confirmDeactivateModalId}
                            aria-modal="true"
                            role="dialog"
                            id="deactivateOrgConfirmationModal"
                            style={{
                                display: deactivateFacilityActivateInd
                                    ? "block"
                                    : "none",
                            }}
                        >
                            <div className="modal-dialog modal-dialog-centered modal-sm">
                                <div className="modal-content">
                                    <div className="modal-body p-0">
                                        <div className="text-center">
                                            <span
                                                id={confirmDeactivateModalId}
                                                className="sr-only"
                                            >
                                                Confirm Deactivate Organization
                                            </span>
                                            <p className="pb-2">
                                                Are you sure you want to
                                                deactivate&nbsp;
                                                <b>
                                                    {hospital.facilityName}
                                                </b>{" "}
                                                organization?
                                            </p>
                                            <div className="mt-4 d-flex justify-content-center">
                                                <button
                                                    className="btn btn-secondary btn-round btn-sm mx-2"
                                                    aria-label="Cancel Deactivation and close the modal"
                                                    onClick={
                                                        deactivateFacilityConfirmationDismissModal
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-round btn-sm mx-2"
                                                    onClick={deactivateFacility}
                                                    data-dismiss="modal"
                                                    aria-label="Confirm Deactivate"
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FocusTrap>
                )}

            {/* Un-map Modal */}
            {hospital && isCorporation && removeFacilityActivateInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal fade show aui-modal"
                        tabIndex={-1}
                        aria-labelledby={confirmRemoveModalId}
                        aria-modal="true"
                        role="dialog"
                        style={{
                            display: removeFacilityActivateInd
                                ? "block"
                                : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <span
                                            id={confirmRemoveModalId}
                                            className="sr-only"
                                        >
                                            Confirm Removing this Organization
                                        </span>
                                        <p className="pb-2">
                                            Are you sure you want to remove
                                            <b> {hospital.facilityName} </b>
                                            from the Corporation?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                aria-label="Cancel remove and close the modal"
                                                onClick={
                                                    removeFacilityConfirmationDismissModal
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                onClick={unmapFacility}
                                                data-dismiss="modal"
                                                aria-label="Confirm Removing this organization"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
            {[
                CONSTANTS.REGISTRED,
                CONSTANTS.CERTIFIED,
                CONSTANTS.RENEWING,
                CONSTANTS.BULKDOCUMENT,
            ].includes(returnPath?.tab) && (
                <div className="aui-dark-nav">
                    <div className="aui-dark-nav-tabs">
                        <div className="aui-dark-nav-container">
                            <div className="navbar-expand-lg">
                                <div className="d-flex flex-row-reverse aui-dark-nav-menubtn">
                                    <button
                                        type="button"
                                        className="navbar-toggler aha-icon-hamburger"
                                        data-toggle="collapse"
                                        data-target="#facItems"
                                        aria-controls="darkNavbarDashboard"
                                        aria-expanded="false"
                                        aria-label="navigation menu"
                                    />
                                </div>
                                <div id="darkNavbarDashboard">
                                    <ul
                                        id="facItems"
                                        className="collapse navbar-collapse aui-dark-nav-menu"
                                        role="menu"
                                    >
                                        <li
                                            role="none"
                                            className={
                                                activeMenu ==
                                                "corporationFacilityDetails"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link
                                                to={{
                                                    pathname: `/corporation/organization-details/${hospitalId}/program/${programId}/category/${categoryId}`,
                                                    state: returnPath,
                                                }}
                                                aria-current={
                                                    activeMenu ==
                                                    "corporationFacilityDetails"
                                                        ? "true"
                                                        : false
                                                }
                                                role="menuitem"
                                            >
                                                Details
                                            </Link>
                                        </li>
                                        {!hideMeasure && (
                                            <li
                                                role="none"
                                                className={
                                                    activeMenu ==
                                                    "corporationFacilityMeasures"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link
                                                    to={{
                                                        pathname: `/corporation/measures/hospital/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                                                        state: stateValue,
                                                        exportMeasureFunc:
                                                            exportFacilityData,
                                                    }}
                                                    aria-current={
                                                        activeMenu ==
                                                        "corporationFacilityMeasures"
                                                            ? "true"
                                                            : false
                                                    }
                                                    role="menuitem"
                                                >
                                                    Measures
                                                </Link>
                                            </li>
                                        )}
                                        <li
                                            role="none"
                                            className={
                                                activeMenu ==
                                                "corporationFacilityDocuments"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link
                                                to={{
                                                    pathname: `/corporation/documents/hospital/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                                                    state: stateValue,
                                                    exportDocumentFunc:
                                                        exportFacilityData,
                                                }}
                                                aria-current={
                                                    activeMenu ==
                                                    "corporationFacilityDocuments"
                                                        ? "true"
                                                        : false
                                                }
                                                role="menuitem"
                                            >
                                                Documents
                                            </Link>
                                        </li>
                                        <li
                                            role="none"
                                            className={
                                                activeMenu ==
                                                "corporationFacilityCertification"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link
                                                to={{
                                                    pathname: `/corporation/certificate/hospital/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                                                    state: stateValue,
                                                    exportCertificateFunc:
                                                        exportFacilityData,
                                                }}
                                                aria-current={
                                                    activeMenu ==
                                                    "corporationFacilityCertification"
                                                        ? "true"
                                                        : false
                                                }
                                                role="menuitem"
                                            >
                                                Certificate
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {activeMenu === "corporationFacilityMeasures" && (
                <h2 className="h3 main-pg-heading mb-2">Measures</h2>
            )}
            {activeMenu === "corporationFacilityDocuments" && (
                <h2 className="h3 main-pg-heading mb-2">Document Submission</h2>
            )}
            {activeMenu !== "corporationFacilityCertification" && (
                <HospitalBanner
                    hospital={hospital}
                    programData={programData}
                    certificateData={certificateData}
                    lastEditedData={lastEditedData}
                    exportFacilityDetailsData={exportFacilityData}
                    close={close}
                    isCorporation={isCorporation}
                    activeMenu={activeMenu}
                />
            )}
        </FacilitiesDetailsWrapper>
    );
};

export default FacilitiesNav;
