import styled from "styled-components";

export const LoaderWrapper = styled.div`
    &.aui-loader-dark {
        background-color: rgba(0, 0, 0, 0.8);
        padding: 16px;
        @media only screen and (min-width: 576px) {
            padding: 32px;
        }
    }
    .loading-message {
        color: #ffffff;
        margin-top: 178px;
        text-align: center;
        @media only screen and (min-width: 576px) {
            margin-top: 164px;
        }
    }
    .message-light {
        font-size: 16px;
        font-weight: 400;
        @media only screen and (min-width: 576px) {
            font-size: 22px;
        }
    }
    .message-dark {
        font-size: 18px;
        font-weight: 600;
        @media only screen and (min-width: 576px) {
            font-size: 24px;
        }
    }
`;
