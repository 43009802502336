import React from "react";
import { userSessionTokenApi } from "api/usersApi";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import store from "app/store";
import { signoutAndRedirect, skipToMainContent } from "common/utils";
import CONSTANTS from "common/constants";
import { ssoLogin } from "pages/Verify/user.slice";
import { AdminHeaderWrapper } from "./styled";

export const AdminHeader = () => {
    const signout = async () => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        await userSessionTokenApi();
        store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
        store.dispatch(ssoLogin({ user: {} }));
        signoutAndRedirect();
    };

    return (
        <AdminHeaderWrapper className="aui-main-header aui-pri-header">
            <button
                className="btn btn-link aui-skip-content"
                onClick={() => skipToMainContent()}
                role="link"
            >
                Skip to main content
            </button>
            <div className="navbar navbar-expand-lg justify-content-between aui-header-content mx-auto aui-pri-header-t">
                <a
                    href="/admin/dashboard"
                    className="qct-logo"
                    aria-label={
                        window.location.pathname ===
                        CONSTANTS.ROUTES.ADMIN_DASHBOARD
                            ? "American heart association Quality and Certification tool logo dashboard page"
                            : "American heart association Quality and Certification tool logo return to dashboard page"
                    }
                >
                    <img
                        src="/images/qct-logo.png"
                        alt="q c t logo for q c t and e m s programs"
                    />
                </a>
                <button
                    className="navbar-toggler ml-2"
                    type="button"
                    data-toggle="collapse"
                    data-target="#adminHeaderCollapse"
                    aria-controls="adminHeaderCollapse"
                    aria-expanded="false"
                    aria-label="main account navigation"
                >
                    <i className="aha-icon-hamburger" aria-hidden="true" />
                </button>
                <div
                    className="justify-content-lg-end collapse navbar-collapse aui-pri-nav"
                    id="adminHeaderCollapse"
                >
                    <ul
                        className="navbar-nav ml-lg-3 flex-lg-row flex-column"
                        role="menubar"
                    >
                        <li
                            className="d-flex nav-item dropdown ml-lg-3 flex-column"
                            role="none"
                        >
                            <button
                                type="button"
                                className="btn btn-text dropdown-toggle flex-grow-1 text-left nav-link"
                                id="accountdropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                role="menuitem"
                            >
                                My Account
                            </button>
                            <ul
                                role="menu"
                                className="dropdown-menu p-lg-0 aui-header-dropdown"
                                aria-labelledby="accountdropdown"
                            >
                                <li role="none">
                                    <button
                                        type="button"
                                        onClick={signout}
                                        className="btn btn-text dropdown-item py-2"
                                        role="menuitem"
                                    >
                                        Sign Out
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </AdminHeaderWrapper>
    );
};
export default AdminHeader;
