import Dropdown from "components/Dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveServiceYear } from "api/bulkRenewApi";
import { setYear } from "../MeasuresDateFilter/measuresDateFilter.slice";
import { getServiceYearListForHospital } from "../../api/emsAPI";

interface Props {
    emsInd?: boolean;
    hospitalId?: number;
    id?: any;
    exportEmsInd?: boolean;
}

const ServiceYearDropdown = ({
    id,
    emsInd,
    hospitalId,
    exportEmsInd,
}: Props) => {
    const dispatch = useDispatch();
    const [yearsDropdown, setYearsDropdown] = useState<Array<object>>([]);
    const measuresDateFilter = useSelector((state: any) => {
        return state.measuresDateFilter;
    });
    const [activeServiceYear, setActiveServiceYear] = useState<any>();
    const [selectedYear, setSelectedYear] = useState<number>(
        measuresDateFilter.year || activeServiceYear
    );
    const [isLoading, setisLoading] = useState<boolean>(false);

    useEffect(() => {
        if (activeServiceYear) {
            exportDropdown();
        } else if (emsInd && hospitalId) {
            fetchYears();
        }
    }, [activeServiceYear, hospitalId]);

    const fetchYears = async () => {
        let years = [];
        setisLoading(true);
        if (emsInd && hospitalId) {
            const response = await getServiceYearListForHospital(hospitalId);
            if (response?.data) {
                years = response.data.serviceYearList.map((year: any) => ({
                    label: year,
                    value: year,
                }));
                setisLoading(false);
            }
        }
        setYearsDropdown(years);
        setisLoading(false);
    };

    const exportDropdown = async () => {
        if (exportEmsInd) {
            const years = [];
            for (let i = activeServiceYear; i >= 2023; i--) {
                years.push(i);
            }
            const yearsDropdownMap: any = [];
            if (years && years.length > 0) {
                years.forEach((year: any) => {
                    yearsDropdownMap.push({ label: year, value: year });
                });
            }
            setYearsDropdown(yearsDropdownMap);
            setSelectedYear(activeServiceYear);
            dispatch(setYear(activeServiceYear));
        }
    };

    const changeYear = (value: any) => {
        setSelectedYear(value);
        dispatch(setYear(value));
    };

    useEffect(() => {
        getActiveServiceYear().then((response: any) => {
            setActiveServiceYear(response.data.activeServiceYear.year);
            setSelectedYear(response.data.activeServiceYear.year);
            dispatch(setYear(response.data.activeServiceYear.year));
        });
    }, []);
    useEffect(() => {
        if (activeServiceYear) {
            exportDropdown();
        }
    }, [activeServiceYear]);
    return (
        <>
            {yearsDropdown && (emsInd || exportEmsInd) && (
                <div
                    className="d-md-flex form-group measure-filters m-0"
                    role="group"
                    aria-labelledby="filterbyserviceyear"
                >
                    <label className="sr-only" id="filterbyserviceyear">
                        Filters
                    </label>
                    <div className="d-flex">
                        <div className="measure-filter-item">
                            <label
                                className="service-year"
                                htmlFor="serviceYear"
                                id="serviceYear-label"
                            >
                                Select Service Year
                            </label>
                            <Dropdown
                                id={id}
                                items={yearsDropdown}
                                selectedValue={measuresDateFilter?.year}
                                callParentOnSelect={changeYear}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ServiceYearDropdown;
