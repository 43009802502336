import React, { useState } from "react";
import CONSTANTS from "common/constants";
import { getSSOUrl } from "api/usersApi";
import { SignInWrapper } from "./styled";

interface Props {
    userType?: string;
    className?: string;
}

const HomePage = (props: Props) => {
    const [isSignInProgress, setIsSignInProgress] = useState<boolean>(false);
    const { userType, className } = props;
    let loggedInUserType;
    switch (userType) {
        case "corporationPortal":
            loggedInUserType = CONSTANTS.CORPORATION;
            localStorage.setItem("userType", loggedInUserType);
            break;
        case "registryPortal":
            loggedInUserType = CONSTANTS.REGISTRY;
            localStorage.setItem("userType", loggedInUserType);
            break;
        default:
            break;
    }
    const signin = () => {
        setIsSignInProgress(true);
        getSSOUrl()
            .then((response: any) => {
                if (response.statusCode === 200) {
                    window.onbeforeunload = () => {
                        localStorage.setItem(
                            "verifier",
                            response.data.verifier
                        );
                    };
                    window.open(response.data.url, "_self");
                    setIsSignInProgress(false);
                }
            })
            .catch(() => {
                setIsSignInProgress(false);
            });
    };
    return (
        <SignInWrapper
            className={`container ${className}`}
            id="maincontent"
            tabIndex={-1}
        >
            <div className="row no-gutters l-block">
                <div className="col-lg-6 p-0 l-block-bg" role="presentation" />
                <div className="col-12 col-lg-6 d-flex justify-content-center l-container">
                    <div className="row align-self-center l-form">
                        <div>
                            <div
                                className="aui-association-logo mx-auto mx-lg-0"
                                role="img"
                                aria-label="American Heart Association logo"
                            />
                            <h1 className="h2 font-600 mt-3 mb-3 mb-lg-3 text-lg-left text-center">
                                An Integrated Approach to Care &amp; Quality
                                Improvement
                            </h1>
                            <p className="text-lg-left text-center pb-2">
                                Patient outcomes improve when hospitals, EMS
                                agencies, and healthcare providers follow
                                evidence-based best practices and a framework to
                                structure, manage and deliver high quality
                                patient care.
                            </p>
                            <button
                                type="button"
                                onClick={signin}
                                className={`btn btn-round btn-primary mt-4 mx-auto mx-lg-0 d-block ${
                                    isSignInProgress
                                        ? CONSTANTS.BUTTON_SPINNER
                                        : ""
                                }`}
                            >
                                Sign in &nbsp;|&nbsp; Sign up
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </SignInWrapper>
    );
};

export default HomePage;
